/**
 * ConvTextTrackView
 * Renders conversation text track
 */
import _ from 'underscore';
import AppBaseView from '../base/AppBaseView';
import nm from '../../nm';
import convTextTrackView from '../../templates/questions/enhancedElp/convTextTrackView.handlebars';

export default AppBaseView.extend({
	events: {},
	className: 'conv-text-tracks-holder',
	textTrack: null,
	initialize(options){
		_.bindAll(this, 'errorHandler');
		this.parentId = options.parentId
	},
	render: function(){
		this.$el.html(convTextTrackView({
			cues: this.collection.toJSON(),
			parentId: this.parentId //ensures every speech bubble's text spans are unique on the page
		}));
		nm.vent.trigger('view:render', {playerIsActive: true});
		return this;
	}
});
