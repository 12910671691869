var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"multiple",{"name":"compare","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":9,"column":16}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"fill_in",{"name":"compare","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":12,"column":16}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"true_false",{"name":"compare","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":15,"column":16}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"open_ended",{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":18,"column":16}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"write_about",{"name":"compare","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":21,"column":16}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("../questions/multipleChoiceView.handlebars"),depth0,{"name":"multipleChoiceView","data":data,"indent":"\t\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("../questions/fillInView.handlebars"),depth0,{"name":"fillInView","data":data,"indent":"\t\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("../questions/trueFalseView.handlebars"),depth0,{"name":"trueFalseView","data":data,"indent":"\t\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("../questions/openEndedView.handlebars"),depth0,{"name":"openEndedView","data":data,"indent":"\t\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("../questions/writeAboutView.handlebars"),depth0,{"name":"writeAboutView","data":data,"indent":"\t\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<div class=\"form-row submit-form-row\">\n"
    + ((stack1 = __default(require("../../helpers/compare.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"type") : depth0),"post_test",{"name":"compare","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":27,"column":3},"end":{"line":31,"column":15}}})) != null ? stack1 : "")
    + "		</div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "				<input type=\"submit\" value=\"Submit and Close\" class=\"submit-take-assessment-form green-button input-small\"/>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "				<input type=\"submit\" value=\"Submit\" class=\"submit-take-assessment-form green-button input-small\"/>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"nm-take-assessment-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":1,"column":29},"end":{"line":1,"column":35}}}) : helper)))
    + "\" class=\"take-assessment-form\" method=\"POST\">\n	<input type=\"hidden\" id=\"assessment-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":37},"end":{"line":2,"column":43}}}) : helper)))
    + "\" name=\"assessmentId\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":72},"end":{"line":2,"column":78}}}) : helper)))
    + "\"/>\n	<input type=\"hidden\" class=\"assigned-bundle-id\" name=\"assignedBundleId\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"assignedBundleId") || (depth0 != null ? lookupProperty(depth0,"assignedBundleId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"assignedBundleId","hash":{},"data":data,"loc":{"start":{"line":3,"column":80},"end":{"line":3,"column":100}}}) : helper)))
    + "\"/>\n	<div class=\"questions\">\n		<ol class=\"questions-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"populatedQuestions") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":3},"end":{"line":22,"column":12}}})) != null ? stack1 : "")
    + "		</ol>\n	</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"submittable") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":1},"end":{"line":33,"column":8}}})) != null ? stack1 : "")
    + "</form>\n";
},"usePartial":true,"useData":true});