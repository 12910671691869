import Backbone from 'backbone';
import nm from '../nm';

export default Backbone.Model.extend({
	// Default attributes
	defaults: {},
	urlRoot: `${nm.api}/assigned_assignment`,
	/**
	 * getAssignmentSummaries
	 * Get the assignment summaries, as opposed to the full detailed assignment response
	 * @param (int) assignmentId
	 * @return (void)
	 */
	getAssignmentSummaries: function(options){
		options = options || {};
		options.url = nm.api + 'assignment_summary';
		return Backbone.Model.prototype.fetch.call(this, options);
	},
	/**
	 * closeAssignment
	 * Hit specific endpoint to "close" Assignment with given ID
	 * @param (int) assignmentId
	 * @return (void)
	 */
	closeAssignment: function(assignmentId, options){
		options = options || {};
		//manually set correct URL and type
		options.url = this.urlRoot + '/' + assignmentId + '/close';
		options.type = 'PUT';
		return Backbone.Model.prototype.save.call(this, {}, options);
	},
	/**
	 * undoClose
	 * Hit specific endpoint to undo "close" Assignment with given ID
	 * @param (int) assignmentId
	 * @return (void)
	 */
	undoClose: function(assignmentId, options){
		options = options || {};
		//manually set correct URL and type
		options.url = this.urlRoot + '/' + assignmentId + '/undo';
		options.type = 'PUT';
		return Backbone.Model.prototype.save.call(this, {}, options);
	}
});
