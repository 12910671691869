var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"expectations-outer\">\n	<a class=\"close\" href=\"#\"><i class=\"material-icons\">close</i></a>\n	<div class=\"expectations-inner\">\n		<div class=\"expectations-header\">\n			<h2 class=\"h6\">Expectations</h2>\n		</div>\n		<div class=\"expectations-body\">\n			<p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"expectations") || (depth0 != null ? lookupProperty(depth0,"expectations") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"expectations","hash":{},"data":data,"loc":{"start":{"line":8,"column":6},"end":{"line":8,"column":24}}}) : helper))) != null ? stack1 : "")
    + "</p>\n		</div>\n	</div>\n</div>\n";
},"useData":true});