/**
 * InputTextTrackView
 * Renders conversation input's text track
 */
import _ from 'underscore';
import AppBaseView from '../base/AppBaseView';
import nm from '../../nm';
import inputTextTrackView from '../../templates/questions/enhancedElp/inputTextTrackView.handlebars';

export default AppBaseView.extend({
	events: {},
	className: 'input-text-tracks-holder',
	textTrack: null,
	initialize(options){
		_.bindAll(this, 'errorHandler');
	},
	render: function(){
		this.$el.html(inputTextTrackView({
			cues: this.collection.toJSON()
		}));
		nm.vent.trigger('view:render', {playerIsActive: true});
		return this;
	}
});
