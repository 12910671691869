import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import colorLogo from '../../img/logo_ellevation_square.png';

const useStyles = makeStyles({
    paper: {
        width: '360px',
        backgroundColor: '#ebebeb',
        padding: '32.36px',
        borderRadius: '12px',
    },
    alert: {
        backgroundColor: '#ed304f',
        color: '#ffffff',
        padding: '10px',
        borderRadius: '12px',
    },
});

const ResetPassword = ({
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    onUpdate,
    errorMessage,
}) => {
    const classes = useStyles();

    const handleChangePassword = event => {
        setPassword(event.target.value);
    }

    const handleChangeConfirmPassword = event => {
        setConfirmPassword(event.target.value);
    }

    const handleClickUpdate = event => {
        onUpdate();
    }

    return (
        <Paper className={classes.paper}>
            <span style={{ align: 'center' }}>
                <img src={colorLogo} alt='Ellevation' width='80px' />
            </span>
            {errorMessage && errorMessage !== '' && (
                <Paper className={classes.alert}>
                <Typography>{errorMessage}</Typography>
            </Paper>)}
            <Typography align='center'>New Password Required</Typography>
            <TextField
                id="password"
                type="password"
                value={password}
                label="New Password"
                onChange={handleChangePassword}
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
            />

            <TextField
                id="confirm-password"
                type="password"
                value={confirmPassword}
                label="Confirm Password"
                onChange={handleChangeConfirmPassword}
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <Button
                variant="contained"
                onClick={handleClickUpdate}
            >Update</Button>
        </Paper>
    )
}

export default ResetPassword
