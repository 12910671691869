var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "				<button class=\"speed-grey-button speed-button review-speed-slower\"><i class=\"material-icons\">remove</i><span>Slower</span></button>\n				<button class=\"scott-circle-button review-play active\"><i class=\"material-icons\">pause</i></button>\n				<button class=\"speed-grey-button speed-button review-speed-faster\"><span>Faster</span><i class=\"material-icons\">add</i></button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "				<button class=\"speed-grey-button speed-button review-speed-slower disabled\"><i class=\"material-icons\">remove</i><span>Slower</span></button>\n				<button class=\"scott-circle-button review-play disabled\"><i class=\"material-icons\">play_arrow</i></button>\n				<button class=\"speed-grey-button speed-button review-speed-faster disabled\"><span>Faster</span><i class=\"material-icons\">add</i></button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isStudent") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":20,"column":3},"end":{"line":24,"column":10}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    return "				<button class=\"scott-green-button review-next disabled animated\"><span>Continue</span><i class=\"material-icons\">keyboard_arrow_right</i></button>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "				<button class=\"scott-green-button review-next animated\"><span>Continue</span><i class=\"material-icons\">keyboard_arrow_right</i></button>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isStudent") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":26,"column":3},"end":{"line":30,"column":10}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return "				<button class=\"scott-green-button review-next disabled animated\"><span>Go Back</span><i class=\"material-icons\">keyboard_arrow_right</i></button>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "				<button class=\"scott-green-button review-next animated\"><span>Go Back</span><i class=\"material-icons\">keyboard_arrow_right</i></button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"controls-container\">\n	<div class=\"left-buttons\">\n		<button class=\"scott-blue-button review-replay\"><i class=\"material-icons\">replay</i><span>Replay</span></button>\n	</div>\n	<div class=\"center-buttons\">\n		<div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasAudio") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":7,"column":3},"end":{"line":15,"column":10}}})) != null ? stack1 : "")
    + "		</div>\n	</div>\n	<div class=\"right-buttons\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasNextSlide") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":19,"column":2},"end":{"line":31,"column":9}}})) != null ? stack1 : "")
    + "	</div>\n</div>\n";
},"useData":true});