/**
 * ReviewProgressView
 * Review Player progress indicator
 */
import AppBaseView from '../base/AppBaseView';
import nm from '../../nm';
import reviewProgressView from '../../templates/assignmentPlayer/reviewProgressView.handlebars';

export default AppBaseView.extend({
	events: {},
	className: 'review-progress-holder',
	initialize: function(options){
		this.progress = options.progress;
	},
	render: function(){
		this.hideHeader();
		//use setElement to prevent extra un-needed wrapping div
		this.setElement(reviewProgressView({
			progress: this.progress
		}));
		nm.vent.trigger('view:render', {playerIsActive: true});
		return this;
	}
});
