import AppBaseView from '../base/AppBaseView';
import textTracksView from '../../templates/assignmentPlayer/textTracksView.handlebars';

export default AppBaseView.extend({
	events: {
	},
	className: 'text-track-holder',
	initialize: function(){
	},
	render: function(){
		this.$el.html(textTracksView(this.collection.toJSON()));
		return this;
	}
});
