import React, {useEffect, useState} from 'react';
import BackboneViewWrapper from '../backbone/helpers/BackboneViewHelper';
import GroupDetailStudentView from '../backbone/views/GroupDetailStudentView';
import Classroom from '../backbone/models/Classroom';
import { useParams } from 'react-router-dom';


const StudentGroupPage = ({
    onNavigate,
}) => {
    const { id } = useParams();
    const [model, setModel] = useState();

    useEffect(() => {
        setModel(new Classroom({id: id}));
    }, [id]);
    
    return (
        <>
        {model && <BackboneViewWrapper View={GroupDetailStudentView} model={model} onNavigate={onNavigate}/>}
        </>
    );
}

export default StudentGroupPage;
