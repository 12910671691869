/**
 * ConvOutputView
 * Renders speech bubbles of a conversation
 */
import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import AppBaseView from '../base/AppBaseView';
import ConvPrimaryTextView from '../assignmentPlayer/ConvPrimaryTextView';
import ConvTextTrackView from '../assignmentPlayer/ConvTextTrackView';
import nm from '../../nm';
import convOutputView from '../../templates/questions/enhancedElp/convOutputView.handlebars';

export default AppBaseView.extend({
	events: {
		'click .play-conversation': 'playConversationSpeech'
	},
	className: 'conv-output-holder',
	textTrack: null,
	player: null,
	convTextTrackIndex: 1,
	updateTime: false,
	playing: false,
	cuesReady: false,
	/**
	 * Different browser implementations trigger VTTCue 'onexit' and 'onenter' events unpredictably when the cue
	 * is less than 200ms and/or when cues overlap. The following properties enforce a minimum duration
	 * for word highlighting
	 */
	minDuration: 100, //ms
	previousTimestamp: 0,
	numberSpeechBubbles: 0, //count of speech bubbles on screen
	manuallyPlayed: false,
	consecutiveLinesIndex: 0, //indicates index of "consecutive lines" being output
	initialize(options){
		_.bindAll(this, 'errorHandler', 'enterTextTrack');
		this.isUser = options.isUser;
		this.elpLevel = options.elpLevel;
		this.convLine = options.convLine;
		this.questionTextRendering = this.getQuestionTextRendering();
		this.endOfConversation = options.endOfConversation;
		this.currentConvLineIndex = options.currentConvLineIndex;
		this.consecutiveLinesIndex = options.consecutiveLinesIndex;
		this.convTextTrackIndex = 1;
		this.listenTo(nm.vent, 'conv-text-track:reset', this.resetConvTextTrackIndex);
	},
	render(){
		this.$el.html(convOutputView({
			isUser: this.isUser,
			vttUrl: this.prepareVtt(),
			endOfConversation: this.endOfConversation,
			conversationIndex: this.currentConvLineIndex,
			transcript: this.model.get('result').transcript,
			questionTextRendering: this.questionTextRendering
		}));
		//define player for this speech bubble and kick off process for audio
		_.defer((function(){
			this.player = this.$el.find('audio');
			this.prepareAudio();
			//if "user's" speech bubble, it won't autoplay, so trigger this manually to move conversation forward
			if(this.isUser){
				nm.vent.trigger('audio:ended', this.isUser);
				nm.vent.trigger('recording:disable');
			}
			if(this.endOfConversation){
				//disable further recording input at end of conversation
				nm.vent.trigger('recording:disable');
			}
		}).bind(this));
		nm.vent.trigger('view:render', {playerIsActive: true});
		return this;
	},
	//determine if ELP level should or should not render the text of the spoken question lines
	getQuestionTextRendering(){
		if(this.elpLevel >= 4 && !this.isUser){
			return false;
		}
		return true;
	},
	showConversationOutput(){
		//actually reveal the rendered speech bubble / hide loading indicator
		let $conversationViewer = $('#nm-conv-output');
		let $conversationLoading = $('.conversation-loading');
		$conversationViewer.find('.hide-conversation-speech')
			.removeClass('hide-conversation-speech')
			.addClass('bounceIn');
		$conversationLoading.remove();
	},
	prepareVtt(){
		//given VTT response from server, convert to URL for use in audio track element
		let caption = this.model.get('result').vtt.split(',').pop();
		caption = window.atob(caption);
		let captionBlob = new Blob([caption]);
		let vttURL = URL.createObjectURL(captionBlob);
		return vttURL;
	},
	prepareAudio(){
		//set listener for audio ended
		this.player.on('ended', function(){
			nm.vent.trigger('conv-text-track:reset');
			//if playing via user click, don't trigger next conversation line
			if(!this.manuallyPlayed){
				if(!this.endOfConversation){
					nm.vent.trigger('audio:ended', this.isUser);
				}
			}else{
				this.manuallyPlayed = false;
			}
		}.bind(this));
		//set listener for track loading
		this.player.find('track').on('load', () => {
			this.prepareTextTracks();
		});
		//load the track and reveal the speech bubble, unless it's user speech, then don't auto play
		if(!this.isUser){
			$('.pr_conv_turns-slide-audio track').load();
		}
		this.showConversationOutput();
		//set listener to actually kick off audio playback
		this.player.on('canplaythrough', (event) => {
			this.startSlideMedia();
		});
		//don't auto-play user's side of the conversation
		if(!this.isUser){
			//set audio src and load it
			this.player.attr('src', this.model.get('result').audio);
			this.player[0].load();
		}
	},
	prepareTextTracks(){
		//set a counter that will act as a per-speech-bubble ID. Subtract 1 to act as zero-based index.
		this.numberSpeechBubbles = $('.conversation-speech').length - 1;
		this.textTrack = this.player.get(0).textTracks[0];
		let useQuestionText = this.checkCanUseQuestionText();
		var cues = [];
		var reference;
		for(var j = 0; j < this.textTrack.cues.length; j++){
			reference = this.textTrack.cues[j];
			//if so, replace each cue's "text" with the original text from the question content itself
			if(useQuestionText){
				if(_.isArray(this.convLine.text)){
					reference.text = this.convLine.text[this.consecutiveLinesIndex].split(' ')[j];
				}else{
					reference.text = this.convLine.text.split(' ')[j];
				}
			}
			reference.highlightText = this.highlight(reference.text, this.words);
			reference.hasBreakline = [];
			//check for presence of "<br>" and/or "<br/>"
			var count = (reference.text.match(/(<br>)|(<br\/>)/g) || []).length;
			for(var i = 0; i < count; i++){
				reference.hasBreakline.push('<br>');
			}
			if(reference.hasBreakline.length === 0){
				reference.hasBreakline = false;
			}
			//replace "%HESITATION" on output
			if(reference.text.indexOf('%HESITATION') > -1){
				reference.text = reference.text.replace(/%HESITATION/g, '...');
				reference.highlightText = reference.highlightText.replace(/%HESITATION/g, '...');
			}
			cues.push(reference);
			reference.onenter = this.enterTextTrack;
		}
		//set subviews for rendering text / highlighting
		this.convTextViews = {
			convPrimaryTextView: new ConvPrimaryTextView({
				collection: new Backbone.Collection(cues)
			}),
			convTextTrackView: new ConvTextTrackView({
				collection: new Backbone.Collection(cues),
				parentId: this.numberSpeechBubbles
			})
		};
		//only render text / highlighting if elp level is low enough AND it's NOT user's speech
		if(this.questionTextRendering){
			this.assignSubViews({
				'.conv-primary-text': this.convTextViews.convPrimaryTextView,
				'.conv-text-track': this.convTextViews.convTextTrackView
			});
		}
		this.updateMathJax();

		this.player.on('ended', function(){
			//clean up last textTrack if exit not fired
			$('[id^=track]').removeClass('active');
			this.playing = false;
			//re-enable speech bubble's play button on audio end
			this.$el.find('.play-conversation').removeClass('speech-playing').attr('disabled', false);
		}.bind(this));

		this.cuesReady = true;
	},
	//checks if we can use original question text content for display on screen or not
	checkCanUseQuestionText(){
		//if this is the user's bubble, get outta here, we're using transcription as-is
		if(this.isUser){
			return false;
		}
		let questionContent = false;
		if(this.convLine.text){
			//either consecutive question lines as an array, or a single question line as a string
			if(_.isArray(this.convLine.text)){
				questionContent = this.convLine.text[this.consecutiveLinesIndex].split(' ');
			}else{
				questionContent = this.convLine.text.split(' ');
			}
		}
		//if original question text is same number of words as transcription, should be safe to use question
		//...otherwise, just use the transcription as-is
		if(this.textTrack.cues && questionContent && this.textTrack.cues.length === questionContent.length){
			return true;
		}
		return false;
	},
	enterTextTrack(event){
		var current = event.currentTarget.id;
		if(Number(current) >= this.convTextTrackIndex - 1){
			var timeRemaining = Math.max(0, this.minDuration - (Date.now() - this.previousTimestamp));
			this.previousTimestamp = Date.now() + timeRemaining;
			_.delay(function(){
				//target the text spans of this speech bubble specifically
				$(`[id^=track-${this.numberSpeechBubbles}]`).removeClass('active');
				$(`#track-${this.numberSpeechBubbles}-${current}`).addClass('active');
			}.bind(this), timeRemaining);
		}
	},
	startSlideMedia(){
		console.log('conv audio start'); // eslint-disable-line no-console
		// only when prepareTextTracks has completed
		if(!this.cuesReady){
			console.log('conv audio cues not ready'); // eslint-disable-line no-console
			return;
		}
		//should only be called once
		if(this.playing){
			console.log('conv audio already playing'); // eslint-disable-line no-console
			return;
		}
		this.playing = true;
		this.$el.find('.play-conversation').addClass('speech-playing').attr('disabled', true);
		if(this.updateTime){
			console.log('conv audio update time'); // eslint-disable-line no-console
			this.updateTime = false;
			this.player[0].currentTime = this.time;
		}
		// for browsers that treat play as a Promise
		let asyncPlay = this.player[0].play();
		if(asyncPlay instanceof Promise){
			asyncPlay
				.then(() => {
					console.log('conv audio started playing'); // eslint-disable-line no-console
				})
				.catch(error => {
					console.log('conv audio play'); // eslint-disable-line no-console
					console.log(error); // eslint-disable-line no-console
				});
		}
	},
	resetConvTextTrackIndex(){
		this.convTextTrackIndex = 1;
	},
	//plays speech bubble when play button is clicked
	playConversationSpeech(event){
		event.preventDefault();
		let $ele = $(event.currentTarget);
		//if src wasn't set yet, set it and load it
		if(!this.player.attr('src')){
			//set audio src and load it
			this.player.attr('src', this.model.get('result').audio);
			this.player[0].load();
		}
		if(!$ele.hasClass('speech-playing')){
			this.startSlideMedia();
		}
		this.manuallyPlayed = true;
	}
});
