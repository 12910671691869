import $ from 'jquery';
import AppBaseView from '../base/AppBaseView';
import nm from '../../nm';
import expectationsView from '../../templates/assignmentPlayer/expectationsView.handlebars';

export default AppBaseView.extend({
	events: {
		'click .close': 'closePanel'
	},
	id: 'nm-expectations',
	className: 'panel-overlay utility-hide',
	initialize: function(options){
		this.listenTo(nm.vent, 'lesson:expectations', this.show);
		this.listenTo(nm.vent, 'assignment_player:clicked_cover', this.closePanel);
	},
	render: function(){
		this.$el.html(expectationsView(this.model.toJSON()));
		this.updateMathJax();
		return this;
	},
	closePanel: function(event){
		if(event){
			event.preventDefault();
			var ele = $(event.currentTarget);
		}
		// Only hide the panel if it's visible in the first place
		if(!this.$el.hasClass('utility-hide')){
			this.$el.addClass('utility-hide');
			// hide fade-in "cover"
			$('.bg-fade').addClass('utility-hide');
			$('.bg-fade').addClass('hide-bg-fade');
		}
	},
	/**
	 * Display words and definitions for all primary words
	 * @param (int) id
	 * @return (void)
	 */
	show: function(id){
		this.$el.removeClass('utility-hide');
		// show fade-in "cover"
		$('.bg-fade').removeClass('utility-hide');
		$('.bg-fade').removeClass('hide-bg-fade');
		// ga('send', {
		// 	hitType: 'event',
		// 	eventCategory: 'Lesson Expectations',
		// 	eventAction: 'Lesson Expectations Opened',
		// 	eventLabel: 'User ID ' + nm.user.get('id') + ' opened lesson expectations'
		// });
	}
});
