import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const BackboneViewWrapper = ({ View, model, onNavigate }) => {
    const ref = useRef();
    const location = useLocation()


    useEffect(() => {
        console.log("new backbone view wrapper");
        const view = new View({
            el: ref.current,
            model: model,
            location: {
                hash: location.pathname + location.search
            },
            onNavigate: onNavigate,
        })
        view.render();
        return () => {
            // TODO is there a memory leak here?
            console.log("tearning down backbone wrapper");
            //view.remove();
        }
    }, [location.pathname, View, model])

    return (
        <div id="nm-appView" className="app-container">
            <div id="nm-page"><div ref={ref} />
            </div>
        </div>
    );
}

export default BackboneViewWrapper;
