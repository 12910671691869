import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import AppBaseView from '../base/AppBaseView';
import ReviewTextTracksView from '../assignmentPlayer/ReviewTextTracksView';
import ReviewPrimaryTextView from '../assignmentPlayer/ReviewPrimaryTextView';
import nm from '../../nm';
import definitionView from '../../templates/assignmentPlayer/definitionView.handlebars';

export default AppBaseView.extend({
	events: {
		'click .close': 'closePanel',
		'click .vocab-review': 'showVocabReview',
		'click .vocab-play': 'playPause',
		'click .vocab-review-definition': 'returnVocabReview'
	},
	id: 'nm-definition',
	className: 'definition-modal utility-hide',
	initialize: function(options){
		this.review = options.review;
		this.words = options.words;
		this.bundleId = options.bundleId;
		this.definition = '';
		this.hasReview = false;
		//below used to detect if review slides exist for a word at all, whether available to view or not
		this.reviewSlidesDefined = false;
		this.hasAudio = false;
		this.fromReview = false;
		this.fromPreTest = false;
		this.definitionId = 0;
		this.listenTo(nm.vent, 'words:define', this.retrieveSlideObject);
		this.listenTo(nm.vent, 'words:close', this.closePanel);
		this.listenTo(nm.vent, 'words:pause', this.audioPause);
		this.listenTo(nm.vent, 'slide:retrieve', this.update);
		this.listenTo(nm.vent, 'assignment_player:clicked_definition_holder', this.closePanel);
	},
	render: function(){
		this.updateMathJax();
		return this;
	},
	closePanel: function(event){
		if(event){
			event.preventDefault();
			var ele = $(event.currentTarget);
		}
		nm.vent.trigger('words:pause');
		$('#nm-definition-holder').addClass('utility-hide');
		this.$el.addClass('utility-hide');
		// hide fade-in "cover"
		$('.bg-fade').addClass('hide-bg-fade');
		$('.bg-fade').addClass('utility-hide');
	},
	// On load the slide object is requested so we know where we are at in the primer
	retrieveSlideObject: function(id, fromReview){
		this.definitionId = id;
		this.fromReview = fromReview;
		// If we are currently on the intro slide, pass a dummy slideObject to update
		// TODO need to figure this out? Do we need to pass in the fakeLocation?
		// var params = this.getSearchParameters(location.hash);
		// if(!params.key || params.key === 'intro'){
		// 	// Set the bundleId property, and a sortOrder so low it won't trigger any review slides to play
		// 	this.update({
		// 		bundleId: this.bundleId.id,
		// 		sortOrder: 0
		// 	});
		// }else if(params.key === 'outro' || params.key === 'pre-test-results' || params.key === 'grid'){
		// 	// If we are on outro, pass a similar but different slideObject
		// 	// Set the bundleId property, and a sortOrder so high it enable all review slides to play
		// 	this.update({
		// 		bundleId: this.bundleId.id,
		// 		sortOrder: 9999
		// 	});
		// }else{
		// 	// Otherwise, trigger slide:request as usual
		// 	nm.vent.trigger('slide:request');
		// }
	},
	/**
	 * Display word and definition by provided id
	 * @param (int) id
	 * @return (void)
	 */
	update: function(slideObject){
		this.definition = this.collection.get(this.definitionId).toJSON();
		this.hasReview = false;
		this.reviewSlidesDefined = false;
		if(this.definition.review.length > 0){
			this.reviewSlidesDefined = true;
			// Check if the review slides should be viewable
			// Pull only review slides for that vocab
			var reviewIds = this.definition.review;
			var allReviewSlides = this.review;
			var reviewSlides = [];
			reviewIds.forEach(function(reviewId){
				for(var i = 0; i < allReviewSlides.length; i++){
					if(reviewId === allReviewSlides[i].id){
						reviewSlides.push(allReviewSlides[i]);
						break;
					}
				}
			});
			this.reviewSlides = reviewSlides;
			// Loop through all review slides, assume all are viewable
			this.hasReview = true;
			for(var i = 0; i < this.reviewSlides.length; i++){
				// Compare bundle ids, see if review slides are in same assignment
				if(this.reviewSlides[i].bundleId === slideObject.bundleId){
					// If slide sort order is before review object, then this review should not be viewable
					if(slideObject.sortOrder <= this.reviewSlides[i].sortOrder){
						this.hasReview = false;
					}
				}
			}
		}
		this.hasAudio = false;
		if(this.definition.audio){
			this.hasAudio = true;
		}
		// Check if definition and alternate are the same
		this.identicalDefinitions = false;
		if(this.definition.alternate === this.definition.definition){
			this.identicalDefinitions = true;
		}
		this.$el.html(definitionView({
			definition: this.definition,
			hasReview: this.hasReview,
			fromReview: this.fromReview,
			identicalDefinitions: this.identicalDefinitions,
			reviewSlidesDefined: this.reviewSlidesDefined
		}));
		this.updateMathJax();
		//run media after render is complete
		_.defer((function(){
			this.runMedia();
		}).bind(this));
		$('#nm-definition-holder').removeClass('utility-hide');
		this.$el.removeClass('utility-hide');
		// show fade-in "cover"
		$('.bg-fade').removeClass('utility-hide');
		$('.bg-fade').removeClass('hide-bg-fade');
	},
	runMedia: function(){
		//if no audio, immediately enable continue button
		if(this.definition.audio){
			//TODO: if additional assets, like videos, are being loaded, wait for all assets to be ready
			var player = $('.definition-audio');
			var source = $('.definition-audio-mp3');
			source.on('error', function(err){
				$('.vocab-play').addClass('hide-audio');
			});
			//set up listener for audio event (must be set directly on DOM element, these events don't bubble up)
			var ele = $('.vocab-play');
			player.on('ended', function(){
				ele.addClass('audio-stopped').find('i').text('play_arrow');
				// this.allowContinue(true);
			}.bind(this));

			if(nm.user.get('exited')){
				// remove play button
				$('.vocab-play').addClass('hide-audio');
			}else{
				player.find('.definition-audio-mp3').attr('src', this.definition.audio);
				player[0].pause();
				player[0].load();
				player[0].oncanplaythrough = player[0].play();
			}
			// We actually don't want to prepare textTracks, since they don't exist yet (no captions for definitions)
			// this.prepareTextTracks(player);
		}
		// else if(!this.definition.questionId){
		// 	this.continueEnabled = true;
		// }
		// if(nm.user.get('type') === 'staff'){
		// 	this.continueEnabled = true;
		// }
	},
	playPause: function(event){
		event.preventDefault();
		var ele = $(event.currentTarget);
		if(this.hasAudio){
			$('#nm-definition').find('audio').each(function(){
			//TODO: is each needed, or is it implicitly executed during iteration
			// this.$el.find('audio').each(function(){
				var audio = $(this)[0];
				if(audio.paused){
					audio.play();
				}else{
					audio.pause();
				}
			});
			$('#nm-definition').find('video').each(function(){
				var video = $(this)[0];
				if(video.paused){
					video.play();
				}else{
					video.pause();
				}
			});
			this.managePlayPauseButton();
		}
	},
	// Pause audio when closing the panel
	audioPause: function(){
		$('#nm-definition').find('audio').each(function(){
		//TODO: is each needed, or is it implicitly executed during iteration
		// this.$el.find('audio').each(function(){
			var audio = $(this)[0];
			audio.pause();
		});
		$('#nm-definition').find('video').each(function(){
			var video = $(this)[0];
			video.pause();
		});
	},
	managePlayPauseButton: function(setToPlaying){
		var ele = $('.vocab-play');
		setToPlaying = setToPlaying || false;
		if(setToPlaying || ele.hasClass('audio-stopped')){
			ele.removeClass('audio-stopped').find('i').text('pause');
		}else{
			ele.addClass('audio-stopped').find('i').text('play_arrow');
		}
	},
	//takes the active audio jquery element
	prepareTextTracks: function(player){
		// This is what would have happened
		if(player.get(0).textTracks.length > 0){
			this.textTrack = player.get(0).textTracks[0];
			//have to wait for cues to load from vtt
			$('#nm-definition audio track').on('load', function(){
				var cues = [];
				var reference;
				for(var j = 0; j < this.textTrack.cues.length; j++){
					reference = this.textTrack.cues[j];
					reference.highlightText = this.highlight(reference.text, this.words);
					cues.push(reference);
					reference.onenter = this.enterTextTrack;
				}
				this.views = {
					textTracks: new ReviewTextTracksView({
						collection: new Backbone.Collection(cues)
					}),
					primaryText: new ReviewPrimaryTextView({
						collection: new Backbone.Collection(cues)
					})
				};
				this.assignSubViews({
					'#nm-definition-text-tracks-holder': this.views.textTracks,
					'#nm-definition-primary-text-holder': this.views.primaryText
				});
				player.on('ended', function(){
					//clean up last textTrack if exit not fired
					$('[id^=definition-track]').removeClass('active');
				}.bind(this));
			}.bind(this));
		}
	},
	// We won't ever trigger this function, as this has not been implemented.
	enterTextTrack: function(event){
		var current = event.currentTarget.id;
		var timeRemaining = Math.max(0, this.minDuration - (Date.now() - this.previousTimestamp));
		this.previousTimestamp = Date.now() + timeRemaining;
		_.delay(function(){
			$('[id^=definition-track]').removeClass('active');
			$('#definition-track-' + current).addClass('active');
		}.bind(this), timeRemaining);
	},
	showVocabReview: function(event){
		event.preventDefault();
		var ele = $(event.currentTarget);
		nm.vent.trigger('words:close');
		nm.vent.trigger('review:open', this.definition, this.reviewSlides);
	},
	returnVocabReview: function(event){
		event.preventDefault();
		var ele = $(event.currentTarget);
		nm.vent.trigger('words:close');
		nm.vent.trigger('review:return');
	}
});
