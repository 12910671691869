import Handlebars from 'handlebars';
import Backbone from 'backbone';
import 'backbone-validation';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import inlineErrorMessage from './templates/inlineErrorMessage.handlebars';
import errorMessage from './templates/errorMessage.handlebars';
import $ from 'jquery';
import _ from 'underscore';

class Nm {
	constructor() {
		this.base = 'https://sls.positivelearning.com';
        /* eslint-disable */
        this.env = {
			stag: 'esv1',
			prod: 'epv1',
			reese: 'erv1',
			test: 'etv1',
			demo: 'edv1'
		},
			//configuration for AWS Cognito authentication/authorization
			this.aws = {}; //set as a part of setAppProperties()
		this.tracking = false; //flag for tracking activity via Google Analytics
		//Primary Configuration
		////////////////////////////////////////////////////////////////////////////////////////////////////////
		this.lastRequestQueue = [];
		//power bar
		this.lastRequestDefault = 6, //amount of attempts available to get session, after which the user will be logged out
			this.lastRequestAttempts = 0, //amount of attempts used
			////////////////////////////////////////////////////////////////////////////////////////////////////////
			//ELP Configuration
			////////////////////////////////////////////////////////////////////////////////////////////////////////
			//for language cores modeled after WIDA, use label map
			this.langCoreLabels = {
				'azella': {
					1: 'Pre-Emergent',
					2: 'Emergent',
					3: 'Basic',
					4: 'Intermediate',
					5: 'Proficient'
				},
				'texas': {
					1: 'Beginning',
					2: 'Intermediate',
					3: 'Advanced',
					4: 'Advanced High'
				},
				'default': { //WIDA-type model
					1: '1',
					2: '2',
					3: '3',
					4: '4',
					5: '5'
				}
			};
		//default app settings, which get replaced / updated by successful fetch of Organization upon session:start
		this.appSettings = {
			clientName: 'Positive Learning',
			searchPlaceholder: 'Type here to search for primers',
			background: 'img/bg.png',
			logo: 'img/logo1.png',
			loginCopy: 'Login'
		};
		//Auto select audio speeds by student ELP level
		this.audioSpeeds = {
			defaultElp: 3,
			slow: {
				lowerBound: 0, // >=
				upperBound: 2 // <
			},
			default: {
				lowerBound: 3, // >=
				upperBound: 3.6 // <
			},
			fast: {
				lowerBound: 3.6, // >=
				upperBound: 6 // <
			}
		}
		////////////////////////////////////////////////////////////////////////////////////////////////////////
		this.appKey = null; //district group key for session
		this.expires = 600000 //seconds, about a week
		//for setInterval functions across pages
		this.interval = null;
		//for setTimeout functions across pages
		this.timeout = null,
			//used throughout for 'delay time' during page transitions
			this.pageTransitionTime = 300;
		this.isProduction = true;
		this.isLocal = false,
		// TODO what transitions do we need?
		//this.transition = $('#nm-page-transitions'); //loading screen/animation between states
		this.notificationDisplayDelay = 100;
		this.notificationHideDelay = 7000;
		this.undoTime = 6000;
		this.editSequenceExtensionTime = 300000; //five minutes
		this.editSequenceCountdownTime = 60000; //one minute
		this.isMobile = false;
		this.temporaryPassword = null; //store a user's temporary password, used when user prompted to update password
		this.trackingTimeLimit = 3600; //arbitrary time limit set against tracking practice resource/question times
		this.largeScreenBreakpoint = 1480; //used for main menu open/close behavior. Same as css XXLScreen variable definition.
		//define order of assessments when presented in bucketed list
		this.bucketedListDefinition = [
			'practice_test',
			'p2p_test',
			'vocab_test',
			'pre_test',
			'post_test'
		];
		this.searchExclude = ["a", "able", "about", "above", "across", "after", "again", "against", "ain't", "all",
			"almost", "also", "am", "among", "an", "and", "any", "are", "aren't", "as", "at", "be", "because",
			"been", "before", "being", "below", "between", "both", "but", "by", "can", "can't", "cannot",
			"could", "could've", "couldn't", "dear", "did", "didn't", "do", "does", "doesn't", "doing", "don't",
			"down", "during", "each", "either", "else", "ever", "every", "few", "for", "from", "further", "get",
			"got", "had", "hadn't", "has", "hasn't", "have", "haven't", "having", "he", "he'd", "he'll", "he's",
			"her", "here", "here's", "hers", "herself", "him", "himself", "his", "how", "how'd", "how'll", "how's",
			"however", "i", "i'd", "i'll", "i'm", "i've", "if", "in", "into", "is", "isn't", "it", "it's", "its",
			"itself", "just", "least", "let", "let's", "like", "likely", "may", "me", "might", "might've", "mightn't",
			"more", "most", "must", "must've", "mustn't", "my", "myself", "neither", "no", "nor", "not", "of", "off",
			"often", "on", "once", "only", "or", "other", "ought", "our", "ours", "ourselves", "out", "over", "own",
			"rather", "said", "same", "say", "says", "shall", "shan't", "she", "she'd", "she'll", "she's", "should",
			"should've", "shouldn't", "since", "so", "some", "such", "than", "that", "that'll", "that's", "the",
			"their", "theirs", "them", "themselves", "then", "there", "there's", "these", "they", "they'd", "they'll",
			"they're", "they've", "this", "those", "through", "tis", "to", "too", "twas", "under", "until", "up",
			"us", "very", "wants", "was", "wasn't", "we", "we'd", "we'll", "we're", "we've", "were", "weren't",
			"what", "what'd", "what's", "when", "when'd", "when'll", "when's", "where", "where'd", "where'll",
			"where's", "which", "while", "who", "who'd", "who'll", "who's", "whom", "why", "why'd", "why'll",
			"why's", "will", "with", "won't", "would", "would've", "wouldn't", "yet", "you", "you'd", "you'll",
			"you're", "you've", "your", "yours", "yourself", "yourselves"];
		this.comicPanelDefinitions = {
			full_width_tti: ['c-100', 'tti'],
			full_width_itt: ['c-100', 'itt'],
			threequarter_width_left_tti: ['c-75', 'pos-l', 'tti'],
			threequarter_width_left_itt: ['c-75', 'pos-l', 'itt'],
			threequarter_width_right_tti: ['c-75', 'pos-r', 'tti'],
			threequarter_width_right_itt: ['c-75', 'pos-r', 'itt'],
			twothird_width_left_tti: ['c-66', 'pos-l', 'tti'],
			twothird_width_left_itt: ['c-66', 'pos-l', 'itt'],
			twothird_width_right_tti: ['c-66', 'pos-r', 'tti'],
			twothird_width_right_itt: ['c-66', 'pos-r', 'itt'],
			half_width_left_tti: ['c-50', 'pos-l', 'tti'],
			half_width_left_itt: ['c-50', 'pos-l', 'itt'],
			half_width_right_tti: ['c-50', 'pos-r', 'tti'],
			half_width_right_itt: ['c-50', 'pos-r', 'itt'],
			half_width_left_tti_alt: ['c-50', 'pos-l', 'tti-alt'],
			half_width_left_itt_alt: ['c-50', 'pos-l', 'itt-alt'],
			half_width_right_tti_alt: ['c-50', 'pos-r', 'tti-alt'],
			half_width_right_itt_alt: ['c-50', 'pos-r', 'itt-alt'],
			half_width_middle_tti: ['c-50', 'pos-m', 'tti'],
			half_width_middle_itt: ['c-50', 'pos-m', 'itt'],
			half_width_middle_tti_alt: ['c-50', 'pos-m', 'tti-alt'],
			half_width_middle_itt_alt: ['c-50', 'pos-m', 'itt-alt'],
			onethird_width_left_tti: ['c-33', 'pos-l', 'tti'],
			onethird_width_left_itt: ['c-33', 'pos-l', 'itt'],
			onethird_width_right_tti: ['c-33', 'pos-r', 'tti'],
			onethird_width_right_itt: ['c-33', 'pos-r', 'itt'],
			onethird_width_middle_itt: ['c-33', 'pos-m', 'itt'],
			onethird_width_middle_tti: ['c-33', 'pos-m', 'tti'],
			onethird_width_left_tti_alt: ['c-33', 'pos-l', 'tti-alt'],
			onethird_width_left_itt_alt: ['c-33', 'pos-l', 'itt-alt'],
			onethird_width_right_tti_alt: ['c-33', 'pos-r', 'tti-alt'],
			onethird_width_right_itt_alt: ['c-33', 'pos-r', 'itt-alt'],
			onethird_width_middle_itt_alt: ['c-33', 'pos-m', 'itt-alt'],
			onethird_width_middle_tti_alt: ['c-33', 'pos-m', 'tti-alt'],
			onequarter_width_left_tti: ['c-25', 'pos-l', 'tti'],
			onequarter_width_left_itt: ['c-25', 'pos-l', 'itt'],
			onequarter_width_middleleft_tti: ['c-25', 'pos-ml', 'tti'],
			onequarter_width_middleleft_itt: ['c-25', 'pos-ml', 'itt'],
			onequarter_width_right_tti: ['c-25', 'pos-r', 'tti'],
			onequarter_width_right_itt: ['c-25', 'pos-r', 'itt'],
			onequarter_width_middleright_tti: ['c-25', 'pos-mr', 'tti'],
			onequarter_width_middleright_itt: ['c-25', 'pos-mr', 'itt']
		};
		// default layout classes applied to comic panel display of a primer,
		// if none of the primer's slides include their own definitions
		this.defaultComicLayout = [
			'full_width_tti',
			'half_width_left_tti',
			'half_width_right_itt',
			'full_width_itt',
			'threequarter_width_left_tti',
			'onequarter_width_right_tti',
			'half_width_left_tti_alt',
			'half_width_right_itt_alt',
			'onequarter_width_left_itt',
			'threequarter_width_right_itt',
			'twothird_width_left_tti',
			'onethird_width_right_tti',
			'onethird_width_left_itt',
			'twothird_width_right_itt',
			'onethird_width_left_tti_alt',
			'onethird_width_middle_itt_alt',
			'onethird_width_right_tti',
			'half_width_left_tti',
			'onequarter_width_middleright_tti',
			'onequarter_width_right_itt',
			'onequarter_width_left_itt',
			'half_width_middle_itt',
			'onequarter_width_right_tti',
			'onequarter_width_left_itt',
			'onequarter_width_middleleft_tti',
			'half_width_right_itt',
			'onequarter_width_left_tti',
			'onequarter_width_middleleft_itt',
			'onequarter_width_middleright_itt',
			'onequarter_width_right_tti'
		];
		this.questionInstructions = {
			fill_in: 'Fill in the blank.',
			true_false: 'True or False.',
			multiple: 'Choose the best answer.',
			write_about: 'Practice your writing.',
			open_ended: 'Answer the question.',
			enhanced_elp: 'Follow the instructions.'
		};

		this.api = process.env.REACT_APP_API_ENDPOINT;
	}
	//return a language core label for a given elp level in a given language core
	getLangCoreLabel(elpLevel) {
		//if no corresponding labels available, just return the elp level
		if (!this.langCoreLabels[this.appSettings.langCore]) {
			return elpLevel;
		}

		const numLabels = _.size(this.langCoreLabels[this.appSettings.langCore]);
		//if elp level is higher than highest available label, set it to exactly that label
		if (elpLevel > numLabels) {
			elpLevel = numLabels;
		}

		const label = this.langCoreLabels[this.appSettings.langCore][Math.round(elpLevel)];
		//if no label comes back, just return elpLevel
		if (!label) {
			return elpLevel;
		}

		return label;
	}
	//return whether a student is a native speaker or exited
	exitedStudent(elpLevel) {
		let highestLevel = _.size(this.langCoreLabels.default);
		// if corresponding langCore levels available, determine new upper bound
		// all elp levels are stored as integers
		if (this.langCoreLabels[this.appSettings.langCore]) {
			highestLevel = _.size(this.langCoreLabels[this.appSettings.langCore]);
		}
		// if elp level is higher than highest available label, set it to exactly that label
		if (elpLevel > highestLevel) {
			return true;
		}
		return false;
	}

	/**
	 * setAppProperties
	 * Sets various app-wide settings based on received Organization config
	 * @param (object) organization
	 * @return (void)
	 */
	setAppProperties(organization) {
		//set appsettings with config we've received, but preserve defaults we've set if no replacement comes in
		this.appSettings = _.extend(this.appSettings, organization.get('result').config);
		this.appKey = organization.get('result').key; //set app key, which is the district group for session login
		// assume customer is a "Positive Learning" customer,
		// define a list of customers / domains that are "Ellevation Customers", by "organization key"
		this.isEllevationCustomer = false;
		const ellevationCustomers = [
			'demo-tx',
			'demo-cc'
		];
		// if current Org is either one of the above, or, has an ellevation_district_id, then
		// hide / change / update "Positive Learning" to "Ellevation Math" all over the app.
		// Sets text, images, logos, and other instances from one name to the other
		if(ellevationCustomers.indexOf(this.appKey) > -1 || organization.get('result').ellevation_district_id){
			this.isEllevationCustomer = true;
			this.appSettings.logo = 'img/logo_ellevation_square.png';
			this.appSettings.logoNavHeader = 'img/logo_ellevation_square_white.png';
			this.appSettings.appName = 'Ellevation Math';
			document.title = this.appSettings.appName;
			$('meta[name=application-name]').attr('content', this.appSettings.appName);
		}
		//establish user pool
		this.aws.userPool = new CognitoUserPool({
			UserPoolId: organization.get('result').config.aws.userPoolId,
			ClientId: organization.get('result').config.aws.clientId
		});
		this.aws.cognitoUser = this.aws.userPool.getCurrentUser();
	}

	/**
		 * config
		 * default settings for ajax and app
		 * @return (void)
		 */
	config() {
		//set small cache for jquery
		$.expr.cacheLength = 1;
		//click events remove delay
		this.setupHandlebars();
		//add error messaging to Backbone.Validation
		_.extend(Backbone.Validation.callbacks, {
			valid: function (view, attr, selector) {
				var escapedAttr = attr.replace(/(:|\.|\[|\])/g, "\\$1");
				var ele = view.$('[name=' + escapedAttr + ']');
				var eleParentFormRow = ele.parents('.form-row');
				eleParentFormRow.removeClass('input-error').find('i').remove();
				eleParentFormRow.find('.nm-error').remove();
			},
			invalid: function (view, attr, error, selector) {
				/**
				 * We'll need to identify the form input element, it's containing 'row', it's form,
				 * and a few other deets about the sitch before we apply the correct error messaging
				 * and markup, natch.
				 */
				var escapedAttr = attr.replace(/(:|\.|\[|\])/g, "\\$1");
				var ele = view.$('[name=' + escapedAttr + ']');
				var eleType = ele.attr('type');
				var eleParentFormRow = ele.parents('.form-row');
				var eleParentFormRowDiv = null;
				var eleSubmitFormRow = ele.parents('form').find('input[type="submit"]').parents('.form-row');
				var errorStyle = ele.parents('form').data('error-style');
				var isRadioOrCheckbox = false;
				if (eleType === 'radio' || eleType === 'checkbox') {
					isRadioOrCheckbox = true;
					eleParentFormRowDiv = eleParentFormRow.find('> div');
				}
				//remove any 'old' error messaging from previous tries
				eleParentFormRow.find('.nm-error').remove();
				//style up the offending rows/input elements
				eleParentFormRow.addClass('input-error').find('label').after('<i class="material-icons color-red">close</i>');
				eleSubmitFormRow.addClass('input-error');
				//use specific "error style", can be defined as 'data-error-style' on form element
				if (errorStyle === 'inline') {
					//since radio/checkbox inputs have unique markup, handle error message... uniquely
					if (isRadioOrCheckbox) {
						eleParentFormRowDiv.append(inlineErrorMessage({
							name: attr,
							message: error
						}));
					} else {
						eleParentFormRow.append(inlineErrorMessage({
							name: attr,
							message: error
						}));
					}
				} else {
					//since file inputs have unique markup, handle error message... uniquely
					if (eleType === 'file') {
						eleParentFormRow.append(errorMessage({
							name: attr,
							message: error
						}));
						//unique handling of choices.js-powered select input rows
					} else if (ele.hasClass('choices__input')) {
						eleParentFormRow.append(errorMessage({
							name: attr,
							message: error
						}));
					} else {
						ele.after(errorMessage({
							name: attr,
							message: error
						}));
					}
				}
			}
		})
	}

	setupHandlebars() {
		//register all templates as partials
		//Handlebars.partials = Hbs.templates;
		//add Hbs helpers
		//updated 'equal' helper, now called 'compare'. Much useful. Very power.
		//http://doginthehat.com.au/2012/02/comparison-block-helper-for-handlebars-templates/#comment-44
		Handlebars.registerHelper('compare', function (lvalue, operator, rvalue, options) {
			var operators;
			var result;
			if (arguments.length < 3) {
				throw new Error("Handlerbars Helper 'compare' needs 2 parameters");
			}
			if (options === undefined) {
				options = rvalue;
				rvalue = operator;
				operator = "===";
			}
			operators = {
				'==': function (l, r) { return l == r; },
				'===': function (l, r) { return l === r; },
				'!=': function (l, r) { return l != r; },
				'!==': function (l, r) { return l !== r; },
				'<': function (l, r) { return l < r; },
				'>': function (l, r) { return l > r; },
				'<=': function (l, r) { return l <= r; },
				'>=': function (l, r) { return l >= r; },
				'typeof': function (l, r) { return typeof l == r; }
			};
			if (!operators[operator]) {
				throw new Error("Handlerbars Helper 'compare' doesn't know the operator " + operator);
			}
			result = operators[operator](lvalue, rvalue);
			if (result) {
				return options.fn(this);
			} else {
				return options.inverse(this);
			}
		});
		var theAlphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
		Handlebars.registerHelper("indexToLetter", function (theIndex) {
			var theLetter = theAlphabet[theIndex].toUpperCase();
			return theLetter;
		});
		Handlebars.registerHelper("indexPlusOne", function (theIndex) {
			theIndex = theIndex + 1;
			return theIndex;
		});
		Handlebars.registerHelper('removeHtml', function (options) {
			var content = _.unescape(options.fn(this));
			content = content.replace(/(<([^>]+)>)/ig, "");
			return content;
		});
		//helper for counting partials within templates for display. e.g "Step 1"
		/* eslint-disable no-underscore-dangle */
		Handlebars.registerHelper("partialCount", function (reset) {
			if (typeof reset === 'boolean') {
				Handlebars._partialCounter = 0;
				return "";
			}
			if (Handlebars._partialCounter) {
				Handlebars._partialCounter++;
			} else {
				Handlebars._partialCounter = 1;
			}
			return Handlebars._partialCounter;
		});
		/* eslint-enable no-underscore-dangle */
		//helper that lets us iterate something "x" times (http://goo.gl/jkJVx5)
		Handlebars.registerHelper('times', function (n, position, block) {
			var accum = '';
			if (position) {
				n = n - position;
			}
			for (var i = 0; i < n; ++i) {
				accum += block.fn(i);
			}
			return accum;
		});
	}
};

export default new Nm();
