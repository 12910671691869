var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<div class=\"enhanced-stem-fill-column\">\n		<div id=\"nm-enhanced-stem-fill-view\" class=\"enhanced-stem-fill-view\">\n		</div>\n	</div>\n	<div class=\"enhanced-stem-fill-column\">\n		<div class=\"enhanced-stem-fill-img\">\n			<img src=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"image") || (depth0 != null ? lookupProperty(depth0,"image") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"image","hash":{},"data":data,"loc":{"start":{"line":11,"column":13},"end":{"line":11,"column":22}}}) : helper)))
    + "\" title=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"elpContent") : depth0)) != null ? lookupProperty(stack1,"content") : stack1), depth0))
    + "\"/>\n		</div>\n	</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "	<div class=\"enhanced-stem-fill-column \">\n		<div id=\"nm-enhanced-stem-fill-view\" class=\"enhanced-stem-fill-view\">\n		</div>\n	</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"enhanced-elp-input enhanced-stem-fill\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"image") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":1},"end":{"line":20,"column":8}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});