import React from 'react';
import ReactDOM from 'react-dom';
import Backbone from 'backbone';
import App from './App';
import Amplify, { Auth } from 'aws-amplify';
import nm from './backbone/nm';
import _ from 'underscore';

// globally configure AWS cognito
Amplify.configure({
  Auth: {
    region: 'us-east-2',
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID,
  }
});

// inject current user jwt to Backbone model calls, if there is a current user
const backboneSync = Backbone.sync;
Backbone.sync = async (method, model, options) => {
  const currentSession = await Auth.currentSession();

  if (currentSession && currentSession.idToken) {
    options.headers = {
      ...options.headers,
      'authorization': currentSession.idToken.jwtToken,
    };
  }

  backboneSync(method, model, options);
};

// start up backbone event
nm.vent = _.extend({}, Backbone.Events);
nm.config();

ReactDOM.render(<App />, document.getElementById('root'));
