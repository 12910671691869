import _ from 'underscore';
import AppBaseView from '../base/AppBaseView';
import stemFillBankView from '../../templates/questions/enhancedElp/stemFillBankView.handlebars';

export default AppBaseView.extend({
	events: {
	},
	className: 'stem-fill-bank-holder',
	initialize: function(options){
		_.bindAll(this, 'errorHandler');
		this.wordBank = options.wordBank;
	},
	render: function(){
		this.$el.html(stemFillBankView({
			wordBank: this.wordBank
		}));
		return this;
	}
});
