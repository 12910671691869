import $ from 'jquery';
import AppBaseView from '../base/AppBaseView';
import ReviewContentView from './ReviewContentView';
import ReviewProgressView from './ReviewProgressView';
import ReviewControlsView from './ReviewControlsView';
import nm from '../../nm';
import reviewView from '../../templates/assignmentPlayer/reviewView.handlebars';

export default AppBaseView.extend({
	events: {
		'click .disabled-image-link': 'preventSubmit'
	},
	className: 'review-modal utility-hide',
	initialize: function(options){
		this.review = options.review;
		this.words = options.words;
		this.currentIndex = 0;
		this.progress = 0;
		this.definitionId = 0;
		this.hasNextSlide = true;
		this.listenTo(nm.vent, 'review:open', this.loadObjects);
		this.listenTo(nm.vent, 'review:close', this.closePanel);
		this.listenTo(nm.vent, 'review:return', this.openPanel);
		this.listenTo(nm.vent, 'review:next_slide', this.nextSlide);
		this.listenTo(nm.vent, 'assignment_player:clicked_review_holder', this.closePanel);
	},
	loadObjects: function(currentVocab, reviewSlides){
		// Figure out which vocabulary word is current
		this.currentIndex = 0;
		this.progress = 0;
		this.currentVocab = currentVocab;
		this.reviewSlides = reviewSlides;
		this.currentReview = this.reviewSlides[this.currentIndex];
		this.update();
		return this;
	},
	update: function(){
		this.$el.html(reviewView());
		this.loadState();
		if(!nm.isMobile){
			this.showLiveChat();
		}else{
			this.hideLiveChat();
		}
		return this;
	},
	render: function(){
		return this;
	},
	loadState: function(){
		this.setProgress();
		// See if current review slide has audio
		this.hasAudio = true;
		if(this.currentReview && !this.currentReview.audio){
			this.hasAudio = false;
		}
		// See if there is another slide after this one
		this.hasNextSlide = true;
		if(this.currentIndex === this.reviewSlides.length - 1){
			this.hasNextSlide = false;
		}
		//close existing views
		this.closeViewSet(this.views);
		this.views = {
			content: new ReviewContentView({
				words: this.words,
				vocab: this.currentVocab,
				review: this.currentReview
			}),
			progress: new ReviewProgressView({
				progress: this.progress
			}),
			controls: new ReviewControlsView({
				hasAudio: this.hasAudio,
				hasNextSlide: this.hasNextSlide
			})
		};
		this.assignSubViews({
			'#nm-review-content': this.views.content,
			'#nm-review-progress': this.views.progress,
			'#nm-review-controls': this.views.controls
		});
		$('#nm-review-holder').removeClass('utility-hide');
		this.$el.removeClass('utility-hide');
		// show fade-in "cover"
		$('.bg-fade').removeClass('hide-bg-fade');
		$('.bg-fade').removeClass('utility-hide');
	},
	closePanel: function(event){
		if(event){
			event.preventDefault();
			var ele = $(event.currentTarget);
		}
		if(this.currentReview && this.currentReview.audio){
			nm.vent.trigger('review:pause');
		}
		$('#nm-review-holder').addClass('utility-hide');
		this.$el.addClass('utility-hide');
		// hide fade-in "cover"
		$('.bg-fade').addClass('hide-bg-fade');
		$('.bg-fade').addClass('utility-hide');
	},
	// If we are returning from the definition view, then unhide the panel without resetting data
	openPanel: function(){
		$('#nm-review-holder').removeClass('utility-hide');
		$('.review-modal').removeClass('utility-hide');
		// show fade-in "cover"
		$('.bg-fade').removeClass('hide-bg-fade');
		$('.bg-fade').removeClass('utility-hide');
	},
	nextSlide: function(){
		this.currentIndex += 1;
		if(this.currentIndex === this.reviewSlides.length){
			nm.vent.trigger('review:close');
			nm.vent.trigger('words:define', this.currentVocab.id);
		}else{
			this.currentReview = this.reviewSlides[this.currentIndex];
			this.loadState();
		}

	},
	setProgress: function(){
		this.progress = Math.round(((this.currentIndex + 1) / this.reviewSlides.length) * 100);
	}
});
