/**
 * ConvPrimaryTextView
 * Renders conversation text track
 */
import _ from 'underscore';
import AppBaseView from '../base/AppBaseView';
import nm from '../../nm';
import convPrimaryTextView from '../../templates/questions/enhancedElp/convPrimaryTextView.handlebars';

export default AppBaseView.extend({
	events: {},
	className: 'conv-primary-text-holder',
	textTrack: null,
	initialize: function(){
		_.bindAll(this, 'errorHandler');
	},
	render: function(){
		this.$el.html(convPrimaryTextView(this.collection.toJSON()));
		nm.vent.trigger('convOutput:rendered');
		nm.vent.trigger('view:render', {playerIsActive: true});
		return this;
	}
});
