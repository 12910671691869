import $ from 'jquery';
import _ from 'underscore';
import Hbs from 'handlebars';
import Backbone from 'backbone';
import WaveSurfer from 'wavesurfer';
import AppBaseView from '../base/AppBaseView';
import SpeechToText from '../../models/SpeechToText';
import InputPrimaryTextView from './InputPrimaryTextView';
import InputTextTrackView from './InputTextTrackView';
import nm from '../../nm';
import convInputView from '../../templates/questions/enhancedElp/convInputView.handlebars';

export default AppBaseView.extend({
	events: {
		'click #nm-start-recording': 'startRecording',
		'click #nm-stop-recording': 'stopRecording',
		'click #nm-submit-recording': 'submitRecording',
		'click #nm-cancel-recording': 'cancelRecording',
		'click #nm-play-transcription': 'playTranscription'
	},
	className: 'recording-view-holder',
	player: null,
	mediaRecorder: null, //will be used for MediaRecorder constructor
	recordingTimeout: null, //store reference to timeout to cancel audio recording after set expiration time
	maxRecordingTime: 10000, //don't allow recording for more than 10 seconds
	audioChunks: [], //array for storing recorded audio chunks
	audioFile: null, //store audio file for use
	trimmedAudioFile: null, //audio file data only for sending to endpoint for transcription
	errorText: '<em>Oops, I couldn\'t hear you, try again.</em>', //used if there's a problem obtaining transcription
	inputTextTrackIndex: 1,
	textTrack: null,
	cuesReady: false,
	playing: false,
	updateTime: false,
	minDuration: 100, //ms
	previousTimestamp: 0,
	contentType: null, //set the audio content type according to browser support
	waveform: null, //holds instance of our waveform visualization
	recentTranscription: null, //holds the last transcription that was received from speech to text endpoint
	initialize(options){
		_.bindAll(this, 'errorHandler', 'enterTextTrack');
		this.question = options.question;
		this.disabled = false; //enable mic input by default
		this.renderErrors = false;
		if(options.disabled){
			this.disabled = options.disabled;
		}
		if(options.renderErrors){
			this.renderErrors = options.renderErrors;
		}
		this.setContentType();
		this.listenTo(nm.vent, 'continue:enabled', this.disableRecording);
		this.listenTo(nm.vent, 'speechToText:received', this.receivedTranscription);
		this.listenTo(nm.vent, 'input-text-track:reset', this.resetInputTextTrackIndex);
		this.listenTo(nm.vent, 'speechToText:error', this.speechToTextError);
		this.listenTo(nm.vent, 'audio:ended', this.enableRecordingButton);
		this.listenTo(nm.vent, 'recording:disable', this.disableRecordingButton);
	},
	render(){
		this.$el.html(convInputView({
			enablePlayback: this.enablePlayback,
			disabled: this.disabled
		}));
		nm.vent.trigger('view:render', {playerIsActive: true});
		return this;
	},
	startRecording(event){
		event.preventDefault();

		//manage ui
		let $startButton = $('#nm-start-recording');
		let $stopButton = $('#nm-stop-recording');
		let $submitButton = $('#nm-submit-recording');
		let $listeningIndicator = $('.listening-indicator');
		$startButton.removeClass('bounceIn').addClass('hide-button');
		$stopButton.removeClass('hide-button').addClass('bounceIn');
		$submitButton.addClass('hide-button').removeClass('bounceIn');
		$listeningIndicator.find('> div').addClass('entrance-it').find('div').addClass('pulsate-it');

		//remove any previously rendered transcription output views, hide play button, reset audio & track elements
		this.closeViewSet(this.inputTextViews);
		this.$el.find('.play-transcription').addClass('hide-play-transcription');
		$('.input-primary-text').empty();
		if(this.waveform){
			this.waveform.destroy();
		}

		//actually trigger mediaDevice / prompt mic access / start recording
		navigator.mediaDevices.getUserMedia({audio: true})
			.then((stream) => {
				//set up media recorder
				this.mediaRecorder = new MediaRecorder(stream, {
					mimeType: this.contentType
				});
				this.audioChunks = [];
				//listen as data is recorded, gather as "audio chunks"
				this.mediaRecorder.ondataavailable = (event) => {
					this.audioChunks.push(event.data);
				};
				//listen for when recorder is stopped, then proceed with processing the recording
				this.mediaRecorder.onstop = () => {
					this.processRecording();
				};
				//fallback to stop recording after pre-set expiration time
				this.recordingTimeout = setTimeout(() => {
					this.stopRecording();
				}, this.maxRecordingTime);
				//finally, actually start recording
				this.mediaRecorder.start();
			})
			.catch((err) => {
				console.log('Media Device error'); // eslint-disable-line no-console
				//TODO: More sophisticated error handling here?
				//send exception event to google analytics
				// ga('send', 'exception', {
				// 	'exDescription': 'Recording / Media Device error: ' + err,
				// 	'exFatal': false
				// });
			});
	},
	stopRecording(event){
		if(event){
			event.preventDefault();
		}
		//manage ui
		let $stopButton = $('#nm-stop-recording');
		let $listeningIndicator = $('.listening-indicator');
		$stopButton.removeClass('bounceIn').addClass('hide-button');
		$listeningIndicator.find('div').removeClass();
		//stop recording and clear timeout
		clearTimeout(this.recordingTimeout);
		this.mediaRecorder.stop();
	},
	processRecording(){
		const audioBlob = new Blob(this.audioChunks, {
			type: this.contentType
		});
		const reader = new FileReader();

		//manage ui, show loading while recording is processed
		let $startButton = $('#nm-start-recording');
		let $stopButton = $('#nm-stop-recording');
		let $submitButton = $('#nm-submit-recording');
		let $loading = $('#nm-loading-recording');
		$startButton.removeClass('bounceIn').addClass('hide-button');
		$stopButton.removeClass('bounceIn').addClass('hide-button');
		$submitButton.addClass('hide-button').removeClass('bounceIn');
		$loading.removeClass('hide-button').addClass('flash infinite');

		//convert audioBlob to base64 data url
		reader.onload = () => {
			this.audioFile = reader.result;
			this.trimmedAudioFile = reader.result.split(',').pop(); //send up only the actual base64 data string
			this.postSpeechToText();
		}
		reader.readAsDataURL(audioBlob);
	},
	submitRecording(event){
		if(event){
			event.preventDefault();
		}
		//manage ui
		let $submitButton = $('#nm-submit-recording');
		let $startButton = $('#nm-start-recording');
		$submitButton.removeClass('bounceIn').addClass('hide-button');
		$startButton.removeClass('hide-button').addClass('bounceIn');
		this.closeViewSet(this.inputTextViews);
		this.$el.find('.play-transcription').addClass('hide-play-transcription');
		this.$el.find('.cancel-recording').addClass('hide-cancel-recording');
		$('.input-primary-text').empty();
		//diable recording button until next conversation line finishes
		this.disableRecordingButton();
		//tell PracticeConversationView to go ahead and render the student's submission in conversation
		nm.vent.trigger('conversation:userSpoke', this.recentTranscription);
		//push audio file to array we'll later be submitting
		nm.vent.trigger('audio:save', this.audioFile);
	},
	cancelRecording(event){
		if(event){
			event.preventDefault();
		}
		//manage ui
		let $submitButton = $('#nm-submit-recording');
		let $startButton = $('#nm-start-recording');
		$submitButton.removeClass('bounceIn').addClass('hide-button');
		$startButton.removeClass('hide-button').addClass('bounceIn');
		this.closeViewSet(this.inputTextViews);
		this.$el.find('.play-transcription').addClass('hide-play-transcription');
		this.$el.find('.cancel-recording').addClass('hide-cancel-recording');
		$('.input-primary-text').empty();
	},
	enableRecordingButton(){
		this.$el.find('#nm-start-recording').attr('disabled', false);
	},
	disableRecordingButton(){
		this.$el.find('#nm-start-recording').attr('disabled', true);
	},
	postSpeechToText(){
		const speechToText = new SpeechToText({
			audio: this.trimmedAudioFile,
			contentType: this.contentType
		});
		speechToText.save({}, {
			success: (model, response, options) => {
				let updatedTranscript = this.replaceHesitations(model.get('result').transcript);
				let vtt = model.get('result').vtt;
				model.set({
					result: {
						transcript: updatedTranscript,
						vtt: vtt
					}
				});
				this.recentTranscription = model;
				nm.vent.trigger('speechToText:received', model);
			},
			error: (model, response, options) => {
				this.recentTranscription = null;
				nm.vent.trigger('speechToText:error', response);
				//manage ui
				let $startButton = $('#nm-start-recording');
				let $stopButton = $('#nm-stop-recording');
				let $submitButton = $('#nm-submit-recording');
				let $loading = $('#nm-loading-recording');
				$startButton.removeClass('hide-button');
				$stopButton.removeClass('bounceIn').addClass('hide-button');
				$submitButton.addClass('hide-button').removeClass('bounceIn');
				$loading.removeClass('flash infinite').addClass('hide-button');
			}
		});
	},
	replaceHesitations(transcript){
		return transcript.replace(/%HESITATION/g, '...');
	},
	disableRecording(){
		// disable recording, upon enabling continue, for students only
		if(nm.user.get('type') !== 'staff'){
			let $controls = $('.speaking-container').find('button');
			$controls.each(function(){
				$(this).attr('disabled', true);
			});
		}
	},
	receivedTranscription(model){
		let questionType = this.question.elpContent.type;
		switch(questionType){
			case 'pr_conv_turns':{
				this.updateTranscription(model);

				//manage ui
				let $startButton = $('#nm-start-recording');
				let $stopButton = $('#nm-stop-recording');
				let $loading = $('#nm-loading-recording');
				let $submitButton = $('#nm-submit-recording');
				$submitButton.removeClass('hide-button');
				$startButton.removeClass('bounceIn').addClass('hide-button');
				$stopButton.removeClass('bounceIn').addClass('hide-button');
				$loading.removeClass('flash infinite').addClass('hide-button');
				this.$el.find('.cancel-recording').removeClass('hide-cancel-recording');
				break;
			}
			case 'pr_write_speak_t':{
				this.updateTranscription(model);
				//manage ui
				let $startButton = $('#nm-start-recording');
				let $stopButton = $('#nm-stop-recording');
				let $loading = $('#nm-loading-recording');
				let $submitButton = $('#nm-submit-recording');
				$startButton.removeClass('hide-button');
				$stopButton.removeClass('bounceIn').addClass('hide-button');
				$submitButton.removeClass('bounceIn').addClass('hide-button');
				$loading.removeClass('flash infinite').addClass('hide-button');
				break;
			}
			case 'pr_write_speak_w':{
				this.updateTranscription(model, true);
				//manage ui
				let $startButton = $('#nm-start-recording');
				let $stopButton = $('#nm-stop-recording');
				let $loading = $('#nm-loading-recording');
				let $submitButton = $('#nm-submit-recording');
				$startButton.removeClass('hide-button');
				$submitButton.removeClass('bounceIn').addClass('hide-button');
				$stopButton.removeClass('bounceIn').addClass('hide-button');
				$loading.removeClass('flash infinite').addClass('hide-button');
				//we won't need these divs for text highlight rendering
				$('.input-primary-text').remove();
				$('.input-text-track').remove();
				break;
			}
			default:{
				//one of the above conditions should have applied. If you got here, you done messed up a-a-ron.
				break;
			}
		}
	},
	updateTranscription(model, waveform = false){
		if(model){
			//update dedicated audio player for playback / highlighting
			this.updateAudio(model, waveform);
			//update hidden input for transcript submission when question is saved
			let transcription = model.get('result').transcript;
			if(transcription){
				$('.spoken-writing-practice').val(transcription);
				$('.writing-practice-audio').val(this.audioFile);
			}
		}else if(this.renderErrors){
			//if no model, and error rendering is on, must have been an error, prompt user to try again
			$('.input-primary-text').html(this.errorText);
		}
	},
	updateAudio(model, waveform){
		let vttUrl;
		//if waveform, no need for VTT handling, text highlighting
		if(!waveform){
			//given VTT response from server, convert to URL for use in audio track element
			let caption = model.get('result').vtt.split(',').pop();
			caption = window.atob(caption);
			let captionBlob = new Blob([caption]);
			vttUrl = URL.createObjectURL(captionBlob);
		}
		//create a new audio element and proceed to prepare it
		let $audioDiv = $('#nm-conv-input-audio');
		//remove any previous audio element
		$audioDiv.find('audio').remove();
		$audioDiv.html(Hbs.templates.convInputAudioView({
			audio: this.audioFile,
			vttUrl: vttUrl
		}));
		this.player = $audioDiv.find('audio');

		//set listener for audio ended
		this.player.on('ended', function(){
			nm.vent.trigger('input-text-track:reset');
			this.$el.find('.play-transcription').removeClass('transcription-playing').attr('disabled', false);
		}.bind(this));
		//set listener for track loading, if not "waveform mode"
		if(!waveform){
			this.player.find('track').last().on('load', () => {
				this.prepareTextTracks();
			});
			//load the track
			this.player.find('track').last().load();
		}else{
			//set up wavesurfer for waveform visual
			this.waveform = WaveSurfer.create({
				container: '.speaking-output',
				waveColor: '#999',
				progressColor: '#68b8a3',
				responsive: true,
				height: 34,
				barHeight: 4
			});
			//load waveform and mute it (we just want the visuals)
			this.waveform.load(this.audioFile);
			this.waveform.setVolume(0);
			//player's "ended" event listener is setup in prepareTextTracks(), but we need it here when in "waveform" mode
			this.player.on('ended', function(){
				this.playing = false;
				//re-enable speech bubble's play button on audio end
				this.$el.find('.play-transcription').removeClass('speech-playing').attr('disabled', false);
			}.bind(this));
			//arbitrarily set "cuesReady" to true since we're not really using them in "waveform" mode
			this.cuesReady = true;
		}
		//set listener to actually kick off audio playback
		this.player.on('canplaythrough', (event) => {
			this.startSlideMedia();
		});
		//set audio src and load it
		this.player.attr('src', this.audioFile);
		this.player[0].load();
	},
	prepareTextTracks(){
		this.textTrack = this.player.get(0).textTracks[0];
		var cues = [];
		var reference;
		if(this.textTrack.cues){
			for(var j = 0; j < this.textTrack.cues.length; j++){
				reference = this.textTrack.cues[j];
				reference.highlightText = this.highlight(reference.text, this.words);
				reference.hasBreakline = [];
				//check for presence of "<br>" and/or "<br/>"
				var count = (reference.text.match(/(<br>)|(<br\/>)/g) || []).length;
				for(var i = 0; i < count; i++){
					reference.hasBreakline.push('<br>');
				}
				if(reference.hasBreakline.length === 0){
					reference.hasBreakline = false;
				}
				//replace "%HESITATION" on output
				if(reference.text.indexOf('%HESITATION') > -1){
					reference.text = reference.text.replace(/%HESITATION/g, '...');
					reference.highlightText = reference.highlightText.replace(/%HESITATION/g, '...');
				}
				//capitalize first word only
				if(j === 0){
					let highlightText = reference.highlightText.trim();
					let text = reference.text.trim();
					reference.highlightText = highlightText[0].toUpperCase() + highlightText.slice(1);
					reference.text = text[0].toUpperCase() + text.slice(1);
				}
				cues.push(reference);
				reference.onenter = this.enterTextTrack;
			}
			//set subviews for rendering text / highlighting
			this.inputTextViews = {
				inputPrimaryTextView: new InputPrimaryTextView({
					collection: new Backbone.Collection(cues)
				}),
				inputTextTrackView: new InputTextTrackView({
					collection: new Backbone.Collection(cues)
				})
			};
			this.assignSubViews({
				'.input-primary-text': this.inputTextViews.inputPrimaryTextView,
				'.input-text-track': this.inputTextViews.inputTextTrackView
			});
			this.updateMathJax();

			this.player.on('ended', function(){
				//clean up last textTrack if exit not fired
				$('[id^=track]').removeClass('active');
				this.playing = false;
				//re-enable speech bubble's play button on audio end
				this.$el.find('.play-transcription').removeClass('speech-playing').attr('disabled', false);
			}.bind(this));

			this.cuesReady = true;
		}
	},
	enterTextTrack(event){
		let current = event.currentTarget.id;
		if(Number(current) >= this.inputTextTrackIndex - 1){
			let timeRemaining = Math.max(0, this.minDuration - (Date.now() - this.previousTimestamp));
			this.previousTimestamp = Date.now() + timeRemaining;
			_.delay(function(){
				$('[id^=track]').removeClass('active');
				$('#track-' + current).addClass('active');
			}.bind(this), timeRemaining);
		}
	},
	startSlideMedia(){
		console.log('input audio start'); // eslint-disable-line no-console
		// only when prepareTextTracks has completed
		if(!this.cuesReady){
			console.log('input audio cues not ready'); // eslint-disable-line no-console
			return;
		}
		//should only be called once
		if(this.playing){
			console.log('input audio already playing'); // eslint-disable-line no-console
			return;
		}
		this.playing = true;
		this.$el.find('.play-transcription').removeClass('hide-play-transcription').addClass('transcription-playing').attr('disabled', true);
		if(this.updateTime){
			console.log('input audio update time'); // eslint-disable-line no-console
			this.updateTime = false;
			this.player[0].currentTime = this.time;
		}
		// for browsers that treat play as a Promise
		let asyncPlay = this.player[0].play();
		if(asyncPlay instanceof Promise){
			asyncPlay
				.then(() => {
					console.log('input audio started playing'); // eslint-disable-line no-console
				})
				.catch(error => {
					console.log('input audio play'); // eslint-disable-line no-console
					console.log(error); // eslint-disable-line no-console
				});
		}
		if(this.waveform){
			this.waveform.playPause(); //play if paused, pause if playing
		}
	},
	resetInputTextTrackIndex(){
		this.inputTextTrackIndex = 1;
	},
	playTranscription(event){
		event.preventDefault();
		let $playButton = $(event.currentTarget);
		if(!$playButton.hasClass('transcription-playing')){
			$playButton.addClass('transcription-playing').attr('disabled', true);
			this.startSlideMedia();
		}
	},
	speechToTextError(response){
		if(response.status === 400 && this.renderErrors){
			$('.input-primary-text').html(this.errorText);
		}
		//send exception event to google analytics
		// ga('send', 'exception', {
		// 	'exDescription': 'Audio transcription error: ' + response.status,
		// 	'exFatal': false
		// });
	},
	setContentType(){
		// iterate possible audio types and confirm/set if supported
		// NOTE: The last matching type is what will be set as the content type,
		// so we deliberately place OGG last, ensuring Firefox will use OGG
		let types = [
			'audio/mp3',
			'audio/mpeg',
			'audio/mp4',
			'audio/webm',
			'audio/webm;codecs=vorbis',
			'audio/webm;codecs=opus',
			'audio/ogg'
		];
		for(var i in types){
			let isSupported = MediaRecorder.isTypeSupported(types[i]);
			//log to show what audio types are supported
			console.log(`${types[i]} support:`, isSupported); // eslint-disable-line no-console
			if(isSupported){
				this.contentType = types[i];
				console.log('Set conten type to:', types[i]); // eslint-disable-line no-console
			}
		}
		//also log if MediaRecorder even supported
		if(MediaRecorder.notSupported){
			console.log('MediaRecorder is not supported'); // eslint-disable-line no-console
		}else{
			console.log('MediaRecorder is supported'); // eslint-disable-line no-console
		}
	}
});
