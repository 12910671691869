/**
 * ConvStemView
 * Renders conversation "stem" prompts
 */
import $ from 'jquery';
import _ from 'underscore';
import AppBaseView from '../base/AppBaseView';
import nm from '../../nm';
import convStemView from '../../templates/questions/enhancedElp/convStemView.handlebars';

export default AppBaseView.extend({
	events: {},
	className: 'conv-stem-holder',
	textTrack: null,
	currentStem: null,
	initialize(options){
		_.bindAll(this, 'errorHandler');
		this.question = options.question;
		this.stemRendering = options.stemRendering;
		this.listenTo(nm.vent, 'conversation:updateStem', this.prepareStem);
		this.listenTo(nm.vent, 'audio:ended', this.render);
	},
	render(){
		//only render stem if determined that elp level allows for it
		if(this.stemRendering){
			this.$el.html(convStemView({
				stem: this.currentStem
			}));
			_.delay(() => this.stemFadeIn(), 1200); //reveal stem after a beat, as a prompt
		}
		nm.vent.trigger('view:render', {playerIsActive: true});
		return this;
	},
	stemFadeIn(){
		$('.conv-stem-holder > div').fadeIn(400);
	},
	prepareStem(args = null){
		//manage ui
		$('.conv-stem-holder > div').hide();
		//assume no stem
		this.currentStem = '';
		if(!args.isUser){
			//if current conv line index exists and is a number
			if(args.currentConvLineIndex != null && args.currentConvLineIndex >= 0){
				//if there is actually a stem for the current line, update it
				if(this.question.elpContent.content[args.currentConvLineIndex]){
					this.currentStem = this.question.elpContent.content[args.currentConvLineIndex].stem;
				}
			}
		}
	}
});
