var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "		<button id=\"nm-start-recording\" class=\"light-blue-button icon-only start-recording animated\" disabled>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "		<button id=\"nm-start-recording\" class=\"light-blue-button icon-only start-recording animated\">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"nm-speaking-component\" class=\"speaking-component\">\n	<div class=\"speaking-container\">\n		<div class=\"speaking-output\">\n			<div class=\"input-primary-text\">\n			</div>\n			<div class=\"input-text-track\">\n			</div>\n		</div>\n		<button id=\"nm-play-transcription\" class=\"play-transcription hide-play-transcription light-blue-button icon-only\" disabled>\n			<i class=\"material-icons\">play_arrow</i>\n			<i class=\"material-icons\">hourglass_empty</i>\n		</button>\n		<button id=\"nm-cancel-recording\" class=\"cancel-recording hide-cancel-recording red-button icon-only\">\n			<i class=\"material-icons\">clear</i>\n		</button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":18,"column":2},"end":{"line":22,"column":9}}})) != null ? stack1 : "")
    + "			<i class=\"material-icons\">mic</i>\n		</button>\n		<button id=\"nm-stop-recording\" class=\"alt-red-button icon-only stop-recording animated hide-button\">\n			<i class=\"material-icons\">stop</i>\n		</button>\n		<button id=\"nm-loading-recording\" class=\"button icon-only loading-recording animated hide-button\" disabled>\n			<i class=\"material-icons\">hourglass_empty</i>\n		</button>\n		<button id=\"nm-submit-recording\" class=\"green-button icon-only submit-recording animated hide-button\">\n			<i class=\"material-icons\">cloud_upload</i>\n		</button>\n		<div id=\"nm-listening-indicator\" class=\"listening-indicator\">\n			<div>\n				<div></div>\n			</div>\n			<div>\n				<div></div>\n			</div>\n			<div>\n				<div></div>\n			</div>\n		</div>\n	</div>\n	<div id=\"nm-conv-input-audio\">\n	</div>\n</div>\n";
},"useData":true});