var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"multiple",{"name":"compare","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":5},"end":{"line":10,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"fill_in",{"name":"compare","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":5},"end":{"line":13,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"true_false",{"name":"compare","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":5},"end":{"line":16,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"open_ended",{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":5},"end":{"line":19,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../../questions/questionImage.handlebars"),depth0,{"name":"questionImage","data":data,"indent":"\t\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("../../questions/multipleChoiceView.handlebars"),depth0,{"name":"multipleChoiceView","data":data,"indent":"\t\t\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("../../questions/fillInView.handlebars"),depth0,{"name":"fillInView","data":data,"indent":"\t\t\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("../../questions/trueFalseView.handlebars"),depth0,{"name":"trueFalseView","data":data,"indent":"\t\t\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("../../questions/openEndedView.handlebars"),depth0,{"name":"openEndedView","data":data,"indent":"\t\t\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<p class=\"student-answer\">Your answer: <strong class=\""
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"question") : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":57},"end":{"line":25,"column":132}}})) != null ? stack1 : "")
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"studentResponse") : depth0)) != null ? lookupProperty(stack1,"response") : stack1), depth0))
    + "</strong></p>\n			<div class=\"comfort-slider\">\n				<p>How comfortable are you answering this question?</p>\n				<p>Your answer: <strong>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"studentResponse") : depth0)) != null ? lookupProperty(stack1,"adjective") : stack1), depth0))
    + "</strong></p>\n			</div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../helpers/compare.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"type") : depth0),"multiple",{"name":"compare","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":75},"end":{"line":25,"column":123}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    return "all-caps";
},"14":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<p>How comfortable are you answering this question?</p>\n			<div class=\"comfort-range-container\">\n				<label for=\"comfort-range\" class=\"visuallyhidden\">Comfort Level</label>\n				<input type=\"range\" id=\"comfort-range\" name=\"comfort-level-question-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data,"loc":{"start":{"line":34,"column":72},"end":{"line":34,"column":78}}}) : helper)))
    + "\" min=\"1\" value=\"-1\" max=\"5\" step=\"1\" />\n				<output for=\"comfort-range\" id=\"comfort-range-output\"></output>\n			</div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "				<input type=\"submit\" class=\"scott-green-button submit-take-question-form disabled\" value=\"Submit\" />\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"studentResponse") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":42,"column":4},"end":{"line":46,"column":11}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    return "					<input type=\"submit\" class=\"scott-green-button submit-take-question-form disabled\" value=\"Submit\" />\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "					<input type=\"submit\" class=\"scott-green-button submit-take-question-form\" value=\"Submit\" />\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"template-single-question\">\n	<form class=\"take-question-form\" method=\"POST\">\n		<input type=\"hidden\" id=\"assessment-"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"matchedAssessment") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" name=\"assessmentId\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"matchedAssessment") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"/>\n		<input type=\"hidden\" class=\"assigned-bundle-id\" name=\"assignedBundleId\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"assignedBundleId") || (depth0 != null ? lookupProperty(depth0,"assignedBundleId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias3,{"name":"assignedBundleId","hash":{},"data":data,"loc":{"start":{"line":4,"column":81},"end":{"line":4,"column":101}}}) : helper)))
    + "\"/>\n		<div class=\"questions\">\n			<ol class=\"questions-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"question") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":21,"column":13}}})) != null ? stack1 : "")
    + "			</ol>\n		</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"studentResponse") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":24,"column":2},"end":{"line":37,"column":9}}})) != null ? stack1 : "")
    + "		<div class=\"form-row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"notStudent") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":39,"column":3},"end":{"line":47,"column":10}}})) != null ? stack1 : "")
    + "			<div class=\"correct-response animated\">\n				<i class=\"material-icons\">done</i>\n				<span>That's correct!</span>\n			</div>\n			<div class=\"incorrect-response animated\">\n				<i class=\"material-icons\">error_outline</i>\n				<span>Incorrect</span>\n			</div>\n			<div class=\"error-response animated\">\n				<i class=\"material-icons\">error_outline</i>\n				<span>Oops, we couldn't save your answer! Try again.</span>\n			</div>\n			<div class=\"open-ended-response animated\">\n				<i class=\"material-icons\">thumb_up</i>\n				<span>Your response has been saved.</span>\n			</div>\n		</div>\n	</form>\n</div>\n";
},"usePartial":true,"useData":true});