/**
 * ReviewContentView
 * Assignment Player Review: Single Slide
 */
import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import AppBaseView from '../base/AppBaseView';
import ReviewTextTracksView from './ReviewTextTracksView';
import ReviewPrimaryTextView from './ReviewPrimaryTextView';
import nm from '../../nm';
import reviewContentView from '../../templates/assignmentPlayer/reviewContentView.handlebars';

export default AppBaseView.extend({
	events: {
		'click .review-done': 'showDefinition',
		'click .highlight': 'showDefinition',
		'click .disabled-image-link': 'preventSubmit'
	},
	className: 'review-slide-holder',
	textTrack: null,
	/**
	 * Different browser implementations trigger VTTCue 'onexit' and 'onenter' events unpredictably when the cue
	 * is less than 200ms and/or when cues overlap. The following properties enforce a minimum duration
	 * for word highlighting
	 */
	minDuration: 150, //ms
	previousTimestamp: 0,
	initialize: function(options){
		_.bindAll(this, 'enterTextTrack', 'resized');
		this.words = options.words;
		this.review = options.review;
		this.vocab = options.vocab;
		this.enableContinue = false;
		this.currentTextTrack = 0;
		this.textTrackIndex = 1;
		//this resize listener is removed by the 'close' function in AppBaseView explicitly
		//on resize, fire 'resized' function at most once every 0.3 seconds
		let that = this;
		$(window).on('resize', _.throttle(function(){
			that.resized();
		}, 300));
		this.captionLinks = '';
		this.listenTo(nm.vent, 'review:replay', this.loadMedia);
		this.listenTo(nm.vent, 'review:play', this.playPause);
		this.listenTo(nm.vent, 'review:pause', this.audioPause);
		this.listenTo(nm.vent, 'review:slower', this.slowDown);
		this.listenTo(nm.vent, 'review:faster', this.speedUp);
		this.listenTo(nm.vent, 'review-text-track:reset', this.resetTextTrackIndex);
	},
	//temp check to adjust "overflowing" state of slide upon resize
	//TODO: Re-evaluate slide styles and get a "natural" CSS solution in place
	resized(){
		this.checkTextOverflow();
	},
	render: function(){
		this.preparePlayer();
		this.$el.html(reviewContentView({
			review: this.review,
			vocab: this.vocab,
			captionLinks: this.captionLinks
		}));
		// setup image "fadein" on load
		var that = this;
		this.$el.find('.img-column').find('img').hide();
		this.$el.find('.img-column').find('img').on('load', function(){
			that.fadeInImg($(this));
		});
		//run media after render is complete
		_.defer((function(){
			this.runMedia();
			//upon render, check if we should immediately enable continue button, and do or do not. There is no try.
			if(this.enableContinue){
				this.allowContinue();
			}
			//if exited is set to true, a student is viewing, delay enable continue button
			if(nm.user.get('exited')){
				this.allowContinue();
			}
		}).bind(this));
		this.updateMathJax();
		return this;
	},
	preparePlayer: function(){
		if(this.review.audio){
			// Establish audio URLs
			var audioNormal = this.review.audio;
			var audioFast = audioNormal.replace('.mp3', '_fast.mp3');
			var audioSlow = audioNormal.replace('.mp3', '_slow.mp3');
			this.audioLinks = {
				slow: audioSlow,
				normal: audioNormal,
				fast: audioFast
			};
		}
		if(this.review.captions){
			// Establish caption URLs
			var captionNormal = this.review.captions;
			//only do the following for IE8 and above, because they're dumb and lame and also bad
			// if(document.documentMode || /Edge/.test(navigator.userAgent)){
			// 	let originalUrl = 's3.amazonaws.com/positivelearning-assets';
			// 	let ieUrl = `${location.hostname}/ie_assets`;
			// 	captionNormal = captionNormal.replace(originalUrl, ieUrl);
			// }
			var captionFast = captionNormal.replace('.vtt', '_fast.vtt');
			var captionSlow = captionNormal.replace('.vtt', '_slow.vtt');
			this.captionLinks = {
				slow: captionSlow,
				normal: captionNormal,
				fast: captionFast
			};
		}
	},
	loadMedia: function(){
		if(this.review.audio){
			var player = $('.review-audio');
			player[0].pause();
			player[0].currentTime = 0;
			player[0].oncanplaythrough = player[0].play();
			nm.vent.trigger('reviewSpeed:enable');
		}
		// ga('send', {
		// 	hitType: 'event',
		// 	eventCategory: 'Review',
		// 	eventAction: 'Replayed Review Slide',
		// 	eventLabel: 'User ID ' + nm.user.get('id') + ' replayed Review Slide ID: ' + this.review.id
		// });
	},
	runMedia: function(){
		//if no audio, immediately enable continue button
		if(this.review.audio){
			//TODO: if additional assets, like videos, are being loaded, wait for all assets to be ready
			var player = $('.review-audio');
			//set up listener for audio event (must be set directly on DOM element, these events don't bubble up)
			player.on('ended', function(){
				this.allowContinue(true);
				nm.vent.trigger('review-text-track:reset');
				nm.vent.trigger('reviewSpeed:disable');
			}.bind(this));
			//don't load and play audio if student has exited ELP
			if(nm.user.get('exited')){
				nm.vent.trigger('review-controls:disable');
				this.continueEnabled = true;
			}else{
				player.find('.review-audio-mp3').attr('src', this.review.audio);
				player[0].pause();
				player[0].load();
				player[0].oncanplaythrough = player[0].play();
			}
			this.prepareTextTracks(player);
		}else if(!this.review.questionId){
			this.continueEnabled = true;
		}
		if(nm.user.get('type') === 'staff'){
			this.continueEnabled = true;
		}
	},
	playPause: function(){
		$('.review-slide-holder').find('audio').each(function(){
		//TODO: is each needed, or is it implicitly executed during iteration
		// this.$el.find('audio').each(function(){
			var audio = $(this)[0];
			if(audio.paused){
				audio.play();
			}else{
				audio.pause();
			}
		});
		this.$el.find('video').each(function(){
			var video = $(this)[0];
			if(video.paused){
				video.play();
			}else{
				video.pause();
			}
		});
	},
	// Pause audio when closing the panel
	audioPause: function(){
		$('.review-slide-holder').find('audio').each(function(){
		//TODO: is each needed, or is it implicitly executed during iteration
		// this.$el.find('audio').each(function(){
			var audio = $(this)[0];
			audio.pause();
		});
		this.$el.find('video').each(function(){
			var video = $(this)[0];
			video.pause();
		});
	},
	//takes the active audio jquery element
	prepareTextTracks: function(player){
		if(player.get(0).textTracks.length > 0){
			//have to wait for cues to load from vtt
			$('.review-audio track').on('load', function(){
				this.textTrack = player.get(0).textTracks[this.currentTextTrack];
				var cues = [];
				var reference;
				for(var j = 0; j < this.textTrack.cues.length; j++){
					reference = this.textTrack.cues[j];
					reference.highlightText = this.highlight(reference.text, this.words);

					reference.hasBreakline = [];
					var count = (reference.text.match(/<br>/g) || []).length;
					for(var i = 0; i < count; i++){
						reference.hasBreakline.push('<br>');
					}
					if(reference.hasBreakline.length === 0){
						reference.hasBreakline = false;
					}

					cues.push(reference);
					reference.onenter = this.enterTextTrack;
				}
				this.views = {
					reviewTextTracks: new ReviewTextTracksView({
						collection: new Backbone.Collection(cues)
					}),
					reviewPrimaryText: new ReviewPrimaryTextView({
						collection: new Backbone.Collection(cues)
					})
				};
				this.assignSubViews({
					'#nm-review-text-tracks-holder': this.views.reviewTextTracks,
					'#nm-review-primary-text-holder': this.views.reviewPrimaryText
				});
				this.updateMathJax();
				player.on('ended', function(){
					//clean up last textTrack if exit not fired
					$('[id^=review-track]').removeClass('active');
				}.bind(this));

				this.updateMathJax();
				$('.text-column-placeholder').hide();
				$('.review-text-column').fadeIn(1000);
				this.checkTextOverflow();
			}.bind(this));
		}
	},
	slowDown: function(source, newSpeed){
		nm.vent.trigger('reviewSpeed:enable');
		// Locate player on slide
		var player = $('.review-audio')[0];
		var src = $('.review-audio-mp3');
		// Transform current playhead by our magical number
		var time = player.currentTime * 1.25;
		var audioUrl = '';
		// Figure out the source to know which audio file to use
		if(source === 'normal'){
			audioUrl = this.audioLinks.slow;
			// Enable new text track, disable old text track
			this.currentTextTrack = 1;
			player.textTracks[0].mode = 'disabled';
			player.textTracks[this.currentTextTrack].mode = 'showing';
			$('.review-audio-captions-slow').load();
		}else if(source === 'fast'){
			audioUrl = this.audioLinks.normal;
			// Enable new text track, disable old text track
			this.currentTextTrack = 0;
			player.textTracks[2].mode = 'disabled';
			player.textTracks[this.currentTextTrack].mode = 'showing';
			$('.review-audio-captions-normal').load();
		}
		// Update player
		src.attr('src', audioUrl);
		player.load();
		// player.oncanplaythrough did not work properly across Chrome, Firefox and Safari. onloadeddata did, therefore, it is implemented in speed controls.
		player.onloadeddata = function(){
			nm.vent.trigger('reviewSpeed:enable');
			player.currentTime = time;
			player.play();
		};
		// ga('send', {
		// 	hitType: 'event',
		// 	eventCategory: 'Review',
		// 	eventAction: 'Speed Decreased',
		// 	eventLabel: 'User ID ' + nm.user.get('id') + ' decreased speed to: ' + newSpeed + ', on Slide ID: ' + this.review.id
		// });
	},
	speedUp: function(source, newSpeed){
		nm.vent.trigger('reviewSpeed:disable');
		// Locate player on slide
		var player = $('.review-audio')[0];
		var src = $('.review-audio-mp3');
		// Transform current playhead by our magical number
		var time = player.currentTime * 0.8;
		var audioUrl = '';
		var captionUrl = '';
		var url = '';
		// Figure out the source to know which audio file to use
		if(source === 'slow'){
			url = this.audioLinks.normal;
			// Enable new text track, disable old text track
			this.currentTextTrack = 0;
			player.textTracks[1].mode = 'disabled';
			player.textTracks[this.currentTextTrack].mode = 'showing';
			$('.review-audio-captions-normal').load();
		}else if(source === 'normal'){
			url = this.audioLinks.fast;
			// Enable new text track, disable old text track
			this.currentTextTrack = 2;
			player.textTracks[0].mode = 'disabled';
			player.textTracks[this.currentTextTrack].mode = 'showing';
			$('.review-audio-captions-fast').load();
		}
		// Update player
		src.attr('src', url);
		player.load();
		// player.oncanplaythrough did not work properly across Chrome, Firefox and Safari. onloadeddata did, therefore, it is implemented in speed controls.
		player.onloadeddata = function(){
			nm.vent.trigger('reviewSpeed:enable');
			player.currentTime = time;
			player.play();
		};
		// ga('send', {
		// 	hitType: 'event',
		// 	eventCategory: 'Review',
		// 	eventAction: 'Speed Increased',
		// 	eventLabel: 'User ID ' + nm.user.get('id') + ' increased speed to: ' + newSpeed + ', on Slide ID: ' + this.review.id
		// });
	},
	enterTextTrack: function(event){
		var current = event.currentTarget.id;
		if(Number(current) >= this.textTrackIndex - 1){
			var timeRemaining = Math.max(0, this.minDuration - (Date.now() - this.previousTimestamp));
			this.previousTimestamp = Date.now() + timeRemaining;
			_.delay(function(){
				$('[id^=review-track]').removeClass('active');
				$('#review-track-' + current).addClass('active');
				// If we change speed, current track might be one less than current index
				if(Number(current) === this.textTrackIndex){
					this.textTrackIndex += 1;
				}
			}.bind(this), timeRemaining);
		}
	},
	showDefinition: function(event){
		event.preventDefault();
		var ele = $(event.currentTarget);
		nm.vent.trigger('review:close');
		// If we clicked on highlight, we want to go to a sub-definition
		if(ele.hasClass('highlight')){
			nm.vent.trigger('words:define', ele.data('id'), true);
			$('.review-play').addClass('audio-stopped').find('i').text('play_arrow');
		}else if(ele.hasClass('review-done')){
			// If we clicked on the done button, we want to go to main definition
			nm.vent.trigger('words:define', ele.data('id'), false);
		}
	},
	allowContinue: function(managePlayPauseButton){
		managePlayPauseButton = managePlayPauseButton || false;
		nm.vent.trigger('review:continueEnabled', managePlayPauseButton);
	},
	resetTextTrackIndex: function(){
		this.textTrackIndex = 1;
	},
	checkTextOverflow: function(){
		//check on typical slide template, desktop
		var textEl = $('.review-text-original');
		var textContainer = textEl.parents('.column');
		if(textEl.height() > textContainer.height()){
			$('.review-template-two-column').addClass('overflowing');
		}else{
			$('.review-template-two-column').removeClass('overflowing');
		}
		//check on typical slide template, mobile
		var slideEl = $('.review-template-two-column');
		var slideContainer = $('.review-slide');
		if(slideEl.height() > slideContainer.height()){
			slideContainer.addClass('overflowing');
		}else{
			slideContainer.removeClass('overflowing');
		}
		//check on question template
		var questionEl = $('.take-question-form');
		var questionContainer = $('.template-single-question');
		if(questionEl.height() > questionContainer.height()){
			questionContainer.addClass('overflowing');
		}else{
			questionContainer.removeClass('overflowing');
		}
	},
	afterRender: function(){
		this.checkTextOverflow();
	}
});
