/**
 * GroupDetailStudentView
 * Student view of a specific Group (Classroom, nested group, etc.)
 */
import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import AppBaseView from './base/AppBaseView';
import AssignedAssignment from '../models/AssignedAssignment';
import nm from '../nm';
import groupDetailStudentView from '../templates/groupDetailStudentView.handlebars';

export default AppBaseView.extend({
	events: {
		'click .kebab-button': 'toggleKebab',
        'click .refresh-button': 'refresh',
        'click .routing-navigate': 'routingNavigate'
	},
	className: 'group-detail-holder',
	pageTitle: '[Name of Group] | Positive Learning',
	fetchedAssignedAssignments: [], //array of each page of fetch items
	pageToFetch: 0, //set starting page of items to fetch
	fetchLimit: 2000, //how many items to get per page when fetching
	initialize(options){
		_.bindAll(this, 'errorHandler', 'setSynced');
		this.assignments = new AssignedAssignment();

		this.model.fetch({
			success: this.setSynced,
			error: this.errorHandler
		});
		this.refresh();
		this.listenTo(this.model, 'sync', this.render);
		this.listenTo(this.assignments, 'sync', this.render);
        this.navigateFn = options.onNavigate;
	},
	render(){
		if(this.model.synced && this.assignments.synced){
			this.$el.html(groupDetailStudentView(this.prepare()));
			this.showHeader(); //TODO: Move to AppView?
			this.setupKebabMenu();
			nm.vent.trigger('view:render');
		}
		return this;
	},
	prepare(){
		let properties = {
			classroom: this.model.get('result'),
			groups: this.prepareGroups(),
			allGroupsHidden: false,
			breadcrumbs: this.prepareBreadcrumbs(),
			current: null,
			postTests: [],
			actionable: false,
			back: this.decodeThenEncode(Backbone.history.fragment)
		}
		//prepare assignments
		let assignments = _.indexBy(this.assignments.get('results'), 'id');
		//there should be one assignedBundle per assignedAssignment for a student
		//sort by latest
		let assignedBundles = _.sortBy(this.assignments.get('assignedBundles'), asb => {
			return asb.id * -1;
		});
		//if there are assignedBundles, the latest is the current
		if(assignedBundles.length){
			if(assignedBundles[0]){
				properties.current = assignments[assignedBundles[0].assignedId];
				//set props based on event statuses
				if(assignedBundles[0].events.indexOf('assignment_complete') > -1){
					properties.current.completed = true
				}
				for(let assignedBundle of assignedBundles){
					//if post test was sent
					if(assignedBundle.timeRemaining && assignedBundle.timeRemaining.expired === false){
						//if the post test hasn't been completed
						if(assignedBundle.events.indexOf('posttest_complete') === -1){
							properties.postTests.push({
								id: assignedBundle.assignedId,
								title: assignments[assignedBundle.assignedId].title,
								timeRemaining: assignedBundle.timeRemaining
							});
						}
					}
				}
			}
		}
		//set boolean if all subgroups are hidden
		if(_.filter(properties.groups, g => g.hidden).length === properties.groups.length){
			properties.allGroupsHidden = true;
		}
		//final boolean, if there is a current OR posttests, set actionable to true
		//will display empty message otherwise
		if(properties.current || properties.postTests.length){
			properties.actionable = true;
		}
		return properties;
	},
	//"paginated fetch" of all assignedAssignments. Recursively calls itself until all items fetched.
	fetchAllAssignedAssignments(){
		let results = [];
		let assignedBundles = [];
		let assessmentResponses = [];
		let usages = [];
		let assessments = [];
		this.assignments.fetch({
			success: (model, response, options) => {
				//if the fetch generated new results...
				if(model.get('results').length === this.fetchLimit){
					//update with latest fetched page/model
					this.fetchedAssignedAssignments.push(model.toJSON());
					//increment options to next page for next call
					this.pageToFetch++;
					//call fetchAll again for next page
					this.fetchAllAssignedAssignments();
				//...or the fetch received less than limit, so we've reached last "page" with results
				}else{
					//update with our last fetched page/model
					this.fetchedAssignedAssignments.push(model.toJSON());
					//manually combine/update all fetched "pages" into one object
					for(let fetchedPage of this.fetchedAssignedAssignments){
						//update arrays
						results.push(fetchedPage.results);
						assignedBundles.push(fetchedPage.assignedBundles);
						assessmentResponses.push(fetchedPage.assessmentResponses);
						usages.push(fetchedPage.usages);
						assessments.push(fetchedPage.assessments);
					}
					//flatten all arrays
					results = _.flatten(results);
					assignedBundles = _.flatten(assignedBundles);
					assessmentResponses = _.flatten(assessmentResponses);
					usages = _.flatten(usages);
					assessments = _.flatten(assessments);
					//set assignedAssignments model with everything we collected / organized
					this.assignments.set({
						results: results,
						assignedBundles: assignedBundles,
						assessmentResponses: assessmentResponses,
						usages: usages,
						assessments: assessments
					});
					//finally set synced flag to true, update model with ALL students
					this.setSynced(this.assignments);
					this.refreshComplete();
				}
			},
			error: (model, response, options) => {
				this.errorHandler(model, response, options);
			},
			data: {
				limit: this.fetchLimit,
				page: this.pageToFetch,
				classroomId: this.model.get('id')
			}
		});
	},
	refresh(event){
		if(event){
			event.preventDefault();
			let $refreshButton = $(event.currentTarget);
			if(!$refreshButton.parents('.refresh').hasClass('refreshing')){
				$refreshButton.parents('.refresh').addClass('refreshing');
			}
		}
		// reset values as necessary to refresh
		this.assignments.synced = false;
		this.pageToFetch = 0;
		this.fetchedAssignedAssignments = [];
		this.fetchAllAssignedAssignments();
	},
	refreshComplete(){
		let $refreshButton = $('button.refresh-button');
		$refreshButton.parents('.refresh').removeClass('refreshing');
	},
	prepareBreadcrumbs(){
		let breadcrumbs = [];
		//prepare breadcrumbs
		if(this.model.get('result').rootId === 0){
			//join the root classroom with groups to make a reference hash
			this.model.get('groups').push(this.model.get('result'));
		}
		let rootReference = _.indexBy(this.model.get('groups'), 'id');
		collectBreadcrumbs(this.model.get('result').id);
		//recursively collect parents of current classroom for breadcrumbs
		function collectBreadcrumbs(groupId){
			if(rootReference[groupId]){
				let g = rootReference[groupId];
				let title = g.title;
				if(g.nickname){
					title = g.nickname;
				}
				breadcrumbs.push({
					id: groupId,
					title: title
				});
				if(g.parentId){
					collectBreadcrumbs(g.parentId);
				}
			}
		}
		return breadcrumbs.reverse();
	},
	prepareGroups(){
		let groups = [];
		//prepare groups
		let reference = _.indexBy(this.model.get('groups'), 'id');
		let studentId = nm.user.get('id')
		//iterate through groupIds and find in reference set, if current student is in that group
		for(let groupId of this.model.get('result').groupIds){
			if(reference[groupId] && _.contains(reference[groupId].studentIds, studentId)){
				groups.push(reference[groupId]);
			}
		}
		return _.sortBy(groups, 'title');
	}
});
