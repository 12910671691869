var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"intro-card\">\n	<p class=\"all-caps\">Primer</p>\n	<h1>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</h1>\n	<p>\n		<button class=\"scott-green-button nav-next-slide\"><span>Start</span> <i class=\"material-icons\">keyboard_arrow_right</i></button>\n	</p>\n</div>\n";
},"useData":true});