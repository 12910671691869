var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<img src=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"vocab") : depth0)) != null ? lookupProperty(stack1,"asset") : stack1), depth0))
    + "\" />\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./reviewTemplates/reviewQuestionTemplate.handlebars"),depth0,{"name":"reviewQuestionTemplate","data":data,"indent":"\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./reviewTemplates/reviewTwoColumnTemplate.handlebars"),depth0,{"name":"reviewTwoColumnTemplate","data":data,"indent":"\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<track class=\"review-audio-captions-normal\" kind=\"captions\" src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"captionLinks") : depth0)) != null ? lookupProperty(stack1,"normal") : stack1), depth0))
    + "\" srclang=\"en\" label=\"English\" default>\n		<track class=\"review-audio-captions-slow\" kind=\"captions\" src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"captionLinks") : depth0)) != null ? lookupProperty(stack1,"slow") : stack1), depth0))
    + "\" srclang=\"en\" label=\"English\">\n		<track class=\"review-audio-captions-fast\" kind=\"captions\" src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"captionLinks") : depth0)) != null ? lookupProperty(stack1,"fast") : stack1), depth0))
    + "\" srclang=\"en\" label=\"English\">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"review-title\">\n	<div class=\"title-image\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"vocab") : depth0)) != null ? lookupProperty(stack1,"asset") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":5,"column":9}}})) != null ? stack1 : "")
    + "	</div>\n	<div class=\"title-text\">\n		<span>\n			<span class=\"title-subtext\">Review</span>\n			<span class=\"title-word\">"
    + ((stack1 = alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"vocab") : depth0)) != null ? lookupProperty(stack1,"word") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n		</span>\n	</div>\n	<button class=\"scott-button review-done\" data-id=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"vocab") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">Done</button>\n</div>\n\n<div class=\"review-slide\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"review") : depth0)) != null ? lookupProperty(stack1,"questionId") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":17,"column":1},"end":{"line":21,"column":8}}})) != null ? stack1 : "")
    + "</div>\n<audio id=\"review-"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"review") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "-audio\" class=\"review-audio\" preload=\"metadata\" crossorigin=\"anonymous\">\n	<source class=\"review-audio-mp3\" src=\"\" type=\"audio/mp3\"></source>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"review") : depth0)) != null ? lookupProperty(stack1,"captions") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":1},"end":{"line":29,"column":8}}})) != null ? stack1 : "")
    + "	Your browser does not support the audio format.\n</audio>\n";
},"usePartial":true,"useData":true});