var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../helpers/compare.js")).call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"slide") : depths[1])) != null ? lookupProperty(stack1,"questionType") : stack1),"enhanced_elp",{"name":"compare","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":6,"column":13}}})) != null ? stack1 : "")
    + "		<form class=\"take-question-form\" id=\"question-"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" data-question-type="
    + alias3(alias2(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"slide") : depths[1])) != null ? lookupProperty(stack1,"questionType") : stack1), depth0))
    + " method=\"POST\">\n			<input type=\"hidden\" id=\"assessment-"
    + alias3(((helper = (helper = lookupProperty(helpers,"matchedAssessmentId") || (depth0 != null ? lookupProperty(depth0,"matchedAssessmentId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias1,{"name":"matchedAssessmentId","hash":{},"data":data,"loc":{"start":{"line":8,"column":39},"end":{"line":8,"column":62}}}) : helper)))
    + "\" name=\"assessmentId\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"matchedAssessmentId") || (depth0 != null ? lookupProperty(depth0,"matchedAssessmentId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias1,{"name":"matchedAssessmentId","hash":{},"data":data,"loc":{"start":{"line":8,"column":91},"end":{"line":8,"column":114}}}) : helper)))
    + "\"/>\n			<input type=\"hidden\" class=\"assigned-bundle-id\" name=\"assignedBundleId\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"assignedBundleId") || (depth0 != null ? lookupProperty(depth0,"assignedBundleId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias1,{"name":"assignedBundleId","hash":{},"data":data,"loc":{"start":{"line":9,"column":82},"end":{"line":9,"column":102}}}) : helper)))
    + "\"/>\n			<div class=\"questions\">\n				<ol class=\"questions-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"question") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":5},"end":{"line":30,"column":14}}})) != null ? stack1 : "")
    + "				</ol>\n			</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"studentResponse") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.program(35, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":34,"column":3},"end":{"line":72,"column":10}}})) != null ? stack1 : "")
    + "\n			<div class=\"form-row submit-question-row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notStudent") : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0, blockParams, depths),"inverse":container.program(41, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":76,"column":4},"end":{"line":93,"column":11}}})) != null ? stack1 : "")
    + "				<div class=\"correct-response animated\">\n					<i class=\"material-icons\">done</i>\n					<span>That's correct!</span>\n				</div>\n				<div class=\"incorrect-response animated\">\n					<i class=\"material-icons\">error_outline</i>\n					<span>Oops, that's not correct. Keep going!</span>\n				</div>\n				<div class=\"error-response animated\">\n					<i class=\"material-icons\">error_outline</i>\n					<span>Oops, we couldn't save your answer! Try again.</span>\n				</div>\n				<div class=\"open-ended-response animated\">\n					<i class=\"material-icons\">thumb_up</i>\n					<span>Your response has been saved.</span>\n				</div>\n			</div>\n\n		</form>\n	</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<div class=\"template-enhanced-elp-question overflowing template-"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"elpContent") : stack1)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + " "
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"onDisplay") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":98},"end":{"line":3,"column":143}}})) != null ? stack1 : "")
    + "\" id=\"question-container-"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" data-template=\"question\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "question-hide";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<div class=\"template-single-question overflowing "
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"onDisplay") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":50},"end":{"line":5,"column":95}}})) != null ? stack1 : "")
    + "\" id=\"question-container-"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" data-template=\"question\">\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../helpers/compare.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"type") : depth0),"enhanced_elp",{"name":"compare","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.program(10, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":13,"column":6},"end":{"line":28,"column":18}}})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../../questions/questionSlideImage.handlebars"),depth0,{"name":"questionSlideImage","data":data,"indent":"\t\t\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("../../questions/enhancedElp/enhancedElp.handlebars"),depth0,{"name":"enhancedElp","data":data,"indent":"\t\t\t\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"multiple",{"name":"compare","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":7},"end":{"line":18,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"fill_in",{"name":"compare","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":7},"end":{"line":21,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"true_false",{"name":"compare","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":7},"end":{"line":24,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"open_ended",{"name":"compare","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":7},"end":{"line":27,"column":19}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../../questions/multipleChoiceView.handlebars"),depth0,{"name":"multipleChoiceView","hash":{"studentResponse":(depths[1] != null ? lookupProperty(depths[1],"studentResponse") : depths[1])},"data":data,"indent":"\t\t\t\t\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../../questions/fillInView.handlebars"),depth0,{"name":"fillInView","hash":{"studentResponse":(depths[1] != null ? lookupProperty(depths[1],"studentResponse") : depths[1])},"data":data,"indent":"\t\t\t\t\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../../questions/trueFalseView.handlebars"),depth0,{"name":"trueFalseView","hash":{"studentResponse":(depths[1] != null ? lookupProperty(depths[1],"studentResponse") : depths[1])},"data":data,"indent":"\t\t\t\t\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../../questions/openEndedView.handlebars"),depth0,{"name":"openEndedView","hash":{"studentResponse":(depths[1] != null ? lookupProperty(depths[1],"studentResponse") : depths[1])},"data":data,"indent":"\t\t\t\t\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"question") : depth0),{"name":"each","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":4},"end":{"line":48,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAssessment") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":4},"end":{"line":60,"column":11}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../helpers/compare.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"type") : depth0),"enhanced_elp",{"name":"compare","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.program(23, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":37,"column":5},"end":{"line":47,"column":17}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../../questions/enhancedElp/enhancedElpResponse.handlebars"),depth0,{"name":"enhancedElpResponse","hash":{"studentResponse":(depths[1] != null ? lookupProperty(depths[1],"studentResponse") : depths[1])},"data":data,"indent":"\t\t\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depths[1] != null ? lookupProperty(depths[1],"studentResponse") : depths[1])) != null ? lookupProperty(stack1,"response") : stack1),{"name":"if","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.program(27, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":42,"column":6},"end":{"line":46,"column":13}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<p class=\"student-answer\">Your answer: <strong class=\""
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"type") : depth0),"multiple",{"name":"compare","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":61},"end":{"line":43,"column":109}}})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(container.lambda(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"studentResponse") : depths[1])) != null ? lookupProperty(stack1,"response") : stack1), depth0))
    + "</strong></p>\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "all-caps";
},"27":function(container,depth0,helpers,partials,data) {
    return "							<p class=\"student-answer\">Your answer: <em>(Did not respond)</em></p>\n";
},"29":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../helpers/compare.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"slide") : depths[1])) != null ? lookupProperty(stack1,"matchedAssessment") : stack1)) != null ? lookupProperty(stack1,"type") : stack1),"p2p_test",{"name":"compare","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":4},"end":{"line":59,"column":16}}})) != null ? stack1 : "");
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<div class=\"comfort-slider\">\n					<p>How do you feel about your answer?</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"studentResponse") : depth0)) != null ? lookupProperty(stack1,"adjective") : stack1),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(33, data, 0),"data":data,"loc":{"start":{"line":53,"column":5},"end":{"line":57,"column":12}}})) != null ? stack1 : "")
    + "				</div>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<p>Your answer: <strong>"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"studentResponse") : depth0)) != null ? lookupProperty(stack1,"adjective") : stack1), depth0)) != null ? stack1 : "")
    + "</strong></p>\n";
},"33":function(container,depth0,helpers,partials,data) {
    return "						<p>Your answer: <em>(Did not respond)</em></p>\n";
},"35":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"slide") : depths[1])) != null ? lookupProperty(stack1,"matchedAssessment") : stack1)) != null ? lookupProperty(stack1,"type") : stack1),"p2p_test",{"name":"compare","hash":{},"fn":container.program(36, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":4},"end":{"line":70,"column":16}}})) != null ? stack1 : "")
    + "\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<p>How do you feel about your answer?</p>\n				<div class=\"comfort-range-container\">\n					<label for=\"comfort-range\" class=\"visuallyhidden\">Confidence Level</label>\n					<input type=\"range\" id=\"comfort-range\" name=\"comfort-level-question-"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" data-id=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" min=\"1\" value=\"-1\" max=\"5\" step=\"1\" />\n					<output for=\"comfort-range\" id=\"comfort-range-output-"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"></output>\n				</div>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<input type=\"submit\" class=\"scott-green-button submit-take-question-form disabled\" data-id=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" value=\"Submit\" />\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isAssessment") : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":5},"end":{"line":80,"column":12}}})) != null ? stack1 : "");
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<button type=\"button\" class=\"scott-green-button question-skip\" data-id=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">Skip Question</button>\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"studentResponse") : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.program(45, data, 0),"data":data,"loc":{"start":{"line":82,"column":5},"end":{"line":92,"column":12}}})) != null ? stack1 : "");
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<input type=\"submit\" class=\"scott-green-button submit-take-question-form disabled\" data-id=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" value=\"Submit\" />\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isAssessment") : depth0),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":84,"column":6},"end":{"line":86,"column":13}}})) != null ? stack1 : "");
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<button type=\"button\" class=\"scott-green-button question-skip disabled\" data-id=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">Skip Question</button>\n";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<input type=\"submit\" class=\"scott-green-button submit-take-question-form\" data-id=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" value=\"Submit\" />\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isAssessment") : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":89,"column":6},"end":{"line":91,"column":13}}})) != null ? stack1 : "");
},"46":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<button type=\"button\" class=\"scott-green-button question-skip\" data-id=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">Skip Question</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"slide") : depth0)) != null ? lookupProperty(stack1,"questions") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":114,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});