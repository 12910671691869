import React from 'react';
import Container from '@material-ui/core/Container';

const ImageBackground = ({ image, children }) => (
    <Container
        style={{
            backgroundImage: `url(${image})`,
            height: '100vh',
            width: '100vw',
            maxWidth: 'false',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            padding: '0',
        }}>
        {children}
    </Container>
);

export default ImageBackground
