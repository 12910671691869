var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":10,"column":6},"end":{"line":15,"column":13}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<a href=\"#student/group/"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":11,"column":30},"end":{"line":11,"column":36}}}) : helper)))
    + "\" class=\"current-breadcrumb\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":11,"column":65},"end":{"line":11,"column":74}}}) : helper)))
    + "</a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<a href=\"#student/group/"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":13,"column":30},"end":{"line":13,"column":36}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":13,"column":38},"end":{"line":13,"column":47}}}) : helper)))
    + "</a>\n						<span><i class=\"material-icons\">keyboard_arrow_right</i></span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "								<span class=\"primary-text truncate\"><h1>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"classroom") : depth0)) != null ? lookupProperty(stack1,"nickname") : stack1), depth0))
    + "</h1></span>\n								<span class=\"secondary-text truncate\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"classroom") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</span>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "								<span class=\"primary-text truncate\"><h1>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"classroom") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</h1></span>\n";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div class=\"rows-list\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"current") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":6},"end":{"line":81,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"postTests") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":6},"end":{"line":116,"column":13}}})) != null ? stack1 : "")
    + "					</div>\n\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<div class=\"flexed-item-row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"current") : depth0)) != null ? lookupProperty(stack1,"completed") : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":59,"column":7},"end":{"line":63,"column":14}}})) != null ? stack1 : "")
    + "							<div class=\"flexed-row-content\">\n								<div class=\"flexed-row-body\">\n									<span class=\"primary-text truncate\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"current") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"current") : depth0)) != null ? lookupProperty(stack1,"completed") : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":67,"column":9},"end":{"line":71,"column":16}}})) != null ? stack1 : "")
    + "\n								</div>\n								<div class=\"flexed-row-controls\">\n									<span>\n										<a href=\"#assignment-player/"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"current") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "?key=intro&back=("
    + alias3(((helper = (helper = lookupProperty(helpers,"back") || (depth0 != null ? lookupProperty(depth0,"back") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"back","hash":{},"data":data,"loc":{"start":{"line":76,"column":69},"end":{"line":76,"column":77}}}) : helper)))
    + ")\" class=\"routing-navigate green-button\"><span>Open</span> <i class=\"material-icons\">keyboard_arrow_right</i></a>\n									</span>\n								</div>\n							</div>\n						</div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "							<i class=\"material-icons color-green\">check_circle</i>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "							<i class=\"material-icons color-darkblue\">insert_drive_file</i>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "									<span class=\"secondary-text truncate\">Complete</span>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "									<span class=\"secondary-text truncate\">Assigned "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"current") : depth0)) != null ? lookupProperty(stack1,"date") : stack1)) != null ? lookupProperty(stack1,"human") : stack1), depth0))
    + "</span>\n";
},"20":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"postTests") : depth0),{"name":"each","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":6},"end":{"line":115,"column":15}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<div class=\"flexed-item-row\">\n\n\n							<i class=\"material-icons color-plblue\">assignment</i>\n							<div class=\"flexed-row-content\">\n								<div class=\"flexed-row-body\">\n									<span class=\"primary-text truncate\">Post Test - "
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":94,"column":57},"end":{"line":94,"column":66}}}) : helper)))
    + "</span>\n									<span class=\"secondary-text truncate\">\n										<div class=\"row-details\">\n											<span class=\"row-detail-label truncate\">\n												<div>\n													<i class=\"material-icons\">schedule</i>\n													<span>Time Remaining: </span>\n												</div>\n												<span class=\"label-content\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"timeRemaining") : depth0)) != null ? lookupProperty(stack1,"human") : stack1), depth0))
    + "</span>\n											</span>\n										</div>\n									</span>\n								</div>\n								<div class=\"flexed-row-controls\">\n									<span>\n										<a href=\"#post-test/"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":109,"column":30},"end":{"line":109,"column":36}}}) : helper)))
    + "?classroomId="
    + alias4(alias5(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"classroom") : depths[1])) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "&key=post-test\" class=\"light-blue-button\">\n											<i class=\"material-icons\">assignment</i> <span>Take Test</span></a>\n									</span>\n								</div>\n							</div>\n						</div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "\n					<div class=\"rows-list empty-rows-list\">\n						<div class=\"flexed-item-row empty-row\">\n							<p>There are no Primers to take right now.</p>\n						</div>\n					</div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"allGroupsHidden") : depth0),{"name":"unless","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":132,"column":4},"end":{"line":153,"column":15}}})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<h2>Groups</h2>\n				<div class=\"rows-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"groups") : depth0),{"name":"each","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":135,"column":5},"end":{"line":151,"column":14}}})) != null ? stack1 : "")
    + "				</div>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hidden") : depth0),{"name":"unless","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":136,"column":5},"end":{"line":150,"column":16}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div class=\"flexed-item-row\">\n						<i class=\"material-icons color-darkblue\">school</i>\n						<div class=\"flexed-row-content\">\n							<div class=\"flexed-row-body\">\n								<span class=\"primary-text truncate\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":141,"column":44},"end":{"line":141,"column":53}}}) : helper)))
    + "</span>\n							</div>\n							<div class=\"flexed-row-controls\">\n								<span>\n									<a href=\"#student/group/"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":145,"column":33},"end":{"line":145,"column":39}}}) : helper)))
    + "\" class=\"green-button\"><span>View</span> <i class=\"material-icons\">keyboard_arrow_right</i></a>\n								</span>\n							</div>\n						</div>\n					</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"main-page\" class=\"screen-container bg-dark-white\">\n	<div class=\"main-page-container page-container\">\n		<div class=\"pure-g\">\n			<div class=\"pure-u-1\">\n				<div class=\"rows-header\">\n					<div class=\"breadcrumbs\">\n						<a href=\"#learning-center\">Classrooms</a>\n						<span><i class=\"material-icons\">keyboard_arrow_right</i></span>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"breadcrumbs") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":6},"end":{"line":16,"column":15}}})) != null ? stack1 : "")
    + "					</div>\n					<div class=\"header-controls\">\n						<div class=\"refresh\">\n							<button class=\"refresh-button\"><i class=\"material-icons\">refresh</i><i class=\"material-icons animated infinite flash\">hourglass_empty</i></button>\n						</div>\n						<div class=\"kebab-menu\">\n							<button class=\"kebab-button kebab-button-default\"><i class=\"material-icons\">more_vert</i></button>\n							<div class=\"kebab-items\">\n								<ul>\n									<li><a href=\"#recent-assignments/"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"classroom") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" class=\"toggle-hidden-groups\">\n										<i class=\"material-icons\">playlist_add_check</i> <span>Recent Primers</span></a></li>\n								</ul>\n							</div>\n						</div>\n					</div>\n				</div>\n\n				<div class=\"row-container\">\n					<div class=\"flexed-item-row\">\n						<div class=\"flexed-row-content\">\n							<div class=\"flexed-row-body\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"classroom") : depth0)) != null ? lookupProperty(stack1,"nickname") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.program(8, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":38,"column":8},"end":{"line":43,"column":15}}})) != null ? stack1 : "")
    + "							</div>\n						</div>\n					</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"actionable") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.program(23, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":51,"column":5},"end":{"line":126,"column":12}}})) != null ? stack1 : "")
    + "\n				</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"groups") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":131,"column":4},"end":{"line":154,"column":11}}})) != null ? stack1 : "")
    + "\n			</div>\n		</div>\n	</div>\n</div>\n";
},"useData":true,"useDepths":true});