import AppBaseView from '../base/AppBaseView';
import reviewTextTracksView from '../../templates/assignmentPlayer/reviewTextTracksView.handlebars';

export default AppBaseView.extend({
	events: {
	},
	className: 'review-text-track-holder',
	initialize: function(){
	},
	render: function(){
		this.$el.html(reviewTextTracksView(this.collection.toJSON()));
		return this;
	}
});
