import $ from 'jquery';
import _ from 'underscore';
import AppBaseView from '../base/AppBaseView';
import nm from '../../nm';
import writingPracticeView from '../../templates/questions/enhancedElp/writingPracticeView.handlebars';

export default AppBaseView.extend({
	events: {
		'click .play-spoken-response': 'playResponse'
	},
	className: 'writing-holder',
	player: null,
	updateTime: false,
	playing: false,
	initialize(options){
		this.question = options.question;
		this.response = options.response;
	},
	render(){
		let responseTranscription;
		let writtenResponse;
		if(this.response){
			//capitalize first letter of spoken response transcription
			responseTranscription = this.response.response.enhanced.spokenWritingPractice;
			responseTranscription = responseTranscription.toUpperCase() + responseTranscription.slice(1);
			writtenResponse = this.response.response.enhanced.writingPractice;
		}
		this.$el.html(writingPracticeView({
			spokenResponse: responseTranscription,
			writtenResponse: writtenResponse
		}));
		//if response is present, define player for this speech bubble and kick off process for audio
		if(this.response){
			_.defer((function(){
				this.player = this.$el.find('audio');
				this.prepareAudio();
			}).bind(this));
		}
		nm.vent.trigger('view:render', {playerIsActive: true});
		return this;
	},
	prepareAudio(){
		let targetAudio = this.response.response.enhanced.writingPracticeAudio;
		//set listener for audio ended
		this.player.on('ended', function(){
			this.playing = false;
			//re-enable speech bubble's play button on audio end
			this.player.parents('.enhanced-write-speak-responses').find('.play-spoken-response').removeClass('speech-playing').attr('disabled', false);
		}.bind(this));
	},
	//plays speech bubble when play button is clicked
	playResponse(event){
		event.preventDefault();
		let $ele = $(event.currentTarget);
		//if src wasn't set yet, set it and load it
		if(!this.player.attr('src')){
			//set audio src and load it
			this.player.attr('src', this.response.response.enhanced.writingPracticeAudio);
			this.player[0].load();
		}
		if(!$ele.hasClass('speech-playing')){
			this.startSlideMedia();
		}
	},
	startSlideMedia(){
		console.log('pr_writing response audio start'); // eslint-disable-line no-console
		//should only be called once
		if(this.playing){
			console.log('pr_writing response audio already playing'); // eslint-disable-line no-console
			return;
		}
		this.playing = true;
		this.player.parents('.enhanced-write-speak-responses').find('.play-spoken-response').addClass('speech-playing').attr('disabled', true);
		// for browsers that treat play as a Promise
		let asyncPlay = this.player[0].play();
		if(asyncPlay instanceof Promise){
			asyncPlay
				.then(() => {
					console.log('pr_writing response audio started playing'); // eslint-disable-line no-console
				})
				.catch(error => {
					console.log('pr_writing response audio play'); // eslint-disable-line no-console
					console.log(error); // eslint-disable-line no-console
				});
		}
	}
});
