import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import CssBaseline from '@material-ui/core/CssBaseline';
import ImageBackground from '../components/backgrounds/ImageBackground';
import Login from '../components/auth/Login';
import ResetPassword from '../components/auth/ResetPassword';
import bg from '../img/bg.png';

const LoginPage = ({
    loginCopy,
    appKey,
    onLogin,
}) => {
    const [state, setState] = useState({
        login: "",
        password: "",
        challengeName: "",
        errorMessage: "",
        user: null,
        newPassword: "",
        confirmPassword: "",
    });

    const setPassword = password => {
        setState({ ...state, password: password });
    }

    const setLogin = login => {
        setState({ ...state, login: login });
    }

    const setNewPassword = password => {
        setState({ ...state, newPassword: password });
    }

    const setConfirmPassword = password => {
        setState({ ...state, confirmPassword: password });
    }

    const handleUpdate = async () => {
        if (state.newPassword !== state.confirmPassword) {
            setState({ ...state, errorMessage: "The passwords don't match" });
            return;
        }

        try {
            await Auth.completeNewPassword(
                state.user,
                state.newPassword,
            );
            const userInfo = await Auth.currentUserInfo();
            onLogin(userInfo);
        } catch (err) {
            switch (err.code) {
                case 'InvalidPasswordException':
                    setState({
                        ...state,
                        login: '',
                        password: '',
                        errorMessage: 'Password does not follow policy.'
                    });
                    break;
                default:
                    setState({
                        ...state,
                        login: '',
                        password: '',
                        errorMessage: 'An error has occurred, please try again'
                    });
            }
        }
    }

    const handleLogin = async () => {
        const userLogin = `${appKey}__${state.login}`;

        try {
            const user = await Auth.signIn(userLogin, state.password);

            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setState({
                    ...state,
                    user: user,
                    challengeName: user.challengeName,
                });
                return;
            }
             
            onLogin(user);
        } catch (err) {
            switch (err.code) {
                case 'NotAuthorizedException':
                case 'UserNotFoundException':
                    setState({
                        ...state,
                        login: '',
                        password: '',
                        errorMessage: 'Incorrect user name or password, try again'
                    });
                    break;
                default:
                    setState({
                        ...state,
                        login: '',
                        password: '',
                        errorMessage: 'An error has occurred, please try again'
                    });
            }

            // TODO unclear how this works?
            // } else if (err.code === 'PasswordResetRequiredException') {
            //     // The error happens when the password is reset in the Cognito console
            //     // In this case you need to call forgotPassword to reset the password
            //     // Please check the Forgot Password part.
        }
    }


    return (
        <>
            <CssBaseline />
            <ImageBackground image={bg}>
                {state.challengeName === '' && <Login
                    loginCopy={loginCopy}
                    setLogin={setLogin}
                    login={state.login}
                    setPassword={setPassword}
                    password={state.password}
                    onLogin={handleLogin}
                    errorMessage={state.errorMessage}
                />}
                {state.challengeName === 'NEW_PASSWORD_REQUIRED' &&
                    <ResetPassword
                        password={state.newPassword}
                        setPassword={setNewPassword}
                        confirmPassword={state.confirmPassword}
                        setConfirmPassword={setConfirmPassword}
                        onUpdate={handleUpdate}
                        errorMessage={state.errorMessage}
                    />
                }
            </ImageBackground>
        </>
    )
}

export default LoginPage;
