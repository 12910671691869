var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<div class=\"enhanced-write-speak-t-column\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"response") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":10,"column":13}}})) != null ? stack1 : "")
    + "		<div id=\"nm-enhanced-write-speak-t-write-view\" class=\"enhanced-write-speak-t-write-view\">\n		</div>\n	</div>\n	<div class=\"enhanced-write-speak-t-column\">\n		<div class=\"enhanced-write-speak-t-img\">\n			<img src=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"image") || (depth0 != null ? lookupProperty(depth0,"image") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"image","hash":{},"data":data,"loc":{"start":{"line":17,"column":13},"end":{"line":17,"column":22}}}) : helper)))
    + "\" title=\""
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"elpContent") : depth0)) != null ? lookupProperty(stack1,"content") : stack1), depth0))
    + "\"/>\n		</div>\n	</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "		<p>Say it first:</p>\n		<div id=\"nm-enhanced-write-speak-t-record-view\" class=\"enhanced-write-speak-t-record-view\">\n		</div>\n		<p>Now write your answer:</p>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<div class=\"enhanced-write-speak-t-column\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"response") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":2},"end":{"line":28,"column":13}}})) != null ? stack1 : "")
    + "		<div id=\"nm-enhanced-write-speak-t-write-view\" class=\"enhanced-write-speak-t-write-view\">\n		</div>\n	</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"enhanced-elp-input enhanced-write-speak-t\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"image") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":33,"column":8}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});