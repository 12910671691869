/**
 * InputPrimaryTextView
 * Renders conversation input's primary text
 */
import _ from 'underscore';
import AppBaseView from '../base/AppBaseView';
import nm from '../../nm';
import inputPrimaryTextView from '../../templates/questions/enhancedElp/inputPrimaryTextView.handlebars';

export default AppBaseView.extend({
	events: {},
	className: 'input-primary-text-holder',
	textTrack: null,
	initialize: function(){
		_.bindAll(this, 'errorHandler');
	},
	render: function(){
		this.$el.html(inputPrimaryTextView(this.collection.toJSON()));
		nm.vent.trigger('inputPrimaryText:rendered');
		nm.vent.trigger('view:render', {playerIsActive: true});
		return this;
	}
});
