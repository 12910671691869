/**
 * AssignmentProgressView
 * Assignment Player progress indicator
 */
import AppBaseView from '../base/AppBaseView';
import nm from '../../nm';
import assignmentProgressView from '../../templates/assignmentPlayer/assignmentProgressView.handlebars';

export default AppBaseView.extend({
	events: {},
	className: 'assignment-progress-holder',
	initialize: function(options){
		this.progress = options.progress;
	},
	render: function(){
		//use setElement to prevent extra un-needed wrapping div
		this.setElement(assignmentProgressView({
			progress: this.progress
		}));
		nm.vent.trigger('view:render', {playerIsActive: true});
		return this;
	}
});
