import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import colorLogo from '../../img/logo_ellevation_square.png';

const useStyles = makeStyles({
    paper: {
        width: '360px',
        backgroundColor: '#ebebeb',
        padding: '32.36px',
        borderRadius: '12px',
    },
    alert: {
        backgroundColor: '#ed304f',
        color: '#ffffff',
        padding: '10px',
        borderRadius: '12px',
    },
});

const Login = ({
    loginCopy,
    login,
    setLogin,
    password,
    setPassword,
    onLogin,
    errorMessage,
}) => {
    const classes = useStyles();

    const handleChangeLogin = event => {
        setLogin(event.target.value);
    }

    const handleChangePassword = event => {
        setPassword(event.target.value);
    }

    const handleClickLogin = event => {
        onLogin();
    }

    return (
        <Paper className={classes.paper}>
            <span style={{ align: 'center' }}>
                <img src={colorLogo} alt='Ellevation' width='80px' />
            </span>
            <Typography align='center'>{loginCopy}</Typography>
            {errorMessage && errorMessage !== '' && <Paper className={classes.alert}>
                {errorMessage}
            </Paper>}
            <TextField
                id="login"
                value={login}
                label="Username or Email"
                onChange={handleChangeLogin}
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
            />

            <TextField
                id="password"
                type="password"
                value={password}
                label="Password"
                onChange={handleChangePassword}
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <Button
                variant="contained"
                onClick={handleClickLogin}
            >Login</Button>
        </Paper>
    )
}

export default Login
