import $ from 'jquery';
import _ from 'underscore';
import ScrollReveal from 'scrollreveal';
import AppBaseView from '../base/AppBaseView';
import nm from '../../nm';
import gridView from '../../templates/assignmentPlayer/gridView.handlebars';
import primaryTextView from '../../templates/assignmentPlayer/primaryTextView.handlebars';

export default AppBaseView.extend({
	events: {
		'click .nm-adaptive-practice': 'practice',
		'click .highlight': 'showDefinition'
	},
	className: 'grid-holder',
	responses: false,
	contentPanels: null, // will hold our prepared content panels for rendering
	loadedAudioTracks: 0, // keep track of how many audio tracks have loaded
	initialize: function(options){
		_.bindAll(this, 'errorHandler');
		this.words = options.words;
		this.listenTo(nm.vent, 'audioTrack:loaded', this.checkAudioTracks);
	},
	render: function(){
		this.hideHeader();
		this.showPlayerLoading();
		this.$el.html(gridView({
			comicPanels: this.prepareComicPanels()
		}));
		//run media after render is complete
		_.defer((function(){
			this.runMedia();
		}).bind(this));
		nm.vent.trigger('view:render', {playerIsActive: true});
		return this;
	},
	showPlayerLoading(){
		$('.player-loading').removeClass('utility-hide');
	},
	hidePlayerLoading(){
		$('.player-loading').addClass('utility-hide');
	},
	practice(event){
		event.preventDefault();
		nm.vent.trigger('assignment_player:load', 'practice');
	},
	prepareComicPanels(){
		// assume use of default comic layout
		let useDefaultLayout = true;
		// only use "content slides", that is, slides that are not for displaying question(s)
		this.contentPanels = _.filter(this.model.get('slides'), function(slide){
			if(!slide.questionId && !slide.questions){
				return slide;
			}
		});
		// determine if this primer has defined it's own customized comic panel layout
		let definedLayoutPanels = _.filter(this.contentPanels, function(slide){
			if(slide.template || slide.treatment){
				return slide;
			}
		});
		// ALL slides MUST have layout defined, if not, use the default layout
		if(this.contentPanels.length === definedLayoutPanels.length){
			useDefaultLayout = false;
		}
		// when using default layout, we'll cycle through definition and then start over if necessary
		let loopedIndex = 0;
		for(const[index, panel] of this.contentPanels.entries()){ // eslint-disable-line keyword-spacing
			if(useDefaultLayout){
				// reset the index counter at the end of default layout to go back to beginning
				if(loopedIndex === nm.defaultComicLayout.length){
					loopedIndex = 0;
				}
				panel.template = nm.defaultComicLayout[loopedIndex];
				loopedIndex++;
			}
			panel.layoutClasses = nm.comicPanelDefinitions[panel.template].join(' ');
			this.preparePlayer(panel)
		}
		return this.contentPanels;
	},
	runMedia(){
		$('.slide-audio track').on('load', (event) => {
			let $audio = $(event.currentTarget).parent('audio');
			this.loadedAudioTracks++;
			nm.vent.trigger('audioTrack:loaded');
			this.prepareTextTracks($audio);
		});
	},
	checkAudioTracks(){
		// if all audio tracks loaded, that means text is ready, so reveal comic panels and update mathjax
		if(this.contentPanels.length === this.loadedAudioTracks){
			this.hidePlayerLoading();
			this.updateMathJax();
			ScrollReveal().reveal('.comic-panel', {
				container: '#nm-grid-holder', // container that is "doing the scrolling"
				interval: 200, // interval between panel animations
				delay: 750, // delay before animation begins
				distance: '40px' // distance to animate panels upwards when fading in
			});
		}
	},
	prepareTextTracks(audio){
		// only using "normal" caption in comic view for now
		this.textTrack = audio.get(0).textTracks[0];
		var cues = [];
		var reference;

		// lifted and adapted from SlideView.js
		for(var j = 0; j < this.textTrack.cues.length; j++){
			reference = this.textTrack.cues[j];
			reference.highlightText = this.highlight(reference.text, this.words);
			reference.hasBreakline = [];
			//check for presence of "<br>" and/or "<br/>"
			var count = (reference.text.match(/(<br>)|(<br\/>)/g) || []).length;
			for(var i = 0; i < count; i++){
				reference.hasBreakline.push('<br>');
			}
			if(reference.hasBreakline.length === 0){
				reference.hasBreakline = false;
			}
			cues.push(reference);
		}

		// use generated cues to populate primary text template and append to target panel
		let copyOutput = primaryTextView(cues);
		$(`#comic-panel-${audio.data('panel-id')}`).prepend(copyOutput);
		//send encountered words/vocab to event service
		//nm.vent.trigger('vocab:submit');
	},
	preparePlayer(panel){
		// Applied audio is always "normal" speed in comic view for now
		if(panel.audio){
			// Establish audio URLs
			var audioNormal = panel.audio;
			var audioFast = audioNormal.replace('.mp3', '_fast.mp3');
			var audioSlow = audioNormal.replace('.mp3', '_slow.mp3');
			panel.audioLinks = {
				slow: audioSlow,
				normal: audioNormal,
				fast: audioFast
			};
		}
		// Applied caption is always "normal" speed in comic view for now
		if(panel.captions){
			// Establish caption URLs
			var captionNormal = panel.captions;
			// TODO we can remove this if we move away from subdomain urls?
			//only do the following for IE8 and above, because they're dumb and lame and also bad
			// if(document.documentMode || /Edge/.test(navigator.userAgent)){
			// 	let originalUrl = 's3.amazonaws.com/positivelearning-assets';
			// 	let ieUrl = `${location.hostname}/ie_assets`;
			// 	captionNormal = captionNormal.replace(originalUrl, ieUrl);
			// }
			var captionFast = captionNormal.replace('.vtt', '_fast.vtt');
			var captionSlow = captionNormal.replace('.vtt', '_slow.vtt');
			panel.captionLinks = {
				slow: captionSlow,
				normal: captionNormal,
				fast: captionFast
			};
		}
	},
	showDefinition(event){
		event.preventDefault();
		let vocabId = $(event.currentTarget).data('id');
		nm.vent.trigger('words:define', vocabId);
	}
});
