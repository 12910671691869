import React, { useEffect, useState, useCallback } from 'react';
import {
    Switch,
    Route,
    Redirect,
    useRouteMatch,
    useLocation,
    useHistory,
    useParams,
} from "react-router-dom";
import LoginPage from '../pages/LoginPage';
import LearningCenterPage from '../pages/LearningCenterPage';
import nm from '../backbone/nm';
import User from '../backbone/models/User';
import StudentGroupPage from '../pages/StudentGroupPage';
import AssignmentPlayerPage from '../pages/AssignmentPlayerPage';
import { Auth } from 'aws-amplify';

const calcPath = (districtSlug, hash) => {
    return `/${districtSlug}/${hash.replace('#', '').replace(/^\/|\/$/g, '')}`;
}

const userFromAttrs = attrs => {
    return new User({
        admin: false,
        promptNewPassword: 0,
        editor: false,
        id: attrs['custom:positive_learning_id'] ? Number(attrs['custom:positive_learning_id']) : -1,
        type: attrs['custom:user_type'],
        context: attrs['custom:organization'],
        lastName: attrs['family_name'],
        firstName: attrs['name'],
        display: attrs['name'] + ' ' + attrs['family_name'],
    });
}

const DistrictRouter = () => {
    const { path, url } = useRouteMatch();
    const { id } = useParams();
    const location = useLocation();
    const history = useHistory();

    const [state, setState] = useState({
        loggedIn: false,
        loading: true,
        appKey: '',
        loginCopy: '',
        aws: {},
    });

    const onLogin = (user) => {
        nm.user = userFromAttrs(user.attributes);
        setState({
            ...state,
            loggedIn: true,
        })
        history.push(location.state.from);
    }

    const onNavigate = useCallback((hash) => {
        history.push(calcPath(id, hash));
    }, [id, history]);

    const PrivateRoute = ({ children, ...params }) => {
        return (
            <Route
                {...params}
                render={({ location }) =>
                    state.loggedIn ? (children) : (
                        <Redirect to={{
                            pathname: `${url}/login`,
                            state: { from: location }
                        }}
                        />
                    )
                }
            />
        );
    }

    useEffect(() => {
        // retrieve user
        // retrieve organization info

        const populate = async () => {
            const [userInfo, orgResults] = await Promise.all([
                Auth.currentUserInfo(),
                fetch(`${process.env.REACT_APP_API_ENDPOINT}-sp1/organization?subdomain=${process.env.REACT_APP_ENVIRONMENT}.${id}`)
            ]);

            if (userInfo) {
                const { attributes } = userInfo;
                nm.user = userFromAttrs(attributes);
                setState(prev => {
                    return {
                        ...prev,
                        loggedIn: true,
                    }
                })
            }

            const { result } = await orgResults.json();

            setState(prev => {
                return {
                    ...prev,
                    loading: false,
                    appKey: result.key,
                    loginCopy: result.config.loginCopy,
                    aws: result.config.aws,
                }
            });
        }

        populate();
    }, [id]);

    useEffect(() => {
        nm.router = {
            navigate: hash => history.push(calcPath(id, hash))
        };
    }, [id, history])

    if (state.loading) {
        // Should we have a brief loading state?
        return <div />
    }

    return (
        !state.loading && (
            <Switch>
                <Route path={`${path}/login`}>
                    <LoginPage
                        loginCopy={state.loginCopy}
                        appKey={state.appKey}
                        onLogin={onLogin}
                    />
                </Route>
                <PrivateRoute path={`${path}/learning-center`}>
                    <LearningCenterPage onNavigate={onNavigate} />
                </PrivateRoute>
                <PrivateRoute path={`${path}/student/group/:id`}>
                    <StudentGroupPage onNavigate={onNavigate} />
                </PrivateRoute>
                <PrivateRoute path={`${path}/assignment-player/:id`}>
                    <AssignmentPlayerPage />
                </PrivateRoute>
                <Route path={path} >
                    <Redirect to={`${url}/learning-center`} />
                </Route>
            </Switch>
        )
    )
}


export default DistrictRouter;

