import _ from 'underscore';
import AppBaseView from '../base/AppBaseView';
import practiceWritingView from '../../templates/questions/enhancedElp/practiceWritingView.handlebars';

export default AppBaseView.extend({
	events: {
	},
	className: 'practice-writing-view-holder',
	initialize(options){
		_.bindAll(this, 'errorHandler');
		this.question = options.question;
		this.response = options.response;
	},
	render(){
		this.$el.html(practiceWritingView({
			question: this.question,
			response: this.response
		}));
		return this;
	}
});
