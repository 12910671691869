var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<div class=\"form-row submit-question-controls\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"slide") : depth0)) != null ? lookupProperty(stack1,"notStudent") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":40,"column":9}}})) != null ? stack1 : "")
    + "		<div class=\"response-feedback correct-response animated\">\n			<i class=\"material-icons\">done</i>\n			<span>That's correct!</span>\n		</div>\n		<div class=\"response-feedback incorrect-response animated\">\n			<i class=\"material-icons\">error_outline</i>\n			<span>Oops, that's not correct. Keep going!</span>\n		</div>\n		<div class=\"response-feedback error-response animated\">\n			<i class=\"material-icons\">error_outline</i>\n			<span>Oops, we couldn't save your answer! Try again.</span>\n		</div>\n		<div class=\"response-feedback open-ended-response animated\">\n			<i class=\"material-icons\">thumb_up</i>\n			<span>Your response has been saved.</span>\n		</div>\n	</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<button type=\"button\" class=\"scott-green-button submit-take-question-form animated disabled\" disabled data-id=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"slide") : depth0)) != null ? lookupProperty(stack1,"currentQuestionId") : stack1), depth0))
    + "\">\n				<i class=\"material-icons\">hourglass_empty</i>\n				<span>Submit</span>\n			</button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"slide") : depth0)) != null ? lookupProperty(stack1,"assessmentId") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":3},"end":{"line":11,"column":10}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<button type=\"button\" class=\"scott-light-blue-button question-skip\" data-id=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"slide") : depth0)) != null ? lookupProperty(stack1,"currentQuestionId") : stack1), depth0))
    + "\"><span>Skip Question</span></button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"slide") : depth0)) != null ? lookupProperty(stack1,"studentResponse") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":13,"column":3},"end":{"line":39,"column":10}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<button type=\"button\" class=\"scott-green-button submit-take-question-form animated disabled\" data-id=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"slide") : depth0)) != null ? lookupProperty(stack1,"currentQuestionId") : stack1), depth0))
    + "\">\n					<i class=\"material-icons\">hourglass_empty</i>\n					<span>Submit</span>\n				</button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"slide") : depth0)) != null ? lookupProperty(stack1,"assessmentId") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":20,"column":11}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<button type=\"button\" class=\"scott-light-blue-button question-skip disabled\" data-id=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"slide") : depth0)) != null ? lookupProperty(stack1,"currentQuestionId") : stack1), depth0))
    + "\"><span>Skip Question</span></button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"slide") : depth0)) != null ? lookupProperty(stack1,"assignmentCompleted") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":22,"column":4},"end":{"line":38,"column":11}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<button type=\"button\" class=\"scott-green-button submit-take-question-form animated disabled\" data-id=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"slide") : depth0)) != null ? lookupProperty(stack1,"currentQuestionId") : stack1), depth0))
    + "\">\n						<i class=\"material-icons\">hourglass_empty</i>\n						<span>Submit</span>\n					</button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"slide") : depth0)) != null ? lookupProperty(stack1,"assessmentId") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":5},"end":{"line":29,"column":12}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<button type=\"button\" class=\"scott-light-blue-button question-skip disabled\" data-id=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"slide") : depth0)) != null ? lookupProperty(stack1,"currentQuestionId") : stack1), depth0))
    + "\"><span>Skip Question</span></button>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<button type=\"button\" class=\"scott-green-button submit-take-question-form animated\" data-id=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"slide") : depth0)) != null ? lookupProperty(stack1,"currentQuestionId") : stack1), depth0))
    + "\">\n						<i class=\"material-icons\">hourglass_empty</i>\n						<span>Submit</span>\n					</button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"slide") : depth0)) != null ? lookupProperty(stack1,"assessmentId") : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":5},"end":{"line":37,"column":12}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<button type=\"button\" class=\"scott-light-blue-button question-skip\" data-id=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"slide") : depth0)) != null ? lookupProperty(stack1,"currentQuestionId") : stack1), depth0))
    + "\"><span>Skip Question</span></button>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "				<button class=\"speed-grey-button speed-button speed-slower\"><i class=\"material-icons\">remove</i><span>Slower</span></button>\n				<button class=\"scott-circle-button navigate-play active\"><i class=\"material-icons\">pause</i></button>\n				<button class=\"speed-grey-button speed-button speed-faster\"><span>Faster</span><i class=\"material-icons\">add</i></button>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "				<button class=\"speed-grey-button speed-button speed-slower disabled\"><i class=\"material-icons\">remove</i><span>Slower</span></button>\n				<button class=\"scott-circle-button navigate-play disabled\"><i class=\"material-icons\">play_arrow</i></button>\n				<button class=\"speed-grey-button speed-button speed-faster disabled\"><span>Faster</span><i class=\"material-icons\">add</i></button>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"previousClicked") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":80,"column":3},"end":{"line":84,"column":10}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    return "				<button class=\"scott-green-button navigate-slide-back disabled animated\"><i class=\"material-icons\">keyboard_arrow_left</i><span>Back</span> </button>\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "				<button class=\"scott-green-button navigate-slide-back animated\"><i class=\"material-icons\">keyboard_arrow_left</i><span>Back</span> </button>\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "			<button class=\"scott-green-button navigate-slide-back animated\"><i class=\"material-icons\">keyboard_arrow_left</i><span>Back</span> </button>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"previousClicked") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":90,"column":3},"end":{"line":94,"column":10}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    return "				<button class=\"scott-green-button navigate-slide-next animated\"><span>Continue</span> <i class=\"material-icons\">keyboard_arrow_right</i></button>\n";
},"30":function(container,depth0,helpers,partials,data) {
    return "				<button class=\"scott-green-button navigate-slide-next disabled animated\"><span>Continue</span> <i class=\"material-icons\">keyboard_arrow_right</i></button>\n";
},"32":function(container,depth0,helpers,partials,data) {
    return "			<button class=\"scott-green-button navigate-slide-next animated\"><span>Continue</span> <i class=\"material-icons\">keyboard_arrow_right</i></button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"slide") : depth0)) != null ? lookupProperty(stack1,"questions") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":58,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\"controls-container\">\n	<div class=\"left-buttons\">\n		<button class=\"scott-blue-button navigate-replay\"><i class=\"material-icons\">replay</i> <span>Replay</span></button>\n	</div>\n	<div class=\"center-buttons\">\n		<div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasAudio") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":67,"column":3},"end":{"line":75,"column":10}}})) != null ? stack1 : "")
    + "		</div>\n	</div>\n	<div class=\"right-buttons\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isStudent") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":79,"column":2},"end":{"line":87,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isStudent") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(32, data, 0),"data":data,"loc":{"start":{"line":89,"column":2},"end":{"line":97,"column":9}}})) != null ? stack1 : "")
    + "	</div>\n</div>\n";
},"useData":true});