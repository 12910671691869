/**
 * SlideView
 * Assignment Player: Single Slide
 */
import Backbone from 'backbone';
import AppBaseView from '../base/AppBaseView';
import StemPointView from './StemPointView';
import StemDragView from './StemDragView';
import StemDragBankView from './StemDragBankView';
import StemFillView from './StemFillView';
import StemFillBankView from './StemFillBankView';
import PracticeWritingView from './PracticeWritingView';
import PracticeConversationView from './PracticeConversationView';
import ConvInputView from './ConvInputView';
import TextTracksView from './TextTracksView';
import PrimaryTextView from './PrimaryTextView';
import AssessmentResponse from '../../models/AssessmentResponse';
import WritingPracticeView from './WritingPracticeView';
import nm from '../../nm';
import slideView from '../../templates/assignmentPlayer/slideView.handlebars';
import $ from 'jquery';
import _ from 'underscore';

export default AppBaseView.extend({
	events: {
		'click .highlight': 'showDefinition',
		'input #comfort-range': 'updateComfortRange',
		'click .disabled-image-link': 'preventSubmit'
	},
	className: 'slide-holder',
	textTrack: null,
	time: 0, // current time for player,
	updateTime: false,
	player: null,
	playing: false,
	cuesReady: false,
	showHeader: false, //whether to hide nav header
	/**
	 * Different browser implementations trigger VTTCue 'onexit' and 'onenter' events unpredictably when the cue
	 * is less than 200ms and/or when cues overlap. The following properties enforce a minimum duration
	 * for word highlighting
	 */
	minDuration: 150, //ms
	previousTimestamp: 0,
	comfortSliderManipulated: false,

	initialize(options){
		_.bindAll(this, 'submitQuestionSuccess', 'submitQuestionError', 'enterTextTrack', 'resized', 'playPause', 'audioPause', 'speedUp', 'slowDown');
		this.words = options.words;
		this.assessments = options.assessments;
		this.students = options.students;
		this.enableContinue = false;
		this.currentTextTrack = 0;
		this.textTrackIndex = 1;
		if(options.showHeader){
			this.showHeader = options.showHeader;
		}
		//this resize listener is removed by the 'close' function in AppBaseView explicitly
		//on resize, fire 'resized' function at most once every 0.3 seconds
		let that = this;
		// TODO hmmm
		// $(window).on('resize', _.throttle(function(){
		// 	that.resized();
		// }, 300));
		this.listenTo(nm.vent, 'player:replay', this.loadMedia);
		this.listenTo(nm.vent, 'player:play', this.playPause);
		this.listenTo(nm.vent, 'player:pause', this.audioPause);
		this.listenTo(nm.vent, 'view:render', this.initRangeslider);
		this.listenTo(nm.vent, 'slide:request', this.sendSlideObject);
		this.listenTo(nm.vent, 'player:slower', this.slowDown);
		this.listenTo(nm.vent, 'player:faster', this.speedUp);
		this.listenTo(nm.vent, 'text-track:reset', this.resetTextTrackIndex);
		this.listenTo(nm.vent, 'prepare:text-tracks', this.prepareTextTracks);
		this.listenTo(nm.vent, 'slide_question:submit', this.submitQuestion);
		this.listenTo(nm.vent, 'slide_question:skip', this.skipQuestion);
	},
	//temp check to adjust "overflowing" state of slide upon resize
	//TODO: Re-evaluate slide styles and get a "natural" CSS solution in place
	resized(){
		this.checkTextOverflow('res');
	},
	render(){
		if(!this.showHeader){
			this.hideHeader();
		}
		this.checkContinue();
		this.checkHasQuestions();
		this.preparePlayer();
		this.$el.html(slideView({
			slide: this.model.toJSON(),
			captionLinks: this.captionLinks
		}));
		// setup image "fadein" on load
		var that = this;
		this.$el.find('.img-column').find('img').hide();
		this.$el.find('.img-column').find('img').on('load', function(){
			that.fadeInImg($(this));
		});
		//run media after render is complete
		_.defer((function(){
			this.player = this.$el.find('.slide-audio');
			this.runMedia();
			//upon render, check if we should immediately enable continue button, and do or do not. There is no try.
			if(this.enableContinue){
				this.allowContinue();
			}
		}).bind(this));
		this.renderQuestionInterface();
		this.updateMathJax();
		nm.vent.trigger('view:render', {playerIsActive: true});
		return this;
	},
	//render any required subviews for question interface, depending on question type
	renderQuestionInterface(){
		let questionType = this.model.get('questionType');
		let questionContentType = null;

		//if enhanced_elp, we need to figure out what specific content type we're displaying
		if(questionType === 'enhanced_elp'){
			questionContentType = _.first(_.first(this.model.get('questions')).question).elpContent.type;
		}

		//if enhanced_elp and we've got content types, init whatever interface is needed for given question content
		if(questionContentType){
			switch(questionContentType){
				case 'stem_point':{
					this.questionViews = {
						stemPointView: new StemPointView({
							contentOptions: _.first(_.first(this.model.get('questions')).question).elpContent.options,
							question: _.first(_.first(this.model.get('questions')).question)
						})
					};
					this.assignSubViews({
						'#nm-enhanced-stem-point': this.questionViews.stemPointView
					});
					break;
				}
				case 'stem_drag':{
					this.questionViews = {
						stemDragView: new StemDragView({
							contentParts: _.first(_.first(this.model.get('questions')).question).elpContent.parts,
							response: this.model.get('studentResponse')
						}),
						stemDragBankView: new StemDragBankView({
							wordBank: _.first(_.first(this.model.get('questions')).question).elpContent.bank,
							response: this.model.get('studentResponse')
						})
					};
					this.assignSubViews({
						'#nm-enhanced-stem-drag-view': this.questionViews.stemDragView,
						'#nm-enhanced-stem-drag-bank': this.questionViews.stemDragBankView
					});
					break;
				}
				case 'stem_fill':{
					this.questionViews = {
						stemFillView: new StemFillView({
							contentParts: _.first(_.first(this.model.get('questions')).question).elpContent.parts,
							response: this.model.get('studentResponse')
						}),
						stemFillBankView: new StemFillBankView({
							wordBank: _.first(_.first(this.model.get('questions')).question).elpContent.bank
						})
					};
					this.assignSubViews({
						'#nm-enhanced-stem-fill-view': this.questionViews.stemFillView,
						'#nm-enhanced-stem-fill-bank': this.questionViews.stemFillBankView
					});
					break;
				}
				case 'stem':{
					//stem is identical to stem_fill above, but without a "bank"
					this.questionViews = {
						stemFillView: new StemFillView({
							contentParts: _.first(_.first(this.model.get('questions')).question).elpContent.parts,
							response: this.model.get('studentResponse')
						})
					};
					this.assignSubViews({
						'#nm-enhanced-stem-fill-view': this.questionViews.stemFillView
					});
					break;
				}
				case 'pr_write':{
					this.questionViews = {
						practiceWritingView: new PracticeWritingView({
							question: _.first(_.first(this.model.get('questions')).question),
							response: this.model.get('studentResponse')
						})
					};
					this.assignSubViews({
						'#nm-practice-writing-view': this.questionViews.practiceWritingView
					});
					break;
				}
				case 'pr_conv_turns':{
					//will either simply be the logged in student's elp, or for teachers, show the question using lowest student elp
					let lowestElpStudent = _.min(this.students,(student) => {
						return student.elpLevel;
					});
					this.questionViews = {
						practiceConversationView: new PracticeConversationView({
							question: _.first(_.first(this.model.get('questions')).question),
							response: this.model.get('studentResponse'),
							words: this.words,
							elpLevel: lowestElpStudent.elpLevel
						})
					};
					this.assignSubViews({
						'#nm-practice-conversation-view': this.questionViews.practiceConversationView
					});
					break;
				}
				case 'pr_write_speak_t':{
					this.questionViews = {
						convInputView: new ConvInputView({
							question: _.first(_.first(this.model.get('questions')).question),
							renderErrors: true
						}),
						writingView: new WritingPracticeView({
							question: _.first(_.first(this.model.get('questions')).question),
							response: this.model.get('studentResponse')
						})
					};
					this.assignSubViews({
						'#nm-enhanced-write-speak-t-record-view': this.questionViews.convInputView,
						'#nm-enhanced-write-speak-t-write-view': this.questionViews.writingView
					});
					break;
				}
				case 'pr_write_speak_w':{
					this.questionViews = {
						convInputView: new ConvInputView({
							question: _.first(_.first(this.model.get('questions')).question),
							renderErrors: true
						}),
						writingView: new WritingPracticeView({
							question: _.first(_.first(this.model.get('questions')).question),
							response: this.model.get('studentResponse')
						})
					};
					this.assignSubViews({
						'#nm-enhanced-write-speak-w-record-view': this.questionViews.convInputView,
						'#nm-enhanced-write-speak-w-write-view': this.questionViews.writingView
					});
					break;
				}
				default:{
					//for most questions, "custom" interfaces simply aren't necessary
					break;
				}
			}
		}
	},
	showDefinition(event){
		event.preventDefault();
		var ele = this.$el.find(event.currentTarget);
		$(event.currentTarget)
		// this.audioPause();
		nm.vent.trigger('change:audioPause');
		nm.vent.trigger('words:define', ele.data('id'));
	},
	checkHasQuestions(){
		this.$el.find('#nm-assignment-controls').removeClass('question-controls');
		this.$el.find('#nm-assignment-controls').removeClass('hide-question-controls');
		//hide / show question controls if question present
		if(this.model.get('questionId') || this.model.get('assessmentId')){
			let qType = this.model.get('questionType');
			let qContentType = null;
			if(_.first(_.first(this.model.get('questions')).question).elpContent){
				qContentType = _.first(_.first(this.model.get('questions')).question).elpContent.type
			}
			//if conversation question, no need for controls after all
			if(qType === 'enhanced_elp' && qContentType === 'pr_conv_turns'){
				this.$el.find('#nm-assignment-controls').addClass('hide-question-controls');
			}else{
				this.$el.find('#nm-assignment-controls').addClass('question-controls');
			}
		}
	},
	checkContinue(){
		let responses = _.compact(_.pluck(this.model.get('questions'), 'studentResponse'));
		if(responses.length){
			this.enableContinue = true;
		}
	},
	preparePlayer(){
		if(this.model.get('audio')){
			// Establish audio URLs
			var audioNormal = this.model.get('audio');
			var audioFast = audioNormal.replace('.mp3', '_fast.mp3');
			var audioSlow = audioNormal.replace('.mp3', '_slow.mp3');
			this.audioLinks = {
				slow: audioSlow,
				normal: audioNormal,
				fast: audioFast
			};
		}
		if(this.model.get('captions')){
			// Establish caption URLs
			var captionNormal = this.model.get('captions');
			//only do the following for IE8 and above, because they're dumb and lame and also bad
			// if(document.documentMode || /Edge/.test(navigator.userAgent)){
			// 	let originalUrl = 's3.amazonaws.com/positivelearning-assets';
			// 	/* eslint-disable */
			// 	let ieUrl = `${location.hostname}/ie_assets`;
			// 	captionNormal = captionNormal.replace(originalUrl, ieUrl);
			// }
			var captionFast = captionNormal.replace('.vtt', '_fast.vtt');
			var captionSlow = captionNormal.replace('.vtt', '_slow.vtt');
			this.captionLinks = {
				slow: captionSlow,
				normal: captionNormal,
				fast: captionFast
			};
		}
	},
	loadMedia(){
		if(this.model.get('audio')){
			this.player[0].pause();
			this.time = 0;
			this.updateTime = true;
			this.playing = false;
			this.startSlideMedia();
		}
		// ga('send', {
		// 	hitType: 'event',
		// 	eventCategory: 'Assignment',
		// 	eventAction: 'Replayed Slide',
		// 	eventLabel: 'User ID ' + nm.user.get('id') + ' replayed Slide ID: ' + this.model.get('id')
		// });
	},
	startSlideMedia(event = null){
		console.log('start'); // eslint-disable-line no-console
		// only when prepareTextTracks has completed
		if(!this.cuesReady){
			console.log('cues not ready'); // eslint-disable-line no-console
			return;
		}
		//should only be called once
		if(this.playing){
			console.log('already playing'); // eslint-disable-line no-console
			return;
		}
		this.playing = true;
		if(this.updateTime){
			console.log('update time'); // eslint-disable-line no-console
			this.updateTime = false;
			this.player[0].currentTime = this.time;
		}
		nm.vent.trigger('speed:enable');
		// for browsers that treat play as a Promise
		let asyncPlay = this.player[0].play();
		if(asyncPlay instanceof Promise){
			asyncPlay
				.then(() => {
					console.log('started playing'); // eslint-disable-line no-console
				})
				.catch(error => {
					console.log('main play'); // eslint-disable-line no-console
					console.log(error); // eslint-disable-line no-console
					//send exception event to google analytics
					// ga('send', 'exception', {
					// 	'exDescription': 'Slide play error: ' + error,
					// 	'exFatal': false
					// });
				});
		}
	},
	runMedia(){
		// check if this is a question slide
		var isQuestionSlide = false;
		if(this.model.get('questionId') || this.model.get('assessmentId')){
			isQuestionSlide = true;
		}
		//if no audio, immediately enable continue button
		if(this.model.get('audio')){
			//TODO: if additional assets, like videos, are being loaded, wait for all assets to be ready
			//set up listener for audio event (must be set directly on DOM element, these events don't bubble up)
			this.player.on('ended', function(){
				// allow continue when audio finishes, unless it's a question slide
				if(!isQuestionSlide){
					this.allowContinue(true);
				}
				nm.vent.trigger('text-track:reset');
				nm.vent.trigger('speed:disable');
			}.bind(this));
			//set event for track loading
			this.$el.find('.slide-audio track').on('load', () => {
				this.prepareTextTracks();
			});
			//start with default configuration
			var elp = nm.audioSpeeds.defaultElp;
			//student data originated from AssignedAssignment data
			if(nm.user.get('type') === 'student' && this.students.length){
				//if student, student set should only include the current student
				elp = nm.user.get('elpLevel'); // set in render function of parent layout view
				//if exited is set to true, a student is viewing, delay enable continue button
				if(nm.user.get('exited')){
					setTimeout(() => {
						this.allowContinue();
					}, 5000);
					//hide all controls
					nm.vent.trigger('controls:disable');
					//interrupt event handling so player doesn't auto play
					//relies on captions loading while the player never loads the audio
					return;
				}
			}
			this.player.on('canplaythrough', (event) => {
				console.log(event.type); // eslint-disable-line no-console
				this.startSlideMedia(event);
			});
			//get appropriate audio speed from configuration
			if(elp >= nm.audioSpeeds.slow.lowerBound && elp < nm.audioSpeeds.slow.upperBound){
				nm.vent.trigger('speed:slow');
			}else if(elp >= nm.audioSpeeds.fast.lowerBound && elp < nm.audioSpeeds.fast.upperBound){
				nm.vent.trigger('speed:fast');
			}else{ //default range
				this.player.find('.slide-audio-mp3').attr('src', this.audioLinks.normal);
				this.player[0].load();
			}
		}else if(!this.model.get('questionId') && !this.model.get('assessmentId')){
			this.enableContinue = true;
		}
		if(nm.user.get('type') === 'staff'){
			this.enableContinue = true;
		}
	},
	//takes the active audio jquery element
	prepareTextTracks(){
		this.textTrack = this.player.get(0).textTracks[this.currentTextTrack];
		var cues = [];
		var reference;

		//reset vocab encounters in parent view
		nm.vent.trigger('vocab:reset'); //highlight method triggers encounter events
		for(var j = 0; j < this.textTrack.cues.length; j++){
			reference = this.textTrack.cues[j];
			reference.highlightText = this.highlight(reference.text, this.words);

			reference.hasBreakline = [];
			//check for presence of "<br>" and/or "<br/>"
			var count = (reference.text.match(/(<br>)|(<br\/>)/g) || []).length;
			for(var i = 0; i < count; i++){
				reference.hasBreakline.push('<br>');
			}
			if(reference.hasBreakline.length === 0){
				reference.hasBreakline = false;
			}

			cues.push(reference);
			reference.onenter = this.enterTextTrack;
		}
		this.views = {
			textTracks: new TextTracksView({
				collection: new Backbone.Collection(cues)
			}),
			primaryText: new PrimaryTextView({
				collection: new Backbone.Collection(cues)
			})
		};
		this.assignSubViews({
			'#nm-text-tracks-holder': this.views.textTracks,
			'#nm-primary-text-holder': this.views.primaryText
		});
		this.updateMathJax();
		nm.vent.trigger('view:render', {playerIsActive: true});
		this.player.on('ended', function(){
			//clean up last textTrack if exit not fired
			this.$el.find('[id^=track]').removeClass('active');
		}.bind(this));

		this.hideTextPlaceholder();
		this.cuesReady = true;
		//send encountered words/vocab to event service
		nm.vent.trigger('vocab:submit');
	},
	hideTextPlaceholder(){
		//TODO: Intentionally left this instance of "retry until element exists", not interested in breaking slide view today.
		let numberTries = 50;
		let that = this;
		let retryer = setInterval(function(){
			if(that.$el.find('.text-column-placeholder').length && that.$el.find('.text-column').length){
				// Fade in text
				that.$el.find('.text-column-placeholder').hide();
				that.$el.find('.text-column').fadeIn(1000, function(){
					that.checkTextOverflow('hideText');
				});
				clearInterval(retryer);
			}else{
				numberTries = numberTries--;
				if(numberTries <= 0){
					clearInterval(retryer);
				}
			}
		}, 200);
	},
	enterTextTrack(event){
		var current = event.currentTarget.id;
		if(Number(current) >= this.textTrackIndex - 1){
			var timeRemaining = Math.max(0, this.minDuration - (Date.now() - this.previousTimestamp));
			this.previousTimestamp = Date.now() + timeRemaining;
			_.delay(function(){
				this.$el.find('[id^=track]').removeClass('active');
				this.$el.find('#track-' + current).addClass('active');
				// If we change speed, current track might be one less than current index
				if(Number(current) === this.textTrackIndex){
					this.textTrackIndex += 1;
				}
			}.bind(this), timeRemaining);
		}
	},
	playPause(){
		//TODO: is each needed, or is it implicitly executed during iteration
		this.$el.find('audio').each(function(){
			var audio = $(this)[0];
			if(audio.paused){
				let asyncPlay = audio.play();
				if(asyncPlay instanceof Promise){
					asyncPlay.catch(error => {
						console.log(error); // eslint-disable-line no-console
						//send exception event to google analytics
						// ga('send', 'exception', {
						// 	'exDescription': 'Slide play error: ' + error,
						// 	'exFatal': false
						// });
					});
				}
			}else{
				audio.pause();
			}
		});
		this.$el.find('.slide-holder').find('video').each(function(){
			var video = $(this)[0];
			if(video.paused){
				video.play();
			}else{
				video.pause();
			}
		});
	},
	// Pause audio when closing the panel
	audioPause(){
		//TODO: is each needed, or is it implicitly executed during iteration
		this.$el.find('.slide-holder').find('audio').each(function(){
			var audio = $(this)[0];
			audio.pause();
			nm.vent.trigger('player:paused');
		});
	},
	slowDown(source, newSpeed){
		nm.vent.trigger('speed:disable');
		var src = this.$el.find('.slide-audio-mp3');
		// Transform current playhead by our magical number
		this.time = this.player[0].currentTime * 1.25;
		this.updateTime = true;
		this.playing = false;
		this.cuesReady = false;
		// Figure out the source to know which audio file to use
		if(source === 'normal'){
			// Update player
			src.attr('src', this.audioLinks.slow);
			// Enable new text track, disable old text track
			this.currentTextTrack = 1;
			this.player[0].textTracks[0].mode = 'disabled';
			this.player[0].textTracks[this.currentTextTrack].mode = 'showing';
			this.$el.find('.slide-audio-captions-slow').trigger("load");
		}else if(source === 'fast'){
			// Update player
			src.attr('src', this.audioLinks.normal);
			// Enable new text track, disable old text track
			this.currentTextTrack = 0;
			this.player[0].textTracks[2].mode = 'disabled';
			this.player[0].textTracks[this.currentTextTrack].mode = 'showing';
			this.$el.find('.slide-audio-captions-normal').trigger("load");
		}
		this.player[0].load();
		// ga('send', {
		// 	hitType: 'event',
		// 	eventCategory: 'Assignment',
		// 	eventAction: 'Speed Decreased',
		// 	eventLabel: 'User ID ' + nm.user.get('id') + ' decreased speed to: ' + newSpeed + ', on Slide ID: ' + this.model.get('id')
		// });
	},
	speedUp(source, newSpeed){
		nm.vent.trigger('speed:disable');
		var src = this.$el.find('.slide-audio-mp3');
		// Transform current playhead by our magical number
		this.time = this.player[0].currentTime * 0.8;
		this.updateTime = true;
		this.playing = false;
		this.cuesReady = false;
		// Figure out the source to know which audio file to use
		if(source === 'slow'){
			// Update player
			src.attr('src', this.audioLinks.normal);
			// Enable new text track, disable old text track
			this.currentTextTrack = 0;
			this.player[0].textTracks[1].mode = 'disabled';
			this.player[0].textTracks[this.currentTextTrack].mode = 'showing';
			this.$el.find('.slide-audio-captions-normal').trigger("load");
		}else if(source === 'normal'){
			// Update player
			src.attr('src', this.audioLinks.fast);
			// Enable new text track, disable old text track
			this.currentTextTrack = 2;
			this.player[0].textTracks[0].mode = 'disabled';
			this.player[0].textTracks[this.currentTextTrack].mode = 'showing';
			this.$el.find('.slide-audio-captions-fast').trigger("load");
		}
		this.player[0].load();
		// ga('send', {
		// 	hitType: 'event',
		// 	eventCategory: 'Assignment',
		// 	eventAction: 'Speed Increased',
		// 	eventLabel: 'User ID ' + nm.user.get('id') + ' increased speed to: ' + newSpeed + ', on Slide ID: ' + this.model.get('id')
		// });
	},
	allowContinue(managePlayPauseButton){
		managePlayPauseButton = managePlayPauseButton || false;
		nm.vent.trigger('continue:enabled', managePlayPauseButton);
	},
	skipQuestion(options){
		if(!options.ele.hasClass('disabled')){
			// Stop audio from playing
			nm.vent.trigger('textToSpeech:stop', options.currentQuestionId);
			// Grab next question
			var availableQuestions = _.where(this.assessments, {id: this.model.get('assessmentId')})[0].questionIds;
			// don't allow use of question ID if it's a write_about type
			let allowedQuestions = _.filter(this.model.get('questions'), function(q){
				return _.first(q.question).type !== 'write_about'; // WTF
			});
			let allowedQuestionIds = _.pluck(_.map(allowedQuestions, function(q){
				return _.first(q.question);
			}), 'id');
			availableQuestions = _.intersection(availableQuestions, allowedQuestionIds);
			var currentId = options.currentQuestionId;
			var nextId = 0;
			if(availableQuestions.indexOf(currentId) !== availableQuestions.length - 1){
				nextId = availableQuestions[availableQuestions.indexOf(currentId) + 1];
			}else{
				nextId = availableQuestions[0];
			}
			this.$el.find('.template-single-question').addClass('question-hide');
			this.$el.find('#question-container-' + nextId).removeClass('question-hide');
			this.checkTextOverflow('skip');
		}
	},
	submitQuestion(options){
		if(!options.ele.hasClass('disabled')){
			//prevent multiple submissions
			options.ele.addClass('disabled');

			//set the form being submitted and question type
			this.targetForm = this.$el.find(`#question-${options.currentQuestionId}`);
			let questionType = this.targetForm.data('question-type');

			//clear any existing validation messaging
			this.targetForm.find('.error-response').removeClass('animated bounceIn').hide();

			//set serialized question form properties and others
			let question = this.targetForm.serializeJSON();
			let response;
			let comfort;

			//set additional properties as needed, based on question type
			switch(questionType){
				//if enhanced_elp question, proceed with appropriate content type to set response
				case 'enhanced_elp':
					switch(question.contentType){
						case 'stem_point':{
							response = this.setStemPointResponse(question);
							break;
						}
						case 'pr_write':{
							response = {
								response: question.response,
								type: question.contentType
							}
							break;
						}
						case 'pr_conv_turns':{
							response = this.setConversationResponse(question, options.userAudios);
							break;
						}
						case 'pr_write_speak_t':{
							response = {
								type: question.contentType,
								enhanced: {
									spokenWritingPractice: question.spokenWritingPractice,
									writingPractice: question.writingPractice,
									writingPracticeAudio: question.writingPracticeAudio
								}
							}
							break;
						}
						case 'pr_write_speak_w':{
							response = {
								type: question.contentType,
								enhanced: {
									spokenWritingPractice: question.spokenWritingPractice,
									writingPractice: question.writingPractice,
									writingPracticeAudio: question.writingPracticeAudio
								}
							}
							break;
						}
						//all other enhanced elp types (stem_drag, stem_fill, stem) set response like so...
						default:{
							response = this.setStemDragFillResponse(question);
							break;
						}
						break;
					}
					break;
				//default response for "standard" question types (multiple choice, true/false, fill_in, open_ended)
				default:{
					for(let prop in question){
						if(prop.indexOf('response') > -1){
							response = question[prop];
						}
					}
					break;
				}
			}

			//deal with comfort rating if one is present (should only used with P2P assessment questions)
			for(let prop in question){
				if(prop.indexOf('comfort') > -1){
					comfort = question[prop];
				}
			}
			//if student did not make a comfort selection, set as -1
			if(!this.comfortSliderManipulated){
				comfort = -1;
			}

			//create AssessmentResponse model to submit
			let assessmentResponse = new AssessmentResponse({
				questionId: Number(question.questionId),
				assessmentId: Number(question.assessmentId),
				assignedBundleId: Number(question.assignedBundleId),
				response: response,
				comfort: Number(comfort)
			});

			//show form as loading and save response
			this.changeFormState(this.targetForm, 'loading');
			var callbackoptions = {
				success: this.submitQuestionSuccess,
				error: this.submitQuestionError
			};
			assessmentResponse.save({}, callbackoptions);
		}
	},
	submitQuestionSuccess(model, response, options){
		var score = model.get('result').score;
		var graded = model.get('result').graded;
		if(graded){
			if(score){
				this.$el.find('.submit-question-controls').find('.correct-response').show().addClass('bounceIn');
			}else{
				this.$el.find('.submit-question-controls').find('.incorrect-response').show().addClass('bounceIn');
			}
		}else{
			this.$el.find('.submit-question-controls').find('.open-ended-response').show().addClass('bounceIn');
		}
		//this.changeFormState(this.targetForm, 'success');
		this.$el.find('.submit-take-question-form').addClass('disabled').parents('.form-row').removeClass('input-loading input-success');
		this.$el.find('.question-skip').addClass('removed');
		this.$el.find('.submit-take-question-form').removeClass('pulse infinite').find('i').hide();
		this.allowContinue();
		// ga('send', {
		// 	hitType: 'event',
		// 	eventCategory: 'Question',
		// 	eventAction: 'Question Submitted',
		// 	eventLabel: 'User ID ' + nm.user.get('id') + ' submitted Question ID: ' + model.get('questionId')
		// });
		// Manually update the model so that the response can be accessed if "Back" slide nav button is used
		nm.vent.trigger('model:addResponse', response);
	},
	submitQuestionError: function(model, response, options){
		//this.changeFormState(this.targetForm, 'error');
		this.$el.find('.question-skip').addClass('removed');
		this.$el.find('.error-response').show().addClass('bounceIn');
		this.$el.find('.submit-take-question-form').addClass('animated shake').removeClass('infinite').find('i').hide();
		this.allowContinue();
		//send exception event to google analytics
		// ga('send', 'exception', {
		// 	'exDescription': 'Submit Question Error: ' + response.status,
		// 	'exFatal': false
		// });
	},
	//set response to a "stem_point" question
	setStemPointResponse(question){
		return {
			type: question.contentType,
			enhanced: question.enhanced
		};
	},
	//set response to a "stem_drag", "stem_fill", or "stem" question
	setStemDragFillResponse(question){
		//look at question content and get array of all "slot keys"
		let contentParts = _.first(_.first(this.model.get('questions')).question).elpContent.parts;
		let contentPartKeys = _.compact(_.map(contentParts, function(p){
			return p.key;
		}));
		//creates an object made of all available slot keys
		let enhanced = _.invert(Object.assign({}, contentPartKeys));
		//iterate slot keys and populate with user selections
		for(let slot in enhanced){
			enhanced[slot] = question[slot];
		}
		//set the response property we'll be sumbitting
		return {
			type: question.contentType,
			enhanced: enhanced
		};
	},
	setConversationResponse(question, userAudios){
		let enhanced = {
			responseTranscripts: question.spokenResponse,
			responseAudios: userAudios
		};
		//set the response property we'll be sumbitting
		return {
			type: question.contentType,
			enhanced: enhanced
		};
	},
	updateComfortRange(event){
		event.stopPropagation();
		var ele = this.$el.find(event.currentTarget);
		var currentId = ele.data('id');
		var comfortLevel = ele.val();
		var comfortLevelOutput = this.$el.find('#comfort-range-output-' + currentId);
		this.comfortSliderManipulated = true;
		if(comfortLevel){
			var adjective = this.comfortNumberToAdjective(Number(comfortLevel));
			var emojiSpan = '<span class="emoji-span"><img src="/img/confidenceEmoji_' + comfortLevel + '.svg"/></span>';
			comfortLevelOutput.html(emojiSpan + adjective);
		}
	},
	initRangeslider(){
		// TODO is range slider used in student, it isn't happy
		// this.$el.find('input[type="range"]').rangeslider({
		// 	polyfill: false
		// });
	},
	checkTextOverflow(whosCallin = null){
		/**
		 * TODO: Below, "817" is last width before a slide becomes 'stacked',
		 * i.e. text content above image. We should ultimately have a listener
		 * on AppView for window resize events that passes down the 'state
		 * of the window' (width, mobile device or not, etc.) to child views,
		 * so all views across app can respond to resize events as appropriate.
		 * What follows is a lame-ish shim to make the various slides respond to
		 * resize events for now.
		 */

		let template = this.$el.find('.assignment-player-slide').children().first().data('template'); //template being used by SlideView
		let windowWidth = $(window).width(); //viewport width
		let isMobileWidth;
		let $overflowingContainers = this.$el.find('.overflowing'); //any currently overflowing (scrolling) items
		let $content; //set this with element containing slide content
		let $contentContainer; //set this with containing element that should scroll when appropriate

		if(windowWidth < 817){
			isMobileWidth = true;
		}else{
			isMobileWidth = false
		}

		//start with a clean slate
		if($overflowingContainers.length > 0){
			$overflowingContainers.removeClass('overflowing');
		}

		//if regular slide on desktop
		if(template === 'slide' && !isMobileWidth){
			$content = this.$el.find('.text-original');
			$contentContainer = $content.parents('.template-two-column');
		}

		//if regular slide on mobile
		if(template === 'slide' && isMobileWidth){
			$content = this.$el.find('.template-two-column');
			$contentContainer = this.$el.find('.assignment-player-slide');
		}

		//if question slide (content / container don't vary between desktop and mobile for questions)
		if(template === 'question'){
			//may be p2p assessment with multiple questions, some of which are hidden,
			//so explicitly select the visible items
			$content = this.$el.find('.take-question-form').filter(':visible');
			//use correct cotainer depending on question type
			$contentContainer = this.$el.find('.template-single-question').filter(':visible');
			if($contentContainer.length === 0){
				$contentContainer = this.$el.find('.template-enhanced-elp-question');
			}
		}

		//finally, set the thing to scroll if necessary or don't
		if($content.outerHeight() > $contentContainer.outerHeight()){
			$contentContainer.addClass('overflowing');
		}else{
			$contentContainer.removeClass('overflowing');
		}
	},
	afterRender(){
		this.checkTextOverflow('afterRender');
	},
	sendSlideObject(){
		nm.vent.trigger('slide:retrieve', this.model.toJSON());
	},
	resetTextTrackIndex(){
		this.textTrackIndex = 1;
	}
});
