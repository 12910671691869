/**
 * IntroView
 * Assignment Player Intro page
 */
import AppBaseView from '../base/AppBaseView';
import nm from '../../nm';
import introView from '../../templates/assignmentPlayer/introView.handlebars';

export default AppBaseView.extend({
	events: {
		'click .nav-next-slide': 'triggerNextSlide'
	},
	className: 'intro-holder',
	initialize: function(options){
		//if student has achieved mastery on all words related to vocab assessment questions
		this.practiceReady = options.practiceReady;
		this.preTestTaken = options.preTestTaken;
	},
	render: function(){
		this.hideHeader();
		this.$el.html(introView(this.model.toJSON()));
		nm.vent.trigger('view:render');
		return this;
	},
	triggerNextSlide: function(event){
		event.preventDefault();
		//student and exited elp level
		if(nm.user.get('exited')){
			//assume pre-test for exited students
			let paramKey = 'pre-test';
			if(this.preTestTaken){
				paramKey = 'pre-test-results';
			}else if(this.practiceReady){
				//practice ready can change over time, so this must come after results conditional
				paramKey = 'practice';
			}
			nm.vent.trigger('assignment_player:load', paramKey);
		}else{
			nm.vent.trigger('assignment_player:next_slide');
		}
	},
	afterRender: function(){
		var currentTimestamp = this.getTimestampSeconds();
		var currentDate = new Date();
		var readableDate = currentDate.getHours() + ':' + currentDate.getMinutes();
		var primerId = this.model.get('result').assignmentId || this.model.get('result').id;
		var assignmentId = this.model.get('result').id;
		// if assignment ID, this must be a student taking an assignment, else, it's a teacher previewing assignment
		if(this.model.get('result').assignmentId){
			// ga('send', {
			// 	hitType: 'event',
			// 	eventCategory: 'Assignment',
			// 	eventAction: 'Assignment Started Time',
			// 	eventLabel: 'User ID: ' + nm.user.get('id') + ' started Primer ID: ' + primerId + ' / Assignment ID: ' + assignmentId + ' at ' + readableDate + ' (' + currentTimestamp + ')'
			// });
			// ga('send', {
			// 	hitType: 'event',
			// 	eventCategory: 'Assignment',
			// 	eventAction: 'Assignment Started',
			// 	eventLabel: 'User ID: ' + nm.user.get('id') + ' started Primer ID: ' + primerId + ' / Assignment ID: ' + assignmentId
			// });
		}else{
			// ga('send', {
			// 	hitType: 'event',
			// 	eventCategory: 'Primer',
			// 	eventAction: 'Teacher previewing Primer Started Time',
			// 	eventLabel: 'User ID ' + nm.user.get('id') + ' started previewing Primer ID ' + primerId + ' at ' + readableDate + ' (' + currentTimestamp + ')'
			// });
			// ga('send', {
			// 	hitType: 'event',
			// 	eventCategory: 'Primer',
			// 	eventAction: 'Teacher previewing Primer Started',
			// 	eventLabel: 'User ID ' + nm.user.get('id') + ' started previewing Primer ID: ' + primerId
			// });
		}
	}
});
