/**
 * OutroView
 * Assignment Player Outro page
 */
import $ from 'jquery';
import Backbone from 'backbone';
import AppBaseView from '../base/AppBaseView';
import nm from '../../nm';
import outroView from '../../templates/assignmentPlayer/outroView.handlebars';

export default AppBaseView.extend({
	events: {
		'click .exit-assignment-player': 'exitAssignmentPlayer'
	},
	className: 'outro-holder',
	initialize: function(){
		this.isSaved = false;
		this.listenTo(nm.vent, 'status:success', this.setSaved);
		this.listenTo(nm.vent, 'status:auto_save', this.saveAssignmentEventStatus);
	},
	render: function(){
		this.hideHeader();
		this.checkAssignmentStatus();
		this.$el.html(outroView());
		nm.vent.trigger('view:render', {playerIsActive: true});
		nm.vent.trigger('status:auto_save');
		return this;
	},
	saveAssignmentEventStatus: function(){
		if(!this.isSaved){
			var key = 'assignment_complete';
			var bundle = this.getStudentBundle(this.model.toJSON().assignedBundles);
			this.newEventStatus(key, bundle.id);
			$('button.exit-assignment-player').hide();
			$('.saving-assignment-status').show().addClass('pulse');
			var currentTimestamp = this.getTimestampSeconds();
			var currentDate = new Date();
			var readableDate = currentDate.getHours() + ':' + currentDate.getMinutes();
			var primerId = this.model.get('result').assignmentId || this.model.get('result').id;
			var assignmentId = this.model.get('result').id;
			// if assignment ID, this must be a student finishing an assignment, else, it's a teacher previewing assignment
			if(this.model.get('result').assignmentId){
				// ga('send', {
				// 	hitType: 'event',
				// 	eventCategory: 'Assignment',
				// 	eventAction: 'Assignment Completed Time',
				// 	eventLabel: 'User ID: ' + nm.user.get('id') + ' completed Primer ID: ' + primerId + ' / Assignment ID: ' + assignmentId + ' at ' + readableDate + ' (' + currentTimestamp + ')'
				// });
				// ga('send', {
				// 	hitType: 'event',
				// 	eventCategory: 'Assignment',
				// 	eventAction: 'Assignment Completed',
				// 	eventLabel: 'User ID: ' + nm.user.get('id') + ' completed Primer ID: ' + primerId + ' / Assignment ID: ' + assignmentId
				// });
			}else{
				// ga('send', {
				// 	hitType: 'event',
				// 	eventCategory: 'Primer',
				// 	eventAction: 'Teacher preview Primer Completed Time',
				// 	eventLabel: 'User ID ' + nm.user.get('id') + ' completed previewing Primer ID ' + primerId + ' at ' + readableDate + ' (' + currentTimestamp + ')'
				// });
				// ga('send', {
				// 	hitType: 'event',
				// 	eventCategory: 'Primer',
				// 	eventAction: 'Teacher preview Primer Completed',
				// 	eventLabel: 'User ID ' + nm.user.get('id') + ' completed previewing Primer ID: ' + primerId
				// });
			}
		}
	},
	exitAssignmentPlayer: function(event){
		event.preventDefault();
		nm.vent.trigger('player:exit');
	},
	//if there's already an "assignment_complete" status, set a flag so we don't save another
	checkAssignmentStatus: function(){
		if(this.model){
			var assignedBundles = new Backbone.Collection(this.model.get('assignedBundles'));
			var studentId = nm.user.get('id');
			var matchedBundle = assignedBundles.findWhere({studentId: studentId});
			//look specifically for the assignment_complete flag
			if(!matchedBundle || matchedBundle.get('events').indexOf('assignment_complete') > -1){
				this.isSaved = true;
			}
		}
	},
	setSaved: function(){
		this.isSaved = true;
	}
});
