var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<div class=\"column img-column img-column-loading\">\n			<div class=\"img-column-placeholder animated fadeIn\"></div>\n			<img src=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"review") : depth0)) != null ? lookupProperty(stack1,"asset") : stack1), depth0))
    + "\" />\n		</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"review-template-two-column\">\n	<div class=\"column\">\n		<div class=\"column-text-container review-text-column\" style=\"display:none\">\n			<div id=\"nm-review-primary-text-holder\"></div>\n			<div id=\"nm-review-text-tracks-holder\"></div>\n		</div>\n		<div class=\"text-column-placeholder animated fadeIn\">\n            <div class=\"background-masker content-first-end\"></div>\n            <div class=\"background-masker content-second-line\"></div>\n            <div class=\"background-masker content-second-end\"></div>\n            <div class=\"background-masker content-third-line\"></div>\n            <div class=\"background-masker content-third-end\"></div>\n            <div class=\"background-masker content-fourth-line\"></div>\n            <div class=\"background-masker content-fourth-end\"></div>\n            <div class=\"background-masker content-fifth-line\"></div>\n            <div class=\"background-masker content-fifth-end\"></div>\n		</div>\n	</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"review") : depth0)) != null ? lookupProperty(stack1,"asset") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":1},"end":{"line":24,"column":8}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});