var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"populatedQuestions") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":5},"end":{"line":43,"column":14}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":11,"column":5},"end":{"line":15,"column":12}}})) != null ? stack1 : "")
    + "						<span class=\"qi-question-number\">"
    + alias2(__default(require("../../helpers/indexPlusOne.js")).call(alias1,(data && lookupProperty(data,"index")),{"name":"indexPlusOne","hash":{},"data":data,"loc":{"start":{"line":16,"column":39},"end":{"line":16,"column":62}}}))
    + ".</span>\n						<div class=\"question-content\">\n							<span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"instructions") || (depth0 != null ? lookupProperty(depth0,"instructions") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"instructions","hash":{},"data":data,"loc":{"start":{"line":18,"column":13},"end":{"line":18,"column":29}}}) : helper)))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"content") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":19,"column":7},"end":{"line":23,"column":14}}})) != null ? stack1 : "")
    + "						</div>\n						<div class=\"question-condensed\">\n							<h2>Question #"
    + alias2(__default(require("../../helpers/indexPlusOne.js")).call(alias1,(data && lookupProperty(data,"index")),{"name":"indexPlusOne","hash":{},"data":data,"loc":{"start":{"line":26,"column":21},"end":{"line":26,"column":44}}}))
    + "</h2>\n						</div>\n						<span class=\"question-status\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"studentResponse") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":7},"end":{"line":35,"column":14}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":36,"column":7},"end":{"line":40,"column":14}}})) != null ? stack1 : "")
    + "						</span>\n					</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div class=\"question question-visited question-active "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"studentResponse") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":59},"end":{"line":12,"column":107}}})) != null ? stack1 : "")
    + "\" data-question-id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":12,"column":127},"end":{"line":12,"column":133}}}) : helper)))
    + "\">\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "question-submitted";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div class=\"question "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"studentResponse") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":26},"end":{"line":14,"column":74}}})) != null ? stack1 : "")
    + "\" data-question-id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":14,"column":94},"end":{"line":14,"column":100}}}) : helper)))
    + "\">\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<h2>"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"content") : stack1), depth0)) != null ? stack1 : "")
    + "</h2>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<h2>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"content") || (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"content","hash":{},"data":data,"loc":{"start":{"line":22,"column":11},"end":{"line":22,"column":24}}}) : helper))) != null ? stack1 : "")
    + "</h2>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"studentResponse") : depth0)) != null ? lookupProperty(stack1,"score") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":30,"column":7},"end":{"line":34,"column":14}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    return "							<i class=\"material-icons color-green qs-small\">check_circle</i>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "							<i class=\"material-icons color-red qs-small\">error</i>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "							<i class=\"material-icons qs-small\">keyboard_arrow_right</i>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "							<i class=\"material-icons qs-small\">fiber_manual_record</i>\n";
},"21":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<div id=\"qinbox-assessments\" class=\"qinbox-content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"hasResponses") : depths[1]),{"name":"if","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":5},"end":{"line":92,"column":12}}})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../assignments/assessmentView.handlebars"),depth0,{"name":"assessmentView","data":data,"indent":"\t\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"hasResponses") : depths[1]),{"name":"unless","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":95,"column":5},"end":{"line":108,"column":16}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"hasResponses") : depths[1]),{"name":"if","hash":{},"fn":container.program(37, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":110,"column":5},"end":{"line":139,"column":12}}})) != null ? stack1 : "")
    + "				</div>\n";
},"22":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div id=\"qinbox-results\">\n						<div>\n							<span class=\"emoji-span\"><img src=\"/img/confidenceEmoji_"
    + alias4(((helper = (helper = lookupProperty(helpers,"emojiLevel") || (depth0 != null ? lookupProperty(depth0,"emojiLevel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"emojiLevel","hash":{},"data":data,"loc":{"start":{"line":55,"column":63},"end":{"line":55,"column":77}}}) : helper)))
    + ".svg\"/></span>\n							<div class=\"qinbox-results-content\">\n								<p>You got "
    + alias4(((helper = (helper = lookupProperty(helpers,"numberCorrect") || (depth0 != null ? lookupProperty(depth0,"numberCorrect") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"numberCorrect","hash":{},"data":data,"loc":{"start":{"line":57,"column":19},"end":{"line":57,"column":36}}}) : helper)))
    + " / "
    + alias4(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"populatedQuestions") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + " correct.</p>\n							</div>\n						</div>\n					</div>\n"
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"key") : depths[1]),"===","post-test",{"name":"compare","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.program(25, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":61,"column":5},"end":{"line":91,"column":17}}})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    return "";
},"25":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div id=\"qinbox-next-action\">\n"
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"numberCorrect") : depth0),"===",((stack1 = (depth0 != null ? lookupProperty(depth0,"populatedQuestions") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"compare","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":6},"end":{"line":75,"column":18}}})) != null ? stack1 : "")
    + "						<div class=\"qinbox-review\">\n"
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"numberCorrect") : depth0),"===",((stack1 = (depth0 != null ? lookupProperty(depth0,"populatedQuestions") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"compare","hash":{},"fn":container.program(28, data, 0, blockParams, depths),"inverse":container.program(30, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":77,"column":7},"end":{"line":81,"column":19}}})) != null ? stack1 : "")
    + "							<div class=\"grid-row\">\n								<i class=\"material-icons color-dark-blue\">insert_drive_file</i>\n								<span>"
    + container.escapeExpression(container.lambda(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"assignedAssignment") : depths[1])) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</span>\n								<div class=\"grid-row-controls\">\n									<a href=\"#\" class=\"light-blue-button nm-adaptive-review\"><i class=\"material-icons\">visibility</i> <span>View</span></a>\n								</div>\n							</div>\n						</div>\n					</div>\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "						<div class=\"qinbox-go-practice\">\n							<div class=\"grid-row\">\n								<i class=\"material-icons color-green\">thumb_up</i>\n								<span>Nice work! Time to practice...</span>\n								<div class=\"grid-row-controls\">\n									<a href=\"#\" class=\"green-button nm-adaptive-practice\"><span>Practice</span> <i class=\"material-icons\">keyboard_arrow_right</i></a>\n								</div>\n							</div>\n						</div>\n";
},"28":function(container,depth0,helpers,partials,data) {
    return "							<p class=\"h3\">If you'd like to review:</p>\n";
},"30":function(container,depth0,helpers,partials,data) {
    return "							<p class=\"h3\">Here's a quick review to help:</p>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../helpers/compare.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"type") : depth0),"===","p2p_test",{"name":"compare","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":5},"end":{"line":107,"column":17}}})) != null ? stack1 : "");
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"populatedQuestions") : depth0),{"name":"each","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":97,"column":5},"end":{"line":106,"column":14}}})) != null ? stack1 : "");
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div class=\"qinbox-comfort "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(data && lookupProperty(data,"first")),{"name":"unless","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":32},"end":{"line":98,"column":80}}})) != null ? stack1 : "")
    + "\" data-question-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":98,"column":100},"end":{"line":98,"column":106}}}) : helper)))
    + "\">\n						<p>How do you feel about your answer?</p>\n						<div class=\"comfort-range-container\">\n							<label for=\"comfort-range\" class=\"visuallyhidden\">Confidence Level</label>\n							<input type=\"range\" id=\"comfort-range\" name=\"comfort-level-question-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":102,"column":75},"end":{"line":102,"column":81}}}) : helper)))
    + "\" data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":102,"column":92},"end":{"line":102,"column":98}}}) : helper)))
    + "\" min=\"1\" value=\"-1\" max=\"5\" step=\"1\" />\n							<output for=\"comfort-range\" id=\"comfort-range-output-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":103,"column":60},"end":{"line":103,"column":66}}}) : helper)))
    + "\"></output>\n						</div>\n					</div>\n";
},"35":function(container,depth0,helpers,partials,data) {
    return "hide-qinbox-comfort";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div class=\"qinbox-key-vocab\">\n						<div>\n							<p class=\"h2\">Key Vocabulary:</p>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"populatedQuestions") : depth0),{"name":"each","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":114,"column":7},"end":{"line":136,"column":16}}})) != null ? stack1 : "")
    + "						</div>\n					</div>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"keyVocab") : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.program(48, data, 0),"data":data,"loc":{"start":{"line":115,"column":7},"end":{"line":135,"column":14}}})) != null ? stack1 : "");
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.program(42, data, 0),"data":data,"loc":{"start":{"line":116,"column":7},"end":{"line":120,"column":14}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"keyVocab") : depth0),{"name":"each","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":121,"column":8},"end":{"line":125,"column":17}}})) != null ? stack1 : "")
    + "							</span>\n";
},"40":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<span class=\"question-key-vocab\" data-question-vocab-id="
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data,"loc":{"start":{"line":117,"column":63},"end":{"line":117,"column":69}}}) : helper)))
    + ">\n";
},"42":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<span class=\"question-key-vocab hide-key-vocab\" data-question-vocab-id="
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data,"loc":{"start":{"line":119,"column":78},"end":{"line":119,"column":84}}}) : helper)))
    + ">\n";
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"word") : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":122,"column":8},"end":{"line":124,"column":15}}})) != null ? stack1 : "");
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "								<span><span class=\"vocab-word\" data-vocab-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":123,"column":54},"end":{"line":123,"column":60}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"word") || (depth0 != null ? lookupProperty(depth0,"word") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"word","hash":{},"data":data,"loc":{"start":{"line":123,"column":62},"end":{"line":123,"column":70}}}) : helper)))
    + "</span>"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":123,"column":77},"end":{"line":123,"column":107}}})) != null ? stack1 : "")
    + "</span>\n";
},"46":function(container,depth0,helpers,partials,data) {
    return ", ";
},"48":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.program(42, data, 0),"data":data,"loc":{"start":{"line":128,"column":7},"end":{"line":132,"column":14}}})) != null ? stack1 : "")
    + "							<span><span class=\"vocab-word-none\">(None)</span></span>\n							</span>\n";
},"50":function(container,depth0,helpers,partials,data) {
    return "					<button class=\"button qinbox-next qinbox-next-question\" disabled=\"disabled\"><span>Next</span> <i class=\"material-icons\">keyboard_arrow_right</i></button>\n";
},"52":function(container,depth0,helpers,partials,data) {
    return "					<button class=\"button qinbox-next qinbox-next-question\"><span>Next</span> <i class=\"material-icons\">keyboard_arrow_right</i></button>\n";
},"54":function(container,depth0,helpers,partials,data) {
    return "					<button class=\"qinbox-submit show-qinbox-submit green-button\"><span>Done</span> <i class=\"material-icons animated infinite\">send</i></button>\n";
},"56":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<button class=\"qinbox-adaptive-review nm-adaptive-review green-button "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showReviewButton") : depth0),{"name":"if","hash":{},"fn":container.program(57, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":152,"column":75},"end":{"line":152,"column":126}}})) != null ? stack1 : "")
    + "\"><span>Go to Review</span> <i class=\"material-icons animated infinite\">keyboard_arrow_right</i></button>\n";
},"57":function(container,depth0,helpers,partials,data) {
    return "show-adaptive-review";
},"59":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../helpers/compare.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"key") : depth0),"===","practice",{"name":"compare","hash":{},"fn":container.program(60, data, 0),"inverse":container.program(62, data, 0),"data":data,"loc":{"start":{"line":155,"column":5},"end":{"line":161,"column":17}}})) != null ? stack1 : "");
},"60":function(container,depth0,helpers,partials,data) {
    return "					<button class=\"qinbox-continue green-button show-qinbox-continue\"><span>Continue</span> <i class=\"material-icons animated infinite\">keyboard_arrow_right</i></button>\n";
},"62":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../helpers/compare.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"key") : depth0),"===","post-test",{"name":"compare","hash":{},"fn":container.program(63, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":158,"column":5},"end":{"line":160,"column":17}}})) != null ? stack1 : "");
},"63":function(container,depth0,helpers,partials,data) {
    return "					<button class=\"qinbox-exit green-button show-qinbox-exit\"><i class=\"material-icons animated infinite\">arrow_back</i><span>Exit</span></button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"qpage-container\">\n	<div id=\"qpage\">\n		<div id=\"qinbox\">\n			<div class=\"qinbox-list\">\n				<div class=\"qinbox-list-header\">\n					<h1><i class=\"material-icons\">question_answer</i><span>Questions</span></h1>\n				</div>\n				<div class=\"qinbox-questions\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"assessments") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":5},"end":{"line":44,"column":14}}})) != null ? stack1 : "")
    + "				</div>\n				<div class=\"qinbox-list-footer\"></div>\n				<button class=\"toggle-qinbox-list\"><i class=\"material-icons\">keyboard_arrow_right</i></button>\n			</div>\n			<div class=\"qinbox-content-container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"assessments") : depth0),{"name":"each","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":4},"end":{"line":141,"column":13}}})) != null ? stack1 : "")
    + "				<div class=\"qinbox-content-footer\">\n"
    + ((stack1 = __default(require("../../helpers/compare.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"assessments") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"populatedQuestions") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),"===",1,{"name":"compare","hash":{},"fn":container.program(50, data, 0, blockParams, depths),"inverse":container.program(52, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":143,"column":5},"end":{"line":147,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasResponses") : depth0),{"name":"unless","hash":{},"fn":container.program(54, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":5},"end":{"line":150,"column":16}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"assessments") : depth0),{"name":"each","hash":{},"fn":container.program(56, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":151,"column":5},"end":{"line":153,"column":14}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasResponses") : depth0),{"name":"if","hash":{},"fn":container.program(59, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":154,"column":5},"end":{"line":162,"column":12}}})) != null ? stack1 : "")
    + "				</div>\n			</div>\n		</div>\n	</div>\n</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});