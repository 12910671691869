/**
 * AppBaseView
 * Foundational functions and methods for all views
 */
import _ from 'underscore';
import $ from 'jquery';
import Backbone from 'backbone';
import Cookies from 'js-cookie';
import nm from '../../nm';
import ClassroomSequence from '../../models/ClassroomSequence';
import highlight from '../../templates/assignmentPlayer/highlight.handlebars';

export default Backbone.View.extend({
	/**
	 * close
	 * Event and memory cleanup for subviews
	 * @return (void)
	 */
	close: function(){
		//most subviews are within this.views
		if(this.views){
			this.removeUninitializedViews(this.views);
			_.invoke(this.views, 'close');
		}
		//some views have more complex children, stored in the following objects
		if(this.questionViews){
			this.removeUninitializedViews(this.questionViews);
			_.invoke(this.questionViews, 'close');
		}
		if(this.convViews){
			this.removeUninitializedViews(this.convViews);
			_.invoke(this.convViews, 'close');
		}
		//"speech bubble" ConvOutputViews are stored in an array
		if(this.convSpeechBubbles && this.convSpeechBubbles.length > 0){
			_.invoke(this.convSpeechBubbles, 'close');
		}
		if(this.convTextViews){
			this.removeUninitializedViews(this.convTextViews);
			_.invoke(this.convTextViews, 'close');
		}
		if(this.inputTextViews){
			this.removeUninitializedViews(this.inputTextViews);
			_.invoke(this.inputTextViews, 'close');
		}
		$(window).off();
		if(this.player){
			this.player[0].oncanplay = () => {};
		}
		if(this.players){
			for(let $player of this.players){
				$player[0].oncanplay = () => {};
			}
			//actually deletes the player elements from the array (https://stackoverflow.com/questions/1232040/how-do-i-empty-an-array-in-javascript)
			this.players.length = 0;
		}
		clearInterval(nm.interval);
		this.remove();
	},
	/**
	 * closeViewSet
	 * Close a subset of specific views
	 * @param nm.AppBaseView[]
	 * @return (void)
	 */
	closeViewSet: function(views){
		if(views){
			_.invoke(views, 'close');
		}
	},
	/**
	 * removeUninitializedViews
	 * The "views" object used in the close() function above is an object whose properties are each a "subview"
	 * It's possible that a subview is never actually initialized, so, instead of invoking "close()" on an undefined view,
	 * this method removes undefined / null properties from the object of subviews to be closed.
	 * @param (object) backbone views
	 * @return (void)
	 */
	removeUninitializedViews(views){
		Object.keys(views).forEach((key) => (views[key] == null) && delete views[key]);
	},
	/**
	 * updateMathJax
	 * Render Mathjax library
	 * @return (void)
	 */
	updateMathJax: function(){
		// TODO make math jax work
		//shim for processing errors
		// _.delay(function(){
		// 	//MathJax.Hub.Typeset();
		// 	MathJax.Hub.Queue(["Typeset",MathJax.Hub]);
		// }, 500);
	},
	//prevent auto submit on inputs with return/enter
	preventSubmit: event => {
		event.preventDefault();
	},
	/**
	 * back
	 * Use browser navigation
	 * @param (object) event
	 * @return (void)
	 */
	back: function(event){
		event.preventDefault();
		Backbone.history.history.back();
	},
	/**
	 * logout
	 * clears all cached data and session information
	 * @return (void)
	 */
	logout: function(event){
		// if event is present, user initiated logout via click
		if(event){
			event.preventDefault();
			// set cookie to check later, in order to force a refresh
			if(typeof Cookies !== 'undefined'){
				Cookies.set('user_logged_out', 1);
			}
		}

		//unlock any stored classroomSequence
		this.unlockClassroomSequence();

		//hide banner message
		$('.banner').removeClass('show');

		//server logout
		nm.vent.trigger('user:logout');
		localStorage.clear();
		this.clearGoogleAnalyticsProperties();

		Backbone.history.navigate('login', true);
	},
	/**
	 * ErrorHandler
	 * persists login and displays error state
	 * @return (void)
	 */
	errorHandler: function(model, response, options){
		//if 401, log the user out and send them to login screen...
		if(response.status === 401){
			//there was a last request and we've attempted this less than the default amount
			if(nm.lastRequestAttempts < nm.lastRequestDefault){
				//whether a success or failure, set flag so it won't replay again
				nm.lastRequestAttempts += 1;
				//get session and retry last ajax request
				//fetch user session, a new access token is probably needed
				nm.user.fetch({
					success: function(){
						//replay all calls in queue
						for(var lr in nm.lastRequestQueue){
							//replay the request
							$.ajax(nm.lastRequestQueue[lr]);
							//remove the request from the queue. if fails, it will be added back to queue
							delete nm.lastRequestQueue[lr];
						}
					},
					//if second attempt fails, then logout,
					//bound on every view that fetches a model or collection
					error: this.errorHandler
				});
			}else{
				//if last request already replayed, just logout
				this.logout();
			}
		//...else trigger a "notification" with an innoccuous, "not our fault!" kind of message :-D
		}else{
			nm.vent.trigger('app:error', new Notification({
				message: '<p>Your internet connection is a bit slow, please try again.</p>',
				bgColor: 'bg-plred',
				textColor: 'color-white'
			}));
			//check if the "main loading cover" is present... if so, chances are we errored in calling up a view.
			//in that case, send the user back so they can try again, or doing something else
			//window.history.back();
			//navigate to user's home page: when there is no history, users are taken away from site
			nm.vent.trigger('navigate:home');
		}
		//reset any forms on the page so the user can indeed re-attempt whatever they did
		this.clearAllForms();
		//old action taken was to navigate to catch-all "error" page... consider using it as a fall back for certain actions?
		//Backbone.history.navigate('error', true);
		//send exception event to google analytics
		// ga('send', 'exception', {
		// 	'exDescription': 'Error Handler: ' + String(response.status),
		// 	'exFatal': false
		// });
	},
	/**
	 * userErrorHandler
	 * specific error handler for required user endpoint response
	 * @return (void)
	 */
	userErrorHandler: function(model, response, options){
		nm.vent.trigger('user:error', response, this);
		if(!Backbone.History.started){
			Backbone.history.start();
		}
		if(nm.router.allowed()){
			return; //do nothing, the current route is allowed without authentication
		}
		this.errorHandler(model, response, options);

		//send exception event to google analytics
		// ga('send', 'exception', {
		// 	'exDescription': 'User Error Handler: ' + String(response.status),
		// 	'exFatal': false
		// });
	},
	/**
	 * assignSubViews
	 * assign 1 or more subviews easily when rendering a view
	 * Lifted from: http://ianstormtaylor.com/assigning-backbone-subviews-made-even-cleaner/
	 * @return (void)
	 */
	assignSubViews: function(selector, view){
		this.stopListening(nm.vent, 'subview:render');
		var selectors;
		if(_.isObject(selector)){
			selectors = selector;
		}else{
			selectors = {};
			selectors[selector] = view;
		}
		if(!selectors){
			return;
		}
		_.each(selectors, function(view, selector){
			if(view){ //check that view is defined
				//view.setElement(this.$(selector)).render();
				$(selector).html(view.render().el);
				//provide a function that calls after view is rendered
				view.afterRender();
			}
		}, this);
	},
	/**
	 * appendSubViews
	 * append 1 or more subviews easily when rendering a view
	 * Modified version of assignSubViews() above, where here, subviews' templates are append()-ed,
	 * not html()-ed to the mount point.
	 * @return (void)
	 */
	appendSubViews(selector, view){
		this.stopListening(nm.vent, 'subview:render');
		var selectors;
		if(_.isObject(selector)){
			selectors = selector;
		}else{
			selectors = {};
			selectors[selector] = view;
		}
		if(!selectors){
			return;
		}
		_.each(selectors, function(view, selector){
			if(view){ //check that view is defined
				$(selector).append(view.render().el);
			}
		}, this);
	},
	/**
	 * toggleActionPageStyle
	 * toggles page style between the default page style and the "action page" style (i.e. Login, Sign Up, etc.)
	 * @param (boolean) isActionPage - True activates the "action page" style, False switches back to default style
	 * @return (void)
	 */
	toggleActionPageStyle: function(isActionPage){
		if(typeof isActionPage === 'undefined'){
			isActionPage = false;
		}
		if(isActionPage){
			//if the current install has a background image set, apply it to these action pages
			$('html').addClass('bg-image');
			//set the actual image being used here
			$('html').css({
				'background-image': `url(${nm.appSettings.background})`
			});
			//if the current install has a logo image set, apply it to these action pages
			//set the actual image being used here
			$('.logo').find('img').remove();
			$('.logo').prepend(`<img src="${nm.appSettings.logo}" alt="${nm.appSettings.appName}"/>`);
			//check if set logo is the default (default contains "1" in the string)
			if(nm.appSettings.logo.indexOf('1') > -1){
				$('.logo').addClass('pl-logo');
			}
			$('.logo').find('span').text(nm.appSettings.clientName);
			$('#main-header').addClass('action-page-header');
			$('#main-page').addClass('action-page-body');
			$('#main-footer').addClass('action-page-footer');
			$('.main-loading').removeClass('regular-page');
			$('.notifications-container').addClass('hide-notification');
		}else{
			//be sure to remove bg image when accessing non-action pages
			$('html').removeClass('bg-image');
			$('html').css({
				'background-image': 'none'
			});
			$('.main-loading').addClass('regular-page');
			$('#main-header').removeClass('action-page-header');
			$('#main-page').removeClass('action-page-body');
			$('#main-footer').removeClass('action-page-footer');
			$('.notifications-container').removeClass('hide-notification');
		}
	},
	/**
	 * changeFormState
	 * change form state between 4 options: Success, Error, Loading, or Cleared state
	 * @param (string/object) targetForm - jQuery selector string for target form, or a jQuery object instance of the form
	 * @param (string) formState - Options: 'success', 'error', 'loading', 'cleared'
	 * @param (boolean) fullWidthCover - If true, the "cover" element will be 100% width. Else, a check will be run against the width of the button/input itself
	 * @return (void)
	 */
	changeFormState: function(targetForm, formState, fullWidthCover){
		fullWidthCover = fullWidthCover || false;
		var buttonCoverHTML = '<div class="input-loading-cover"><div class="loader"><div class="flipper"><div class="front"></div><div class="back"></div></div></div></div>';
		var buttonCoverClass = '.input-loading-cover';
		//if targetForm isn't already a jQuery object, make it one
		if(typeof targetForm === 'string'){
			targetForm = $(targetForm);
		}
		//note: for any instance where an element that triggers the "submission" is NOT a submit input, make sure that item has the ".is-submit" class on it
		if(formState === 'loading'){
			targetForm.find('input[type="submit"], .is-submit').parent('.form-row').alterClass('input-*', 'input-' + formState).append(buttonCoverHTML);
			if(!fullWidthCover){
				//for instances where the button is not "full width", this sets the cover element to the same width as the button, also re-positions the cover too
				$(buttonCoverClass).width(targetForm.find('input[type="submit"], .is-submit').last().outerWidth()).css({
					left: targetForm.find('input[type="submit"], .is-submit').last().position().left,
					top: targetForm.find('input[type="submit"], .is-submit').last().position().top
				});
			}
		}else{
			targetForm.find('input[type="submit"], .is-submit').parent('.form-row').removeClass().addClass('form-row submit-form-row input-' + formState).find(buttonCoverClass).empty().remove();
		}
	},
	/**
	 * changeInputState
	 * change input state between 4 options: Success, Error, Loading, or Cleared state
	 * @param (string) targetInput - jQuery selector string for target input
	 * @param (string) inputState - Options: 'success', 'error', 'loading', 'cleared'
	 * @param (boolean) fullWidthCover - If true, the "cover" element will be 100% width. Else, a check will be run against the width of the button/input itself
	 * @return (void)
	 */
	changeInputState: function(targetInput, inputState, fullWidthCover){
		fullWidthCover = fullWidthCover || false;
		var inputCoverHTML = '<div class="input-loading-cover"><div class="loader"><div class="flipper"><div class="front"></div><div class="back"></div></div></div></div>';
		var inputCoverClass = '.input-loading-cover';
		//if targetForm isn't already a jQuery object, make it one
		if(typeof targetInput === 'string'){
			targetInput = $(targetInput);
		}
		if(inputState === 'loading'){
			targetInput.closest('.form-row').removeClass().addClass('form-row input-' + inputState).append(inputCoverHTML);
			if(!fullWidthCover){
				//for instances where the button is not "full width", this sets the cover element to the same width as the button.
				$(inputCoverClass).width(targetInput.outerWidth());
			}
		}else{
			targetInput.closest('.form-row').removeClass().addClass('form-row input-' + inputState).find(inputCoverClass).empty().remove();
		}
		if(inputState === 'cleared'){
			targetInput.closest('.form-row').find('i').remove();
		}
	},
	/**
	 * clearAllForms
	 * Used primarily by the error handler function, this "resets" the visual state of every form on the current page.
	 * @return (void)
	 */
	clearAllForms: function(){
		var buttonCoverClass = '.input-loading-cover';
		$('form').each(function(){
			$(this).find('input[type="submit"], .is-submit').parent('.form-row').removeClass().addClass('form-row input-cleared').find(buttonCoverClass).empty().remove();
		});
	},
	//TODO: Replace the following two functions with an appropriate date library of some kind?
	/**
	 * getTimestampSeconds
	 * Get current timestamp in seconds
	 * @return (Number)
	 */
	getTimestampSeconds: function(){
		return Math.round(new Date().getTime() / 1000);
	},
	dateToReadableTime: function(date){
		date = date || new Date();
		var hours = date.getHours();
		var minutes = date.getMinutes();
		var ampm = hours >= 12 ? 'pm' : 'am';
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? '0' + minutes : minutes;
		var strTime = hours + ':' + minutes + ampm;
		return strTime;
	},
	/**
	 * setSynced
	 * For use inside success callback of fetch, this takes a view's model/collection and sets the "synced" property to true.
	 * Note: the arguments for this function deliberately match what fetch() passes to "success" callback
	 * @param (obj) model - the model or collection
	 * @param (obj) response - the response returned from the server
	 * @param (obj) options
	 * @return (void)
	 */
	setSynced(model, response, options){
		model.synced = true;
	},
	/**
	 * checkSynced
	 * An app-wide conditional to be used for rendering views. Checks if the view has an associated model/collection, as well as any subview models/collections, and returns true when all data is ready.
	 * "this" is expected to be the view in which this function is run.
	 * @return (boolean)
	 */
	checkSynced: function(){
		//recurse through sub views
		if(this.views){
			for(var x in this.views){
				if(!this.views[x].checkSynced()){
					return false;
				}
			}
		}
		//...if there's an un-synced model...
		if(this.model && !this.model.synced){
			return false;
		}
		//...if there's an un-synced collection...
		if(this.collection && !this.collection.synced){
			return false;
		}
		return true;
	},
	//TODO: Do we still need / use this?
	/**
	 * renderSubView
	 * Triggers that a subview is ready for rendering by the parent
	 * @return (void)
	 */
	renderSubView: function(){
		nm.vent.trigger('subview:render');
	},
	/**
	 * setGoogleAnalyticsProperties
	 * Set custom "dimensions" in Google Analytics, run when the user has been authenticated.
	 * Currently, GA has two custom dimensions, one for tracking "user role" (teacher, student, etc.).
	 * and another for "User PLID", their positive learning numeric ID.
	 * @param (userModel) backbone model nm.user
	 */
	setGoogleAnalyticsProperties: function(userModel){
		userModel = userModel || null;
		if(userModel){
			//sets custom "User Role" and "User PLID" dimensions in google analytics
			var userRole = 'unknown';
			if(userModel.get('type') === 'student'){
				userRole = 'student';
			}else if(userModel.get('type') === 'staff'){
				if(userModel.get('admin')){
					userRole = 'staff admin';
				}else{
					userRole = 'staff';
				}
			}
			//var userId = String(userModel.get('id'));
			// ga('set', 'dimension1', userRole); //LEGACY User Role in ga (data stored with older settings)
			// ga('set', 'dimension2', userId); //User PLID in ga
			// ga('set', 'dimension4', userRole); //User Type in ga (was "User Role", above)
		}
	},
	/**
	 * clearGoogleAnalyticsProperties
	 * Clears previously mentioned custom dimensions upon direction to login page
	 * Currently, google indicates that these can't be "unset", so the practice is to
	 * "clear" previous settings by simply setting them to empty strings
	 */
	clearGoogleAnalyticsProperties: function(){
		// ga('set', 'dimension1', ''); //LEGACY User Role in ga
		// ga('set', 'dimension2', ''); //User PLID in ga
		// ga('set', 'dimension4', ''); //User Role in ga
	},
	/**
	 * getSearchParameters
	 * Split get parameters for query string. Note: not real search query because URL hash
	 *
	 * @param (string) queryString
	 * @return (object)
	 */
	getSearchParameters: function(queryString){
		var pair = null;
		var params = {};
		//split name value pairs in URL
		var keyPairs = (queryString.substr(queryString.indexOf('?') + 1)).split("&");
		for(var x in keyPairs){
			pair = keyPairs[x].split("="); //split name and value
			params[pair[0]] = pair[1]; //set object property and value
		}
		return params;
	},
	/**
	 * prepareBundles
	 * Expects an array of "bundles", and returns an array of bundles
	 * wherein there is one per ELP level available in the grouping of bundles.
	 * For example, if it receives two bundles that relate to ELP levels 1-4,
	 * this returns an array of 4 bundles, 1 per ELP level.
	 *
	 * @param (array) bundles
	 * @return (array)
	 */
	prepareBundles: function(bundles){
		if(bundles.length <= 0){
			return [];
		}
		bundles[0].bundleTitle = 'View Primer Summary';
		bundles[0].bundleSlug = bundles[0].bundleTitle.toLowerCase().replace(/ /g,'-');
		//return the first in the set of bundles
		return [bundles[0]];
	},
	/**
	 * loggedInRedirect
	 * Redirects a logged in user away to their "home" page from whatever the current view is
	 *
	 * @return (void)
	 */
	loggedInRedirect: function(){
		if(nm.user.has('type')){
			if(nm.user.get('type') === 'student'){
				Backbone.history.navigate('learning-center', true);
			}else{
				Backbone.history.navigate('classrooms', true);
			}
		}
	},
	/**
	 * newEventStatus
	 * Trigger an event that passes information needed to create a new EventStatus
	 *
	 * @param (string) key
	 * @param (int) bundleId
	 * @return (void)
	 */
	newEventStatus: function(key, bundleId){
		var statusData = {
			key: key,
			bundleId: bundleId
		};
		nm.vent.trigger('status:update', statusData);
	},
	/**
	 * getStudentBundle
	 * Given an array of student assigned Bundles, returns the bundle that applies
	 * to the currently logged in student
	 *
	 * @param (array) assignedBundles - array of Assigned Bundle objects
	 * @return (void)
	 */
	getStudentBundle: function(assignedBundles){
		var studentBundle = _.find(assignedBundles, function(bundle){
			return bundle.studentId == nm.user.get('id');
		});
		return studentBundle;
	},
	hideHeader: function(){
		$('.main-loading').addClass('assignment-page');
		$('#main-header').addClass('hide-header');
	},
	showHeader: function(){
		$('#main-header').removeClass('hide-header');
		$('.main-loading').removeClass('assignment-page');
	},
	hideDynamicHeader: function(){
		$('.dynamic-header').removeClass('activated');
	},
	/**
	 * Replace words found in the "words" property with markup for highlighting
	 * @param (string) copy
	 * @param (array) words
	 * @return (string)
	 */
	highlight: function(copy, words){
		//don't bother trying to highlight if this "copy" is a mathjax / latex string
		/* eslint-disable no-useless-escape */
		if(copy && copy.indexOf('\[') === -1){
		/* eslint-enable no-useless-escape */
			var expression;
			var regex;
			var matchFound = false;
			//sort vocabulary by number of "words", allows us to highlight vocabulary phrases that
			//may contain other vocabulary words
			var sortedWords = _.sortBy(words, function(w){
				return -(w.word.match(/\s/g) || []).length;
			});
			//iterate over dictionary of words
			_.each(sortedWords, function(word, index){
				//if a match was found for current word already, don't bother continuing to compare remaining words
				if(!matchFound){
					//if word is actually not a word, if it's a symbol as HTML entity
					//there must be spaces/non-word-characters around HTML entities for this to work
					if(word.word.indexOf('&') === 0){
						expression = "\\B" + word.word + "\\B";
						//if the word ends in "y", it's plural might become "ies" without the y
					}else if(word.word.endsWith('y')){
						var removedTrailingY = word.word.substring(0, word.word.length - 1);
						expression = "\\b" + word.word + "[es,s]*\\b" +
							"|\\b" + removedTrailingY + "[ies]*\\b";
					}else{
						expression = "\\b" + word.word + "[es,s]*\\b";
					}
					regex = new RegExp(expression, "gi");
					copy = copy.replace(regex, function(match){
						matchFound = true;
						//collect matches across the slide
						nm.vent.trigger('vocab:encounter', word);
						return highlight({
							word: match,
							type: word.type,
							id: word.id
						}).trim();
					});
				}
			}, this);
		}else{
			// for latex "words", work around a mathjax bug where <br> tags can't directly adjoin equation text
			// removable when https://github.com/mathjax/MathJax/issues/2202 makes a versioned release
			regex = new RegExp("(<br>)|(<br/>)", "gi");
			copy = copy.replace(regex, () => "<br> ");
		}
		return copy;
	},
	/**
	 * comfortNumberToAdjective
	 * Accepts a number between 1 and 5 (a "comfort rating") and returns the corresponding "adjective"
	 * For example, passing "5" returns "Very Comfortable"
	 * @param (int) comfortRating
	 * @return (string)
	 */
	comfortNumberToAdjective: function(comfortRating = 0){
		switch(comfortRating){
			case 1:
				return 'I\'m not sure';
			case 2:
				return 'Just OK';
			case 3:
				return 'I feel fine';
			case 4:
				return 'I feel pretty good';
			case 5:
				return 'I feel great!';
			default:
				return '';
		}
	},
	/**
	 * afterRender
	 * Override-able method called within assignSubView to allow for post-rendered activities
	 * @return (void)
	 */
	afterRender: function(){
		// you should override me in your view
	},
	/**
	 * prepareTextTranslation
	 * Override-able method called within assignSubView to allow for post-rendered activities
	 * @param (string) text - Any text string meant to be sent for conversion to audio/speech
	 * @return (void)
	 */
	prepareTextTranslation: function(text){
		text = text || null;
		var preparedText = '';
		//make sure text exists and is a string
		if(typeof text === 'string'){
			preparedText = this.removeTextAnomalies(text);
			preparedText = this.underscoresToBlank(preparedText);
			//search for MathJax expressions and remove "\", so they are not spoken
			preparedText = this.mathjaxForSpeech(preparedText);
		}
		return preparedText;
	},
	/**
	 * removeTextAnomalies
	 * Remove line breaks, extraneous spaces, and other anomalies
	 * @param (string) text
	 * @return (string)
	 */
	removeTextAnomalies: function(text){
		return text.toString().trim().replace(/[^\x20-\x7E]/gmi, '').replace(/&nbsp;/gi,'');
	},
	/**
	 * underscoresToBlank
	 * Search for groupings of 3 or more underscores, and replace each with the word "BLANK"
	 * @param (string) text
	 * @return (string)
	 */
	underscoresToBlank: function(text){
		return text.replace(/_{3,}/g, 'BLANK');
	},
	/**
	 * mathjaxForSpeech
	 * Search for and remove mathjax/latex tags
	 * @param (string) text
	 * @return (string)
	 */
	mathjaxForSpeech: function(text){
		//locate a variety of tags and replace as appropriate
		text = text.replace(/(\\\[)/g, ''); //mathjax/latex opening tag
		text = text.replace(/(\\\])/g, ''); //mathjax/latex closing tag
		text = text.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g,' '); //replaces <br> tags, incl. <br>, <br/>, &lt;br&gt;, with a space
		text = text.replace(/(&ldquo;)/g, ' '); //remove curly quote entities
		text = text.replace(/(&rdquo;)/g, ' '); //remove curly quote entities
		text = text.replace(/(\\div)/g, 'DIVIDED BY');
		text = text.replace(/(\\times)/g, 'MULTIPLIED BY');
		text = text.replace(/(\\neq)/g, 'Is NOT EQUAL TO'); //lower case "s" so bing doesn't say "i-s"
		text = text.replace(/(\\sqrt)/g, 'SQUARE ROOT OF');
		text = text.replace(/(\\sqrt\[n\])/g, 'CUBE ROOT OF');
		text = text.replace(/(\\leq)/g, 'LESS THAN OR EQUAL TO');
		text = text.replace(/(\\geq)/g, 'GREATER THAN OR EQUAL TO');
		text = text.replace(/(\\triangle)/g, 'TRIANGLE');
		text = text.replace(/(\\angle)/g, 'ANGLE');
		text = text.replace(/(\\overline)/g, 'LINE SEGMENT');
		text = text.replace(/(\\overrightarrow)/g, 'RAY');
		text = text.replace(/(\\approx)/g, 'APPROXIMATELY EQUAL TO');
		text = text.replace(/(\\frac)/g, 'THE FRACTION');
		text = text.replace(/(\\cong)/g, 'Is CONGRUENT TO');
		text = text.replace(/(&le;)/g, 'Is LESS THAN OR EQUAL TO');
		text = text.replace(/(&ge;)/g, 'Is GREATER THAN OR EQUAL TO');
		text = text.replace(/(<)/g, 'Is LESS THAN');
		text = text.replace(/(>)/g, 'Is GREATER THAN');
		text = text.replace(/(&lt;)/g, 'Is LESS THAN');
		text = text.replace(/(&gt;)/g, 'Is GREATER THAN');
		return text;
	},
	refreshButtonLoading: function(ele){
		//animate button to show loading
		ele.find('i').addClass('hide-icon');
		ele.find('.animated').removeClass('hide-icon').addClass('flash');
	},
	/**
	 * decodeThenEncode
	 * Quickly decode, and then encode, a string.
	 * Handy for preparing "back" get parameter.
	 * (Ensures that the entirety of any given
	 * 'Backbone.history.fragment' is encoded for use as a GET parameter)
	 *
	 * @param (string) string
	 * @return (string)
	 */
	decodeThenEncode: function(string){
		if(typeof string === 'string'){
			string = decodeURIComponent(string);
			string = encodeURIComponent(string);
			return string;
		}
	},
	/**
	 * extractBackParameter
	 * Find and return the "back" parameter of a URL.
	 * The "back" parameter will contain an encoded URL, which itself may have query/back parameters.
	 *
	 * @param (string) queryString
	 * @return (string)
	 */
	extractBackParameter: function(queryString){
		var backUrl = '';
		backUrl = queryString.substring(queryString.indexOf('('),queryString.lastIndexOf(')') + 1);
		backUrl = backUrl.slice(1, -1);
		this.decodeThenEncode(backUrl);
		return backUrl;
	},
	/**
	 * decodeBackUrl
	 * Decode a "back" url, which may itself contain a "back" parameter,
	 * so we need to make sure the "back" parameter is "encoded", but the rest
	 * of the URL is "decoded".
	 *
	 * @param (string) backUrl
	 * @return (string)
	 */
	decodeBackUrl: function(backUrl){
		//start by decoding the entire URL we got from the "back" parameter
		backUrl = decodeURIComponent(backUrl);
		//if this decoded url has it's own "back" parameter...
		if(backUrl.indexOf('back=') > -1){
			//grab the (currently decoded) value of "back"...
			var extractedBack = '(' + this.extractBackParameter(backUrl) + ')';
			//...and encoded it for later.
			var encodedBack = encodeURIComponent(extractedBack);
			//remove the "back" value from the URL...
			var start = backUrl.indexOf(extractedBack);
			var end = start + extractedBack.length;
			//...and replace it with the encoded version
			backUrl = backUrl.slice(0, start) + encodedBack + backUrl.slice(end);
		}
		//return our complete URL, which is decoded, except for the "back" value, which is encoded
		return backUrl;
	},
	/**
	 * showLiveChat
	 * Displays support chat box, and also sets some user properties
	 * to share with chat specialist.
	 *
	 * @return (void)
	 */
	showLiveChat(){
		// TODO zoho isn't used by students right?
		// if($zoho && $zoho.salesiq && $zoho.salesiq.floatbutton){
		// 	$zoho.salesiq.floatbutton.visible('show');
		// }
	},
	hideLiveChat(){
		// TODO zoho isn't used by students right?
		// if($zoho && $zoho.salesiq && $zoho.salesiq.floatbutton){
		// 	$zoho.salesiq.floatbutton.visible('hide');
		// }
	},
	/**
	 * Create classrooms collection with necessary nested students
	 * @return (collection)
	 */
	setupClassrooms: function(classrooms){
		//setup lookup collections
		var students = new Backbone.Collection(classrooms.get('students'));
		_.each(classrooms.get('results'), function(classroom, index){
			classroom.students = [];
			classroom.count = classroom.studentIds.length;
			_.each(classroom.studentIds, function(studentId){
				classroom.students.push(students.get(studentId).toJSON());
			}, this);
		}, this);
		return new Backbone.Collection(classrooms.get('results'));
	},
	//TODO: Improve hide/show animation of assignment sections, confirm 60fps performance
	toggleClassroomStudents: function(event){
		event.preventDefault();
		var clickedButton = $(event.currentTarget);
		var buttonChevron = clickedButton.find('i').first();
		var targetStudents = clickedButton.parents('.item-row').find('.item-content-container');
		if(targetStudents.hasClass('show')){
			targetStudents.removeClass('show');
			buttonChevron.text('chevron_right');
		}else{
			targetStudents.addClass('show');
			buttonChevron.text('expand_more');
		}
	},
	trackPageView: function(url){
		url = url || Backbone.history.getFragment();
		if(!/^\//.test(url)){
			url = '/' + url;
		}
		// ga('set', {
		// 	page: url
		// });
		// ga('send', 'pageview');
	},
	/**
	 * fadeInImg
	 * "Fades in" a targeted image when it has loaded.
	 * @param (string) targetImg - jQuery object of target image
	 * @return (void)
	 */
	fadeInImg: function(targetImg){
		targetImg = targetImg || null;
		if(targetImg){
			this.$el.find('.img-column-placeholder').hide();
			targetImg.parents('.img-column').removeClass('img-column-loading');
			targetImg.fadeIn(1000);
		}
	},
	stopTextToSpeechAudio: function(){
		// TODO need to text to speech?
		//this.$el.find('#tts-audio')[0].pause();
	},
	/**
	 * toggleRowTray
	 * @param (Object) options, configurable options
	 * @return (void)
	 */
	toggleRowTray(clickedItem, options){
		let $ele = clickedItem;
		let $targetRow = $ele.parents('.flexed-item-row').first();
		let $containingList = $ele.parents('.rows-list').first();
		let $openRows;
		//if clicked element IS the row, set it as such
		if($ele.hasClass('flexed-item-row')){
			$targetRow = $ele;
		}
		let $otherRows = $containingList.find('.flexed-item-row').not($targetRow);
		if($targetRow.hasClass('show-tray')){
			$targetRow.removeClass('show-tray');
			$openRows = $containingList.find('.show-tray');
			if(!$openRows.length){
				$containingList.removeClass('showing-tray');
			}
		}else{
			$targetRow.addClass('show-tray');
			//leave other rows open
			if(!(options && options.leaveOthersOpen)){
				$otherRows.removeClass('show-tray');
			}
			$containingList.addClass('showing-tray');
		}
	},
	/**
	 * toggleKebab
	 * Opens / Closes the clicked kebab menu
	 * @return (void)
	 */
	toggleKebab(event){
		event.stopPropagation();
		let clickedKebab = $(event.currentTarget);
		$('.kebab-items').removeClass('active'); //close other kebabs
		clickedKebab.next('.kebab-items').toggleClass('active');
	},
	/**
	 * hideKebabs
	 * Hides any / all kebab menus that might be open
	 * @return (void)
	 */
	hideKebabs(){
		$('.kebab-items').removeClass('active');
		$('.bar-items').removeClass('active');
	},
	/**
	 * setupKebabMenu
	 * For any "kebab" menu found, sets the appropriate css transition on
	 * the kebab's menu items
	 * @return (void)
	 */
	setupKebabMenu(){
		$('.kebab-items li').each(function(){
			let delay = $(this).index() * 50 + 'ms';

			$(this).css({
				'-webkit-transition-delay': delay,
				'-moz-transition-delay': delay,
				'-o-transition-delay': delay,
				'transition-delay': delay
			});
		});
	},
	/**
	 * attrEq
	 * Checks if provided key exists, and if it does, if it also matches provided value.
	 * Returns false if either key does not exist or if value doesn't match, otherwise, returns true.
	 * Part of an adaptation of a gist found here: https://gist.github.com/jimmed/6608648
	 * Used in arrayToTree() below.
	 * @return (boolean)
	 */
	attrEq(key, value, input){
		return input[key] && input[key] == value;
	},
	/**
	 * arrayToTree
	 * Given a 'flat' array of items that each have rootIds and parentIds
	 * representing a tree structure, returns an array wherein the items
	 * are organized into that tree.
	 * Part of an adaptation of a gist found here: https://gist.github.com/jimmed/6608648
	 * @return (array)
	 */
	arrayToTree(data, rootId, primaryKeyName, foreignKeyName){
		primaryKeyName = primaryKeyName || 'id';
		foreignKeyName = foreignKeyName || 'parentId';
		rootId = rootId || (_.first(data) || {})[primaryKeyName] || 0;

		var output = _.clone(_.find(data, _.partial(this.attrEq, primaryKeyName, rootId)));
		var children = _.filter(data, _.partial(this.attrEq, foreignKeyName, rootId));

		output.subgroups = _.map(children, function(child){
			return this.arrayToTree(data, child[primaryKeyName], primaryKeyName, foreignKeyName);
		}, this);

		return output;
	},
	/**
	 * toggleInterstitial
	 * Toggles showing of interstitial window
	 * @return (void)
	 */
	toggleInterstitial(event){
		if(event){
			event.preventDefault();
		}
		$('.interstitial-holder').toggleClass('show');
		_.delay(function(){
			$('#nm-interstitial').toggleClass('show');
		}, 200);
	},
	/**
	 * closeInterstitial
	 * Closes interstitial window
	 * @return (void)
	 */
	closeInterstitial(event){
		if(event){
			event.preventDefault();
		}
		$('.interstitial-holder').removeClass('show');
		_.delay(function(){
			$('#nm-interstitial').removeClass('show');
		}, 200);
	},
	playerIsActive(){
		$('#nm-footer').addClass('player-active');
	},
	playerIsInactive(){
		$('#nm-footer').removeClass('player-active');
	},
	unlockClassroomSequence(){
		let lockedClassroomSequence = JSON.parse(localStorage.getItem('lockedClassroomSequence'));
		if(lockedClassroomSequence){
			let classroomSequence = new ClassroomSequence({
				id: lockedClassroomSequence.id,
				cancelEditRequest: true
			});
			classroomSequence.save({}, {
				success: (model, response, options) => {
					//remove this classsroomSequence from localStorage "lockedSequence"
					if($('.localstorage').length){
						localStorage.removeItem('lockedClassroomSequence');
					}
				},
				error: (model, response, options) => {
					//do not "errorHandler" and interrupt user, log to GA for debugging if needed
					// ga('send', {
					// 	hitType: 'event',
					// 	eventCategory: 'Classroom Sequence',
					// 	eventAction: 'Unlock Failure',
					// 	eventLabel: `Failed to unlock classroom sequence ${lockedClassroomSequence.id}
					// 		from localStorage (${response.status})`
					// });
				}
			});
			//cancel repeating edit extensions
			clearTimeout(nm.timeout);
		}
	},
	/**
	 * Returns the currentPosition based on a studentPositions array
	 * @param {Object[]}
	 * @return (number)
	 */
	determinePosition(studentPositions){
		if(studentPositions.length === 0){
			return 0;
		}
		let currentPosition = _.max(_.map(studentPositions, 'position'));
		if(currentPosition === null){
			currentPosition = 0;
		}else{
			currentPosition += 1;
		}
		return currentPosition;
	},
	/**
	 * Download the link provided, cross browser
	 * @param {string} url
	 * @param {string} blobSize - The size, in bytes, of the provided Blob
	 * @param {string} filename
	 * @return (void)
	 */
	downloadFile(url, blobSize, filename){
		//if link created by this function already exists from eariler click, remove it
		$('#export-students-csv').remove();
		let link = document.createElement('a');
		//set ID to make removal easy, set export size to be used in e2e testing
		link.setAttribute('href', url);
		link.setAttribute('id', 'export-students-csv');
		link.setAttribute('download', `${filename}`);
		link.setAttribute('data-export-size', `${blobSize}`);
		document.body.appendChild(link);
		link.click();
	},
	/**
	 * Bridge to react-router
	 * @param {object} e -- event from link click
	 * @param {string} blobSize - The size, in bytes, of the provided Blob
	 * @param {string} filename
	 * @return (void)
	 */
	routingNavigate(e){
		e.preventDefault();
		if (this.navigateFn) {
			this.navigateFn(e.currentTarget.hash);
		}
	},
});
