var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"vocab-image\">\n				<img src=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"definition") : depth0)) != null ? lookupProperty(stack1,"asset") : stack1), depth0))
    + "\" />\n			</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"identicalDefinitions") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":11,"column":5},"end":{"line":20,"column":12}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<span class=\"accesible-heading\">"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"definition") : depth0)) != null ? lookupProperty(stack1,"word") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n						<p class=\"accesible-definition\">"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"definition") : depth0)) != null ? lookupProperty(stack1,"definition") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<span class=\"accesible-heading\">"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"definition") : depth0)) != null ? lookupProperty(stack1,"word") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n						<p class=\"accesible-definition\">"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"definition") : depth0)) != null ? lookupProperty(stack1,"alternate") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n						<hr/>\n						<span class=\"precise-heading\">"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"definition") : depth0)) != null ? lookupProperty(stack1,"word") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n						<p class=\"precise-definition\">"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"definition") : depth0)) != null ? lookupProperty(stack1,"definition") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<span class=\"accesible-heading\">"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"definition") : depth0)) != null ? lookupProperty(stack1,"word") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n					<p class=\"accesible-definition\">"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"definition") : depth0)) != null ? lookupProperty(stack1,"definition") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "		<button class=\"scott-circle-button vocab-play\"><i class=\"material-icons\">pause</i></button>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "		<button class=\"scott-orange-button vocab-review-definition\">Back To Slide</button>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<button class=\"scott-orange-button close\">Okay, got it</button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"reviewSlidesDefined") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":2},"end":{"line":41,"column":9}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasReview") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":36,"column":3},"end":{"line":40,"column":10}}})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<button class=\"scott-button vocab-review\" data-id=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"definition") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">Tell Me More</button>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "				<button class=\"scott-button vocab-review\" disabled>Tell Me More</button>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<track kind=\"captions\" src=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"definition") : depth0)) != null ? lookupProperty(stack1,"captions") : stack1), depth0))
    + "\" srclang=\"en\" label=\"English\" default>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"definition\">\n	<div class=\"definition-content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"definition") : depth0)) != null ? lookupProperty(stack1,"asset") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":7,"column":9}}})) != null ? stack1 : "")
    + "		<div class=\"definition-details\">\n			<span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"definition") : depth0)) != null ? lookupProperty(stack1,"alternate") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":24,"column":11}}})) != null ? stack1 : "")
    + "			</span>\n		</div>\n	</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"definition") : depth0)) != null ? lookupProperty(stack1,"audio") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":1},"end":{"line":30,"column":8}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fromReview") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":31,"column":1},"end":{"line":42,"column":8}}})) != null ? stack1 : "")
    + "\n</div>\n<audio id=\"definition-"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"definition") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "-audio\" class=\"definition-audio\" preload=\"metadata\" crossorigin=\"anonymous\">\n	<source class=\"definition-audio-mp3\" src=\"\" type=\"audio/mp3\"></source>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"definition") : depth0)) != null ? lookupProperty(stack1,"captions") : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":1},"end":{"line":49,"column":8}}})) != null ? stack1 : "")
    + "	Your browser does not support the audio format.\n</audio>\n";
},"useData":true});