var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"comicPanels") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":1},"end":{"line":16,"column":10}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<div class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"layoutClasses") || (depth0 != null ? lookupProperty(depth0,"layoutClasses") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"layoutClasses","hash":{},"data":data,"loc":{"start":{"line":4,"column":14},"end":{"line":4,"column":31}}}) : helper)))
    + " comic-panel\" id=\"comic-panel-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":4,"column":61},"end":{"line":4,"column":67}}}) : helper)))
    + "\">\n			<div class=\"comic-img\" style=\"background-image:url('"
    + alias4(((helper = (helper = lookupProperty(helpers,"asset") || (depth0 != null ? lookupProperty(depth0,"asset") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"asset","hash":{},"data":data,"loc":{"start":{"line":6,"column":55},"end":{"line":6,"column":64}}}) : helper)))
    + "')\"></div>\n		</div>\n		<audio id=\"slide-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":9,"column":19},"end":{"line":9,"column":25}}}) : helper)))
    + "-audio\" class=\"slide-audio\" preload=\"metadata\" autoplay=\"false\" muted=\"true\" crossorigin=\"anonymous\" data-panel-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":9,"column":141},"end":{"line":9,"column":147}}}) : helper)))
    + "\">\n			<source class=\"slide-audio-mp3\" src=\""
    + alias4(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"audioLinks") : depth0)) != null ? lookupProperty(stack1,"normal") : stack1), depth0))
    + "\" type=\"audio/mp3\"></source>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"captions") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":3},"end":{"line":13,"column":10}}})) != null ? stack1 : "")
    + "			Your browser does not support the audio format.\n		</audio>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<track class=\"slide-audio-captions-normal\" kind=\"captions\" src=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"captionLinks") : depth0)) != null ? lookupProperty(stack1,"normal") : stack1), depth0))
    + "\" srclang=\"en\" label=\"Normal\" default>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"comic\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"comicPanels") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":17,"column":8}}})) != null ? stack1 : "")
    + "</div>\n<div id=\"complete-comic\">\n	<a href=\"#\" class=\"green-button nm-adaptive-practice\"><span>Next</span> <i class=\"material-icons\">keyboard_arrow_right</i></a>\n</div>\n";
},"useData":true});