import $ from 'jquery';
import _ from 'underscore';
import interact from 'interactjs';
import AppBaseView from '../base/AppBaseView';
import nm from '../../nm';
import stemDragBankView from '../../templates/questions/enhancedElp/stemDragBankView.handlebars';

export default AppBaseView.extend({
	events: {
	},
	className: 'stem-drag-bank-holder',
	initialize: function(options){
		_.bindAll(this, 'errorHandler');
		this.wordBank = options.wordBank;
		this.response = options.response;
		this.listenTo(nm.vent, 'stem_fill:fill_slot', this.removeWordFromBank);
		this.listenTo(nm.vent, 'stem_fill:render_bank', this.render);
		this.listenTo(nm.vent, 'slide_question:submit', this.disableDragAndDrop);
	},
	render: function(){
		this.$el.html(stemDragBankView({
			wordBank: this.prepareWordBank()
		}));
		//only init drag/drop interface if no response is present
		_.defer(() => {
			if(!this.response){
				this.initWordBankDragging();
			}else{
				this.destroyWordBankDragging();
			}
		});
		return this;
	},
	initWordBankDragging(){
		let that = this;
		//clear any interact-ables that might be present from previous render
		this.destroyWordBankDragging();

		//set drag interactivity on bank word elements
		interact('.enhanced-stem-drag-bank-word').draggable({
			autoScroll: true,
			//when starting drag, rotate the word to show it's "active"
			onstart(event){
				let draggingWord = event.target;
				let transformStyle = 'rotate(-10deg)';
				//set rotated transform on word element on major browsers
				draggingWord.style.webkitTransform = transformStyle;
				draggingWord.style.MozTransform = transformStyle;
				draggingWord.style.msTransform = transformStyle;
				draggingWord.style.OTransform = transformStyle;
				draggingWord.style.transform = transformStyle;
			},
			//actually move word as user drags via css translate transform
			onmove(event){
				let draggingWord = event.target;
				let x = (parseFloat(draggingWord.getAttribute('data-x')) || 0) + event.dx;
				let y = (parseFloat(draggingWord.getAttribute('data-y')) || 0) + event.dy;
				let transformStyle = 'translate(' + x + 'px, ' + y + 'px) rotate(-10deg)';
				//set the translate with new coordinates on the word element
				draggingWord.style.webkitTransform = transformStyle;
				draggingWord.style.MozTransform = transformStyle;
				draggingWord.style.msTransform = transformStyle;
				draggingWord.style.OTransform = transformStyle;
				draggingWord.style.transform = transformStyle;
				//update the word's data attributes for current coordinates
				draggingWord.setAttribute('data-x', x);
				draggingWord.setAttribute('data-y', y);
				//prevent text selection / highlighting during dragging
				if(document.selection){
					document.selection.empty()
				}else{
					window.getSelection().removeAllRanges()
				}
			},
			onend(event){
				let draggingWord = event.target;
				let $allSlots = $('.stem-drag-part-slot');
				let overlapping = false;

				//check if dragged word is overlapping a slot
				$allSlots.each(function(){
					let slotOverlap = that.areElementsOverlapping(draggingWord, $(this)[0]);
					if(slotOverlap){
						overlapping = true;
					}
				});

				//if drag began from full slot, and now NOT overlapping a slot, send word back to bank...
				if(!overlapping && $(draggingWord).hasClass('slotted')){
					that.addWordToBank(event);
					nm.vent.trigger('stem_fill:emptied_slot', event);
				//...word was dragged around but never slotted, so re-render existing bank
				}else if(!overlapping){
					that.render();
				}
			}
		});
	},
	destroyWordBankDragging(){
		interact('.enhanced-stem-drag-bank-word').unset();
	},
	disableDragAndDrop(){
		interact('.enhanced-stem-drag-bank-word').draggable({
			enabled: false
		});
	},
	//remove a word from bank
	removeWordFromBank(interactEvent){
		let $draggingWord = $(interactEvent.relatedTarget);
		let wordIndex = _.indexOf(this.wordBank, $draggingWord.find('span').text());
		if(wordIndex > -1){
			this.wordBank.splice(wordIndex, 1);
		}
		this.render();
	},
	//add a word to the bank
	addWordToBank(interactEvent){
		let $draggingWord = $(interactEvent.relatedTarget);
		$draggingWord = $(interactEvent.target);
		this.wordBank.push($draggingWord.find('span').text());
		this.render();
	},
	//given two elements, returns boolean indicating if they overlap on screen or not
	areElementsOverlapping(ele1, ele2){
		let rect1 = ele1.getBoundingClientRect();
		let rect2 = ele2.getBoundingClientRect();
		let overlap = !(rect1.right < rect2.left ||
			rect1.left > rect2.right ||
			rect1.bottom < rect2.top ||
			rect1.top > rect2.bottom);
		return overlap;
	},
	prepareWordBank(){
		//if response is present, removes words used in response from bank
		if(this.response){
			let usedWords = this.response.response.enhanced;
			for(let key in usedWords){
				let index = this.wordBank.indexOf(usedWords[key]);
				if(index > -1){
					this.wordBank.splice(index, 1);
				}
			}
		}
		return this.wordBank;
	}
});
