/**
 * ConvResponseView
 * Renders entire conversation, used when rendering a conversation a student has "answered"
 */
import $ from 'jquery';
import _ from 'underscore';
import AppBaseView from '../base/AppBaseView';
import nm from '../../nm';
import convResponseView from '../../templates/questions/enhancedElp/convResponseView.handlebars';

export default AppBaseView.extend({
	events: {
		'click .play-conversation': 'playConversationSpeech'
	},
	className: 'conv-response-holder',
	textTrack: null,
	players: [],
	playing: false,
	userResponses: [],
	initialize(options){
		_.bindAll(this, 'errorHandler');
		this.conversation = options.conversation;
		this.userResponses = _.filter(this.conversation, (c) => {
			return c.source === 'user'
		});
	},
	render: function(){
		this.$el.html(convResponseView({
			conversation: this.conversation
		}));
		//define player for this speech bubble and kick off process for audio
		let that = this;
		_.defer((function(){
			let allPlayers = this.$el.find('audio');
			allPlayers.each(function(){
				that.players.push($(this));
			});
			this.prepareAudios();
		}).bind(this));
		nm.vent.trigger('view:render', {playerIsActive: true});
		return this;
	},
	prepareAudios(){
		for(let i = 0; i < this.players.length; i++){
			if(this.userResponses[i]){
				let targetAudio = this.userResponses[i].audio;
				//set listener for audio ended
				this.players[i].on('ended', function(){
					this.playing = false;
					//re-enable speech bubble's play button on audio end
					this.players[i].parents('.conversation-speech').find('.play-conversation').removeClass('speech-playing').attr('disabled', false);
				}.bind(this));
			}
		}
	},
	//plays speech bubble when play button is clicked
	playConversationSpeech(event){
		event.preventDefault();
		let $ele = $(event.currentTarget);
		let $targetPlayer = $ele.parents('.conversation-speech').find('audio');
		let playerIndex = $('.conversation-speech').find('audio').index($targetPlayer);
		let targetAudio = null;
		if(this.userResponses[playerIndex]){
			targetAudio = this.userResponses[playerIndex].audio;
		}
		//if src wasn't set yet, set it and load it
		if(!$targetPlayer.attr('src') && targetAudio){
			//set audio src and load it
			$targetPlayer.attr('src', targetAudio);
			$targetPlayer[0].load();
		}
		if(!$ele.hasClass('speech-playing') && targetAudio){
			this.startSlideMedia($targetPlayer);
		}
	},
	startSlideMedia($currentPlayer = null){
		console.log('conv response audio start'); // eslint-disable-line no-console
		//should only be called once
		if(this.playing){
			console.log('conv response audio already playing'); // eslint-disable-line no-console
			return;
		}
		this.playing = true;
		$currentPlayer.parents('.conversation-speech').find('.play-conversation').addClass('speech-playing').attr('disabled', true);
		// for browsers that treat play as a Promise
		let asyncPlay = $currentPlayer[0].play();
		if(asyncPlay instanceof Promise){
			asyncPlay
				.then(() => {
					console.log('conv response audio started playing'); // eslint-disable-line no-console
				})
				.catch(error => {
					console.log('conv response audio play'); // eslint-disable-line no-console
					console.log(error); // eslint-disable-line no-console
				});
		}
	}
});
