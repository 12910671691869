import $ from 'jquery';
import AppBaseView from '../base/AppBaseView';
import primaryTextView from '../../templates/assignmentPlayer/primaryTextView.handlebars';

export default AppBaseView.extend({
	events: {
	},
	className: 'primary-text-holder',
	initialize: function(){
	},
	render: function(){
		this.$el.html(primaryTextView(this.collection.toJSON()));
		return this;
	},
	checkTextOverflow: function(){
		//check on typical slide template, desktop
		var textEl = $('.text-original');
		var textContainer = textEl.parents('.column');
		if(textEl.height() > textContainer.height()){
			$('.template-two-column').addClass('overflowing');
		}
		//check on typical slide template, mobile
		var slideEl = $('.template-two-column');
		var slideContainer = $('.assignment-player-slide');
		if(slideEl.height() > slideContainer.height()){
			slideContainer.addClass('overflowing');
		}
		//check on question template
		var questionEl = $('.take-question-form');
		var questionContainer = $('.template-single-question');
		if(questionEl.height() > questionContainer.height()){
			questionContainer.addClass('overflowing');
		}
	},
	afterRender: function(){
		this.checkTextOverflow();
	}
});
