var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "	<div class=\"question-content pr_conv_turns-question-content tts-target\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "	<div class=\"question-content tts-target\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "		<h3 class=\"practice-question-heading\">Speaking Practice Question</h2>\n"
    + ((stack1 = container.invokePartial(require("../questionImage.handlebars"),depth0,{"name":"questionImage","data":data,"indent":"\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("../questionContent.handlebars"),depth0,{"name":"questionContent","data":data,"indent":"\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../questionImage.handlebars"),depth0,{"name":"questionImage","data":data,"indent":"\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./stemPointUi.handlebars"),depth0,{"name":"stemPointUi","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./stemDragUi.handlebars"),depth0,{"name":"stemDragUi","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./stemFillUi.handlebars"),depth0,{"name":"stemFillUi","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./stemUi.handlebars"),depth0,{"name":"stemUi","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./practiceWritingUi.handlebars"),depth0,{"name":"practiceWritingUi","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./practiceConversationUi.handlebars"),depth0,{"name":"practiceConversationUi","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./writeSpeakTUi.handlebars"),depth0,{"name":"writeSpeakTUi","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./writeSpeakWUi.handlebars"),depth0,{"name":"writeSpeakWUi","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"enhanced-elp-question question-"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"elpContent") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + " question-not-answered\">\n	<span class=\"question-number\">"
    + alias2(__default(require("../../../helpers/indexPlusOne.js")).call(alias3,(data && lookupProperty(data,"index")),{"name":"indexPlusOne","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":54}}}))
    + "</span>\n	<i class=\"material-icons color-red\">close</i>\n"
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"elpContent") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"pr_conv_turns",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":1},"end":{"line":8,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"elpContent") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"pr_conv_turns",{"name":"compare","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":9,"column":2},"end":{"line":16,"column":14}}})) != null ? stack1 : "")
    + "		<div class=\"question-answer-input\" data-content-type="
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"elpContent") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + ">\n"
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"elpContent") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"stem_point",{"name":"compare","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":3},"end":{"line":20,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"elpContent") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"stem_drag",{"name":"compare","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":3},"end":{"line":23,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"elpContent") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"stem_fill",{"name":"compare","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":3},"end":{"line":26,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"elpContent") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"stem",{"name":"compare","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":3},"end":{"line":29,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"elpContent") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"pr_write",{"name":"compare","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":3},"end":{"line":32,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"elpContent") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"pr_conv_turns",{"name":"compare","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":3},"end":{"line":35,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"elpContent") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"pr_write_speak_t",{"name":"compare","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":3},"end":{"line":38,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"elpContent") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"pr_write_speak_w",{"name":"compare","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":3},"end":{"line":41,"column":15}}})) != null ? stack1 : "")
    + "		</div>\n	</div>\n	<input type=\"hidden\" name=\"questionId\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias3,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":44,"column":47},"end":{"line":44,"column":53}}}) : helper)))
    + "\"/>\n	<input type=\"hidden\" name=\"contentType\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"elpContent") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\"/>\n</li>\n";
},"usePartial":true,"useData":true});