var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"source") : depth0),"user",{"name":"compare","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":11,"column":12}}})) != null ? stack1 : "")
    + "	<div class=\"conversation-icon\">\n"
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"source") : depth0),"user",{"name":"compare","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":13,"column":2},"end":{"line":21,"column":14}}})) != null ? stack1 : "")
    + "	</div>\n	<div class=\"conversation-content\">\n		<div class=\"conv-primary-text\">\n			<p class=\"slide-text text-original\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"content") || (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"content","hash":{},"data":data,"loc":{"start":{"line":25,"column":39},"end":{"line":25,"column":50}}}) : helper)))
    + "</p>\n		</div>\n"
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"source") : depth0),"user",{"name":"compare","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":2},"end":{"line":29,"column":14}}})) != null ? stack1 : "")
    + "	</div>\n"
    + ((stack1 = __default(require("../../../helpers/compare.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"source") : depth0),"user",{"name":"compare","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":1},"end":{"line":36,"column":13}}})) != null ? stack1 : "")
    + "</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "<div class=\"conversation-speech user-speech conversation-response\">\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":6,"column":0},"end":{"line":10,"column":7}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return "<div class=\"conversation-speech conversation-complete conversation-response\">\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "<div class=\"conversation-speech conversation-response\">\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "		<i class=\"material-icons\">person</i>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":16,"column":2},"end":{"line":20,"column":9}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    return "		<i class=\"material-icons\">check</i>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "		<i class=\"material-icons\">face</i>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "		<button class=\"play-conversation light-blue-button icon-only\"><i class=\"material-icons\">play_arrow</i><i class=\"material-icons\">hourglass_empty</i></button>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<audio id=\"conv-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"index","hash":{},"data":data,"loc":{"start":{"line":33,"column":17},"end":{"line":33,"column":27}}}) : helper)))
    + "-audio\" src=\"\" preload=\"metadata\" autoplay=\"false\" crossorigin=\"anonymous\">\n		Your browser does not support the audio format.\n	</audio>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>Your responses:</p>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"conversation") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":38,"column":9}}})) != null ? stack1 : "");
},"useData":true});