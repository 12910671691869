var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<a href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"referenceSheet") || (depth0 != null ? lookupProperty(depth0,"referenceSheet") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"referenceSheet","hash":{},"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":30}}}) : helper)))
    + "\" target=\"_blank\" class=\"reference-sheet-button\"><i class=\"material-icons\">description</i> <span>Reference Sheet</span></a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"assignment-player-page\" class=\"bg-dark-blue\">\n\n	<div class=\"close-assignment-player\">\n		<span><i class=\"material-icons\">arrow_back</i> <span>Exit</span></span>\n	</div>\n\n	<div class=\"other-controls\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"referenceSheet") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":2},"end":{"line":12,"column":9}}})) != null ? stack1 : "")
    + "		<a href=\"#\" class=\"dictionary-button\"><i class=\"material-icons\">lightbulb_outline</i> <span>Dictionary</span></a>\n	</div>\n\n	<div id=\"nm-intro-holder\" class=\"holder-div\">\n\n	</div>\n\n	<div id=\"nm-pre-test-holder\" class=\"holder-div\">\n\n	</div>\n\n	<div id=\"nm-practice-holder\" class=\"holder-div\">\n\n	</div>\n\n	<div id=\"nm-grid-holder\" class=\"holder-div\">\n\n	</div>\n\n	<div id=\"nm-slide-holder\" class=\"holder-div\">\n		<div id=\"nm-assignment-progress\">\n\n		</div>\n		<div id=\"nm-slide\" class=\"slide\">\n\n		</div>\n		<div id=\"nm-assignment-controls\">\n\n		</div>\n	</div>\n\n	<div id=\"nm-outro-holder\" class=\"holder-div\">\n\n	</div>\n\n	<div id=\"nm-post-test-holder\" class=\"holder-div\">\n\n	</div>\n\n	<div id=\"nm-review-holder\" class=\"utility-hide\">\n\n	</div>\n\n	<div id=\"nm-definition-holder\" class=\"utility-hide\">\n\n	</div>\n\n	<div id=\"nm-dictionary-holder\">\n\n	</div>\n\n\n	<div class=\"bg-fade utility-hide\"></div>\n\n	<div class=\"player-loading utility-hide\">\n		<div>\n			<i class=\"material-icons animated infinite flash\">hourglass_empty</i>\n			<p>One moment&hellip;</p>\n		</div>\n	</div>\n</div>\n";
},"useData":true});