var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<div class=\"conversation-speech hide-conversation-speech animated user-speech\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"endOfConversation") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":8,"column":7}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "<div class=\"conversation-speech hide-conversation-speech animated conversation-complete\">\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "<div class=\"conversation-speech hide-conversation-speech animated\">\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "		<i class=\"material-icons\">person</i>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"endOfConversation") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":14,"column":2},"end":{"line":18,"column":9}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return "		<i class=\"material-icons\">check</i>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "		<i class=\"material-icons\">face</i>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "		<div class=\"conv-primary-text\">\n		</div>\n		<div class=\"conv-text-track\">\n		</div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "		<div class=\"elp-hidden-conversation\">\n			<p><em>Listen</em></p>\n		</div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<input class=\"conversation-speech-input\" type=\"hidden\" name=\"spokenResponse\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"transcript") || (depth0 != null ? lookupProperty(depth0,"transcript") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"transcript","hash":{},"data":data,"loc":{"start":{"line":43,"column":85},"end":{"line":43,"column":99}}}) : helper)))
    + "\" />\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isUser") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":9,"column":7}}})) != null ? stack1 : "")
    + "	<div class=\"conversation-icon\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isUser") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":11,"column":2},"end":{"line":19,"column":9}}})) != null ? stack1 : "")
    + "	</div>\n	<div class=\"conversation-content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"questionTextRendering") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":22,"column":2},"end":{"line":33,"column":9}}})) != null ? stack1 : "")
    + "		<button class=\"play-conversation light-blue-button icon-only\"><i class=\"material-icons\">play_arrow</i><i class=\"material-icons\">hourglass_empty</i></button>\n	</div>\n	<audio id=\"conv-"
    + alias4(((helper = (helper = lookupProperty(helpers,"conversationIndex") || (depth0 != null ? lookupProperty(depth0,"conversationIndex") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"conversationIndex","hash":{},"data":data,"loc":{"start":{"line":37,"column":17},"end":{"line":37,"column":38}}}) : helper)))
    + "-audio\" src=\"\" preload=\"metadata\" autoplay=\"false\" crossorigin=\"anonymous\">\n		<track class=\"pr_conv_turns-slide-audio-captions-normal\" kind=\"captions\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"vttUrl") || (depth0 != null ? lookupProperty(depth0,"vttUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"vttUrl","hash":{},"data":data,"loc":{"start":{"line":39,"column":80},"end":{"line":39,"column":90}}}) : helper)))
    + "\" srclang=\"en\" label=\"Normal\" default>\n		Your browser does not support the audio format.\n	</audio>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isUser") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":1},"end":{"line":44,"column":8}}})) != null ? stack1 : "")
    + "</div>\n<div class=\"conversation-speech-loading hide-conversation-speech-loading\">\n	<i class=\"material-icons animated pulse infinite\">more_horiz</i>\n</div>\n";
},"useData":true});