var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./slideTemplates/questionTemplate.handlebars"),depth0,{"name":"questionTemplate","data":data,"indent":"\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./slideTemplates/twoColumnTemplate.handlebars"),depth0,{"name":"twoColumnTemplate","data":data,"indent":"\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<track class=\"slide-audio-captions-normal\" kind=\"captions\" src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"captionLinks") : depth0)) != null ? lookupProperty(stack1,"normal") : stack1), depth0))
    + "\" srclang=\"en\" label=\"Normal\" default>\n		<track class=\"slide-audio-captions-slow\" kind=\"captions\" src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"captionLinks") : depth0)) != null ? lookupProperty(stack1,"slow") : stack1), depth0))
    + "\" srclang=\"en\" label=\"Slow\">\n		<track class=\"slide-audio-captions-fast\" kind=\"captions\" src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"captionLinks") : depth0)) != null ? lookupProperty(stack1,"fast") : stack1), depth0))
    + "\" srclang=\"en\" label=\"Fast\">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"assignment-player-slide\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"slide") : depth0)) != null ? lookupProperty(stack1,"questions") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":6,"column":8}}})) != null ? stack1 : "")
    + "</div>\n<audio id=\"slide-"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"slide") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "-audio\" class=\"slide-audio\" preload=\"metadata\" autoplay=\"false\" crossorigin=\"anonymous\">\n	<source class=\"slide-audio-mp3\" src=\"\" type=\"audio/mp3\"></source>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"slide") : depth0)) != null ? lookupProperty(stack1,"captions") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":1},"end":{"line":14,"column":8}}})) != null ? stack1 : "")
    + "	Your browser does not support the audio format.\n</audio>\n";
},"usePartial":true,"useData":true});