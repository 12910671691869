import $ from 'jquery';
import _ from 'underscore';
import AppBaseView from '../base/AppBaseView';
import stemPointView from '../../templates/questions/enhancedElp/stemPointView.handlebars'

export default AppBaseView.extend({
	events: {
		'click .stem-point-option': 'selectStemPointOption'
	},
	className: 'stem-point-holder',
	initialize(options){
		this.contentOptions = options.contentOptions;
		this.question = options.question;
	},
	render(){
		this.$el.html(stemPointView({
			contentOptions: this.prepareContentOptions()
		}));
		return this;
	},
	//if response is present, add it to appropriate content option
	prepareContentOptions(){
		let response = this.question.response;
		let responseOption = null;
		if(response){
			responseOption = _.findWhere(this.contentOptions, {key: response.response.enhanced});
			responseOption.isResponse = true;
		}
		return this.contentOptions;
	},
	selectStemPointOption(event){
		event.preventDefault();
		let hasResponse = _.findWhere(this.contentOptions, {isResponse: true});
		//only allow user point interaction if the question hasn't been answered yet
		if(!hasResponse){
			let $allOptions = $('.stem-point-option');
			let $selectedOption = $(event.currentTarget);
			let $stemPointInput = $('.stem-point-input');
			//show that an option was clicked
			$allOptions.removeClass('selected-stem-point');
			$selectedOption.addClass('selected-stem-point');
			//update the hidden input with the selected option's key
			$stemPointInput.val($selectedOption.data('key'));
		}
	}
});
