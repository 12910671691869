/**
 * AssignmentPlayerView
 * Container for the "Assignment Player" track
 */
import Backbone from 'backbone';
import AppBaseView from '../base/AppBaseView';
import EventStatus from '../../models/EventStatus';
import IntroView from '../assignmentPlayer/IntroView';
import DictionaryView from '../assignmentPlayer/DictionaryView';
import DefinitionView from '../assignmentPlayer/DefinitionView';
import ReviewView from '../assignmentPlayer/ReviewView';
import ExpectationsView from '../assignmentPlayer/ExpectationsView';
import QinboxView from '../assignmentPlayer/QinboxView';
import GridView from '../assignmentPlayer/GridView';
import SlideView from '../assignmentPlayer/SlideView';
import AssignmentProgressView from '../assignmentPlayer/AssignmentProgressView';
import AssignmentControlsView from '../assignmentPlayer/AssignmentControlsView';
import OutroView from '../assignmentPlayer/OutroView';
import nm from '../../nm';
import assignmentPlayerView from '../../templates/layouts/assignmentPlayerView.handlebars';
import _ from 'underscore';

export default AppBaseView.extend({
	events: {
		'click .close-assignment-player': 'closeAssignmentPlayer',
		'click .expectations-button': 'showExpectations',
		'click .dictionary-button': 'showDictionary',
		'click .bg-fade': 'clickedPlayerCover',
		'click #nm-definition-holder': 'clickedDefinitionHolder',
		'click #nm-review-holder': 'clickedReviewHolder'
	},
	className: 'assignment-player-holder',
	pageTitle: '',
	bundleId: null,
	assignedBundleId: null,
	slides: null, //every slide in assignment/primer
	slide: null, //the current slide
	progress: 0, //percentage of slides viewed
	teacherReview: false, //flag if a teacher is viewing a student's slide/bundle (i.e. reviewing a question from Primer Results)
	notStudent: false, //flag for if user is a student or not
	userId: 0, //current user id, modified by setupUser
	encounters: [], //Object[], vocab/word encounters in Primer representation
	adaptiveAssessment: null, //pre-test assessment
	adaptiveQuestions: [], //questions that a student needs to answer
	adaptiveResponses: [], //does the student have responses for the adaptive assessment
	postTestAssessment: null, //prep the post test assessment like other assessments
	postTestQuestions: [], //prep the post test questions like other assessments
	postTestResponses: [], //prep the post test responses like other assessments

	initialize(options){
		_.bindAll(this, 'errorHandler', 'setSynced');
		this.fakeLocation = options.location;
		this.navigate = (path) => nm.router.navigate(path);
		//if params provided directly, use 'em, don't use from URL
		if(options.params){
			this.teacherReview = true;
			this.bundleId = options.bundleId;
			this.assignedBundleId = options.assignedBundleId;
			this.studentId = options.studentId;
			this.params = options.params;
		}
		//load necessary data for whole "assignment player" track
		this.model.fetch({
			success: this.setSynced,
			error: this.errorHandler
		});
		this.previousClicked = false;
		this.listenTo(this.model, 'sync', this.render);
		this.listenTo(nm.vent, 'assignment_player:next_slide', this.nextSlide);
		this.listenTo(nm.vent, 'assignment_player:previous_slide', this.previousSlide);
		this.listenTo(nm.vent, 'assignment_player:load', this.load);
		this.listenTo(nm.vent, 'model:addResponse', this.addResponse);
		this.listenTo(nm.vent, 'status:success', this.assignmentSequenceStatusSuccess);
		this.listenTo(nm.vent, 'player:exit', this.closeAssignmentPlayer);
		//vocab tracking events, used in Primer's slide representation and grid/comic representation
		this.listenTo(nm.vent, 'vocab:encounter', (word) => {
			this.encounters.push(word);
		});
		this.listenTo(nm.vent, 'vocab:reset', () => {
			this.encounters = [];
		});
		this.listenTo(nm.vent, 'vocab:submit', () => {
			//if user is a student and the assignedBundle is set and encounters is not empty
			if(nm.user.get('type') === 'student' && this.assignedBundleId && this.encounters.length){
				let eventStatus = new EventStatus({
					eventKey: 'vocab_encounter',
					assignedBundleId: this.assignedBundleId,
					wordIds: this.encounters.map(item => item.id)
				});
				eventStatus.save({}, {
					success: () => null,
					error: this.errorHandler
				});
			}
			//reset
			this.encounters = [];
		});
	},
	/**
	 * Manually update model with new question response, so response is available if Back button used
	 * @return (void)
	 */
	addResponse(response){
		if(response){
			//set up the response we just received from submitting a question
			var responseResult = response.result;
			var adjective = this.comfortNumberToAdjective(Number(responseResult.comfort));
			responseResult.adjective = adjective;
			//get existing model's responses
			var assessmentResponses = this.model.toJSON().assessmentResponses;
			//add the new response
			assessmentResponses.push(responseResult);
			//set the model silently so nothing re-renders
			this.model.set({
				assessmentResponses: assessmentResponses
			}, {silent: true});
			//re-run setupSlides now that response has been updated,
			//this will update the question at the slide with the response
			this.setupSlides();
		}
	},
	render(){
		if(this.model.synced){
			this.setupUser();
			this.setupBundleIds();
			this.setupSlides();
			this.setupAdaptiveProperties();
			this.setupPostTest();
			//grab reference sheet for this assignment
			this.referenceSheet = null;
			if(this.model.get('bundles')[0].referenceSheet){
				this.referenceSheet = this.model.get('bundles')[0].referenceSheet;
			}
			this.$el.html(assignmentPlayerView({
				referenceSheet: this.referenceSheet
			}));
			this.loadState();
			if(!nm.isMobile && _.has(nm.user.toJSON(), 'type')){
				if(nm.user.get('type') === 'staff'){
					this.showLiveChat();
				}
			}else{
				this.hideLiveChat();
			}
			//set to populate html head's title tag
			this.pageTitle = `Assignment "${this.model.toJSON().result.title}" | Positive Learning`;
			nm.vent.trigger('view:render', {playerIsActive: true});
			if(nm.user.get('type') === 'staff'){
				nm.vent.trigger('helpButton:show', {
					title: 'Learn about ELL-Specific Supports in the Lesson Primer',
					url: '/ell-specific-support'
				});
			}
		}
		return this;
	},
	load(paramKey){ 
		let params = this.getSearchParameters(this.fakeLocation.hash);
		let route = `assignment-player/${this.model.get('id')}?back=${params.back}&key=${paramKey}`;
		this.navigate(route, {trigger: false});
		// ga('send', {
		// 	hitType: 'pageview',
		// 	page: '/' + location.hash
		// });
		this.loadState();
	},
	loadState(){
        //set properties for state using params from URL
		var params = this.getSearchParameters(this.fakeLocation.hash);
		if(this.teacherReview){
			params = this.params;
		}
		//set defaults
		if(!params.key){
			params.key = 'intro';
		}
		//close existing views
		this.closeViewSet(this.views);
		//remove by default since it is outside of this view
		this.hideDynamicHeader();
		//all "states" should remove this class, except grid state, which will "overrule" this in a sec...
		this.$el.find('#nm-grid-holder').addClass('hide-grid');
		this.$el.find('#assignment-player-page').removeClass('grid-mode');
		// var url = Backbone.history.getFragment();
		// this.trackPageView(url);
		let slideId;
		let slideIndex;
		switch(params.key){
			case 'intro':
				this.views = {
					intro: new IntroView({
						model: this.model,
						//if student has achieved mastery on all words related to vocab assessment questions
						practiceReady: !this.adaptiveQuestions.length,
						preTestTaken: this.adaptiveResponses.length
					}),
					dictionary: new DictionaryView({
						collection: new Backbone.Collection(_.where(this.model.get('words'), {
							'type': 'primary'
						}))
					}),
					definition: new DefinitionView({
						bundleId: this.model.toJSON().bundles[0],
						words: this.model.get('words'),
						review: this.model.get('review'),
						collection: new Backbone.Collection(this.model.get('words'))
					}),
					review: new ReviewView({
						review: this.model.get('review'),
						words: this.model.get('words')
					}),
					expectations: new ExpectationsView({
						model: new Backbone.Model(this.getAssignmentProperty())
					})
				};
				this.assignSubViews({
					'#nm-intro-holder': this.views.intro,
					'#nm-dictionary-holder': this.views.dictionary,
					'#nm-definition-holder': this.views.definition,
					'#nm-review-holder': this.views.review,
					'#nm-expectations-holder': this.views.expectations
				});
				this.manageHolderStates(['#nm-intro-holder']);
				break;
			case 'pre-test':
				//send event status when the student finished the pre-test
				if(nm.user.get('type') === 'student'){
					this.saveAssignmentSequenceStatus();
				}
				this.views = {
					preTest: new QinboxView({
						model: this.model,
						assessment: this.adaptiveAssessment,
						questions: this.adaptiveQuestions,
						key: params.key
					}),
					dictionary: new DictionaryView({
						collection: new Backbone.Collection(_.where(this.model.get('words'), {
							'type': 'primary'
						}))
					}),
					definition: new DefinitionView({
						bundleId: this.model.toJSON().bundles[0],
						words: this.model.get('words'),
						review: this.model.get('review'),
						collection: new Backbone.Collection(this.model.get('words'))
					}),
					review: new ReviewView({
						review: this.model.get('review'),
						words: this.model.get('words')
					})
				};
				this.assignSubViews({
					'#nm-pre-test-holder': this.views.preTest,
					'#nm-dictionary-holder': this.views.dictionary,
					'#nm-definition-holder': this.views.definition,
					'#nm-review-holder': this.views.review
				});
				this.manageHolderStates(['#nm-pre-test-holder']);
				break;
			case 'pre-test-results':
				this.views = {
					preTestResults: new QinboxView({
						model: this.model,
						assessment: this.adaptiveAssessment,
						questions: this.adaptiveQuestions,
						slides: this.slides,
						key: params.key
					}),
					dictionary: new DictionaryView({
						collection: new Backbone.Collection(_.where(this.model.get('words'), {
							'type': 'primary'
						}))
					}),
					definition: new DefinitionView({
						bundleId: this.model.toJSON().bundles[0],
						words: this.model.get('words'),
						review: this.model.get('review'),
						collection: new Backbone.Collection(this.model.get('words'))
					}),
					review: new ReviewView({
						review: this.model.get('review'),
						words: this.model.get('words')
					})
				};
				this.assignSubViews({
					'#nm-pre-test-holder': this.views.preTestResults,
					'#nm-dictionary-holder': this.views.dictionary,
					'#nm-definition-holder': this.views.definition,
					'#nm-review-holder': this.views.review
				});
				this.manageHolderStates(['#nm-pre-test-holder']);
				break;
			case 'practice':
				//send event status when the student finished the pre-test
				if(nm.user.get('type') === 'student'){
					this.saveAssignmentSequenceStatus();
				}
				//before proceeding, determine if there's anything to even practice
				let hasPractice = false;
				let writeAboutQuestions = _.where(this.model.get('questions'), {type: 'write_about'});
				let confidenceAssessment = _.findWhere(this.model.get('assessments'), {type: 'p2p_test'}) || [];
				let foundInAssessment = _.intersection(_.pluck(writeAboutQuestions, 'id'), confidenceAssessment.questionIds);
				// if there are write about questions found in the confidence assessment, we've got practice material!
				if(foundInAssessment.length){
					hasPractice = true;
				}
				//if nothing to practice, just skip to Outro
				if(!hasPractice){
					nm.vent.trigger('assignment_player:load', 'outro');
					break;
				}
				this.views = {
					practice: new QinboxView({
						model: this.model,
						key: params.key
					}),
					dictionary: new DictionaryView({
						collection: new Backbone.Collection(_.where(this.model.get('words'), {
							'type': 'primary'
						}))
					}),
					definition: new DefinitionView({
						bundleId: this.model.toJSON().bundles[0],
						words: this.model.get('words'),
						review: this.model.get('review'),
						collection: new Backbone.Collection(this.model.get('words'))
					}),
					review: new ReviewView({
						review: this.model.get('review'),
						words: this.model.get('words')
					})
				};
				this.assignSubViews({
					'#nm-practice-holder': this.views.practice,
					'#nm-dictionary-holder': this.views.dictionary,
					'#nm-definition-holder': this.views.definition,
					'#nm-review-holder': this.views.review
				});
				this.manageHolderStates(['#nm-practice-holder']);
				break;
			case 'grid':
				this.views = {
					grid: new GridView({
						model: this.model,
						words: this.model.get('words')
					}),
					dictionary: new DictionaryView({
						collection: new Backbone.Collection(_.where(this.model.get('words'), {
							'type': 'primary'
						}))
					}),
					definition: new DefinitionView({
						bundleId: this.model.toJSON().bundles[0],
						words: this.model.get('words'),
						review: this.model.get('review'),
						collection: new Backbone.Collection(this.model.get('words'))
					}),
					review: new ReviewView({
						review: this.model.get('review'),
						words: this.model.get('words')
					})
				};
				this.assignSubViews({
					'#nm-grid-holder': this.views.grid,
					'#nm-dictionary-holder': this.views.dictionary,
					'#nm-definition-holder': this.views.definition,
					'#nm-review-holder': this.views.review
				});
				this.manageHolderStates(['#nm-grid-holder']);
				this.$el.find('#nm-grid-holder').removeClass('hide-grid');
				this.$el.find('#assignment-player-page').addClass('grid-mode');
				break;
			case 'slide':
				//TODO: we shouldn't reload all subviews
				slideId = Number(params.slide);
				this.slide = _.findWhere(this.slides, {id: slideId});
				slideIndex = _.findIndex(this.slides, {id: slideId});
				//if student views any slide beyond the 3rd slide, save 'assignment_sequence' event status
				if(nm.user.get('type') === 'student' && slideIndex >= 2){
					this.saveAssignmentSequenceStatus();
				}
				this.setProgress(slideIndex);
				this.hasAudio = true;
				if(!this.slide.audio){
					this.hasAudio = false;
				}

				this.views = {
					slide: new SlideView({
						model: new Backbone.Model(this.slide),
						words: this.model.get('words'),
						assessments: this.model.get('assessments'),
						students: this.model.get('students')
					}),
					progress: new AssignmentProgressView({
						progress: this.progress
					}),
					controls: new AssignmentControlsView({
						isReview: false, // where could we get this from?
						hasAudio: this.hasAudio,
						previousClicked: this.previousClicked,
						currentSlide: new Backbone.Model(this.slide),
						assessments: this.model.get('assessments')
					}),
					definition: new DefinitionView({
						words: this.model.get('words'),
						review: this.model.get('review'),
						collection: new Backbone.Collection(this.model.get('words'))
					}),
					review: new ReviewView({
						review: this.model.get('review'),
						words: this.model.get('words')
					}),
					dictionary: new DictionaryView({
						collection: new Backbone.Collection(_.where(this.model.get('words'), {
							'type': 'primary'
						}))
					}),
					expectations: new ExpectationsView({
						model: new Backbone.Model(this.getAssignmentProperty())
					})
				};
				this.assignSubViews({
					'#nm-slide': this.views.slide,
					'#nm-assignment-progress': this.views.progress,
					'#nm-assignment-controls': this.views.controls,
					'#nm-definition-holder': this.views.definition,
					'#nm-review-holder': this.views.review,
					'#nm-dictionary-holder': this.views.dictionary,
					'#nm-expectations-holder': this.views.expectations
				});
				this.manageHolderStates(['#nm-slide-holder']);
				break;
			case 'outro':
				this.views = {
					outro: new OutroView({
						model: this.model
					}),
					dictionary: new DictionaryView({
						collection: new Backbone.Collection(_.where(this.model.get('words'), {
							'type': 'primary'
						}))
					}),
					definition: new DefinitionView({
						bundleId: this.model.toJSON().bundles[0],
						words: this.model.get('words'),
						review: this.model.get('review'),
						collection: new Backbone.Collection(this.model.get('words'))
					}),
					review: new ReviewView({
						review: this.model.get('review'),
						words: this.model.get('words')
					}),
					expectations: new ExpectationsView({
						model: new Backbone.Model(this.getAssignmentProperty())
					})
				};
				this.assignSubViews({
					'#nm-outro-holder': this.views.outro,
					'#nm-dictionary-holder': this.views.dictionary,
					'#nm-definition-holder': this.views.definition,
					'#nm-review-holder': this.views.review,
					'#nm-expectations-holder': this.views.expectations
				});
				this.manageHolderStates(['#nm-outro-holder']);
				break;
			case 'post-test':
				this.views = {
					postTest: new QinboxView({
						model: this.model,
						assessment: this.postTestAssessment,
						questions: this.postTestQuestions,
						key: params.key
					})
				};
				this.assignSubViews({
					'#nm-post-test-holder': this.views.postTest
				});
				this.manageHolderStates(['#nm-post-test-holder']);
				// hide "other controls" on Post Test only
				this.$el.find('.other-controls').hide();
				break;
			case 'teacher-review':
				//this 'teacher-review' case is a modified version of 'slide' case, used in reports for displaying question results
				slideId = Number(params.slide);
				this.slide = _.findWhere(this.slides, {id: slideId});
				slideIndex = _.findIndex(this.slides, {id: slideId});
				this.hasAudio = false;

				this.views = {
					slide: new SlideView({
						model: new Backbone.Model(this.slide),
						words: this.model.get('words'),
						assessments: this.model.get('assessments'),
						students: this.model.get('students'),
						showHeader: true
					})
				};
				this.assignSubViews({
					'#nm-slide': this.views.slide
				});
				this.manageHolderStates(['#nm-slide-holder']);
				break;
			default:
				break;
		}
	},
	// hides all "holder" divs in assignment player view, excluding those passed in
	manageHolderStates(holdersToShow){
		this.$el.find('.holder-div').addClass('hide-holder-div');
		for(let holder of holdersToShow){
			this.$el.find(holder).removeClass('hide-holder-div');
		}
	},
	showDictionary(event){
		event.preventDefault();
		this.stopTextToSpeechAudio();
		nm.vent.trigger('words:list');
	},
	showExpectations(event){
		event.preventDefault();
		nm.vent.trigger('lesson:expectations');
	},
	randomQuestionId(){
		let p2pAssessments;
		//if student, find THAT student's p2p assessment. For teachers, just get all p2p assessment questions.
		if(nm.user.get('type') === 'student'){
			let studentAssignedBundle = _.findWhere(this.model.get('assignedBundles'), {studentId: this.userId});
			let originBundle = _.findWhere(this.model.get('bundles'), {id: studentAssignedBundle.bundleId});
			p2pAssessments = _.filter(this.model.get('assessments'), function(assessment){
				return _.contains(originBundle.assessmentIds, assessment.id) && assessment.type === 'p2p_test';
			}, this);
		}else{
			p2pAssessments = _.filter(this.model.get('assessments'), function(assessment){
				return assessment.type === 'p2p_test';
			}, this);
		}
		//nab the p2p question from the p2p assessment(s)
		let p2pAssessmentQuestionIds = _.flatten(_.pluck(p2pAssessments, 'questionIds'));
		// filter out any "write_about" type question(s) that might be present
		let allowedQuestions = _.pluck(_.filter(this.model.get('questions'), function(q){
			return q.type !== 'write_about';
		}), 'id');
		p2pAssessmentQuestionIds = _.intersection(p2pAssessmentQuestionIds, allowedQuestions);
		//randomly select and return one of the p2p question IDs
		let randomP2pQuestionId = _.sample(p2pAssessmentQuestionIds);
		return randomP2pQuestionId;
	},
	/**
	 * Go to next slide by index, update the URL with the slide id
	 * @return (void)
	 */
	nextSlide(){
        var next = 0;
		var params = this.getSearchParameters(this.fakeLocation.hash);
		var route = 'assignment-player/' + this.model.get('id') + '?back=' + params.back;
		var slideId = 0;
		//if we are on intro from first slide, set next to first index
		if(params.key === 'intro' && this.previousClicked){
			next = 0;
		}else if(this.slide){
			next = _.findIndex(this.slides, {id: this.slide.id}) + 1;
		}
		//if we hit the last slide, go to the outro
		if(next >= this.slides.length){
			route += '&key=outro';
			slideId = 'Outro';
		}else{
			route += `&key=slide&slide=${this.slides[next].id}`
			slideId = this.slides[next].id;
		}
		this.fakeLocation.hash = route;
		this.navigate(route, {trigger: false});
		// ga('send', {
		// 	hitType: 'pageview',
		// 	page: '/' + location.hash
		// });
		var currentTimestamp = this.getTimestampSeconds();
		var currentDate = new Date();
		var readableDate = currentDate.getHours() + ':' + currentDate.getMinutes();
		// ga('send', {
		// 	hitType: 'event',
		// 	eventCategory: 'Assignment',
		// 	eventAction: 'Assignment Slide Viewed',
		// 	eventLabel: 'User ID ' + nm.user.get('id') + ' viewed slide id ' + slideId + ' from Assignment ' + this.model.get('id') + ' at ' + readableDate + ' (' + currentTimestamp + ')'
		// });
		this.previousClicked = false;
		this.loadState();
	},
	previousSlide(isReview = false){
		this.previousClicked = true;
		var previous = 0;
        var assignmentId = this.model.get('id');
		var params = this.getSearchParameters(this.fakeLocation.hash);
		var path = 'assignment-player';
		if(isReview){
			path = 'assignment-review';
		}
		var constantParams = this.getConstantParams(params);
		var route = `${path}/${assignmentId}?${constantParams}`;
		var slideId = 0;
		if(this.slide){
			previous = _.findIndex(this.slides, {id: this.slide.id}) - 1;
		}
		//if we are on the first slide, go to the intro
		if(previous === -1){
			route += '&key=intro';
			slideId = 'Intro';
		}else{
			route += `&key=slide&slide=${this.slides[previous].id}`
			slideId = this.slides[previous].id;
		}
		this.fakeLocation.hash = route;
		this.navigate(route, {trigger: false});
		// ga('send', {
		// 	hitType: 'pageview',
		// 	page: '/' + location.hash
		// });
		var currentTimestamp = this.getTimestampSeconds();
		var currentDate = new Date();
		var readableDate = currentDate.getHours() + ':' + currentDate.getMinutes();
		// ga('send', {
		// 	hitType: 'event',
		// 	eventCategory: 'Assignment',
		// 	eventAction: 'Assignment Slide Back',
		// 	eventLabel: 'User ID ' + nm.user.get('id') + ' is returning to slide id ' + slideId + ' from Assignment ' + this.model.get('id') + ' at ' + readableDate + ' (' + currentTimestamp + ')'
		// });
		this.loadState();
	},
	closeAssignmentPlayer(event){
		if(event){
			event.preventDefault();
		}
        var targetUrl;
		var backUrl = this.extractBackParameter(this.fakeLocation.hash);
		var userType = nm.user.get('type');
		//set some defaults based on user type
		if(userType === 'student'){
			targetUrl = 'learning-center';
		}else{
			targetUrl = 'classrooms';
		}
		//utilize the back GET parameter if present
		if(backUrl){
			targetUrl = this.decodeBackUrl(backUrl);
		}
		this.navigate(targetUrl, {trigger: true});
	},
	setupUser(){
		this.userId = nm.user.get('id');
		//if user is not a student, set flag to true
		if(nm.user.get('type') !== 'student'){
			this.notStudent = true;
		}
		//if this is TeacherReview, we want the player to think this is a student
		if(this.teacherReview){
			this.notStudent = false;
			this.userId = this.studentId;
		}
		//if student, set student properties for all subviews and load state within player
		if(nm.user.get('type') === 'student' && this.model.get('students').length){
			//if student, student set should only include the current student
			let elpLevel = _.where(this.model.get('students'), {id: nm.user.get('id')})[0].elpLevel;
			//set ELP level
			nm.user.set('elpLevel', elpLevel);
			//determine is student has exited ELL program or is native speaker
			//set exited/native with ELP level
			nm.user.set('exited', nm.exitedStudent(elpLevel));
		}
	},
	setupAdaptiveProperties(){
		if(nm.user.get('exited')){
			//reset
			this.adaptiveResponses = [];
			this.adaptiveQuestions = [];
			//get questions for pre-test which is vocab_test
			this.adaptiveAssessment = _.findWhere(this.model.get('assessments'), {type: 'vocab_test'});
			let questions = _.filter(this.model.get('questions'),
				q => this.adaptiveAssessment.questionIds.indexOf(q.id) > -1);

			this.adaptiveResponses = _.where(this.model.get('assessmentResponses'), {
				assessmentId: this.adaptiveAssessment.id
			});
			let keyedResponses = _.indexBy(this.adaptiveResponses, 'questionId');
			let wordTracking = _.indexBy(this.model.get('wordTracking'), 'id');
			//whether to skip the question, omitted from adpative question set
			let skip;
			let w;
			for(let q of questions){
				if(this.adaptiveResponses.length){
					if(keyedResponses[q.id]){
						this.adaptiveQuestions.push(q);
					}
				}else{
					skip = true;
					for(w of q.wordIds){
						if(wordTracking[w] && wordTracking[w].mastery === false){
							skip = false;
						}
					}
					//if no word associations exist or if a response exists for question
					if(!skip || !q.wordIds.length){
						this.adaptiveQuestions.push(q);
					}
				}
			}
		}
	},
	setupPostTest(){
		this.postTestAssessment = _.findWhere(this.model.get('assessments'), {type: 'post_test'});
		this.postTestQuestions = _.filter(this.model.get('questions'),
			q => this.postTestAssessment.questionIds.indexOf(q.id) > -1);

		this.postTestResponses = _.where(this.model.get('assessmentResponses'), {
			assessmentId: this.postTestAssessment.id
		});
	},
	/**
	 * Determine the Bundle assigned to the current student
	 * @return (void)
	 */
	setupBundleIds(){
		//usually, just use the bundle that belongs to the logged in user
		let matchedBundle = _.findWhere(this.model.get('assignedBundles'), {
			'studentId': nm.user.get('id')
		});
		//if teacher is reviewing, use provided bundle ID instead
		if(this.teacherReview){
			matchedBundle = {
				bundleId: this.bundleId,
				id: this.assignedBundleId
			}
		}
		this.bundleId = matchedBundle.bundleId;
		this.assignedBundleId = matchedBundle.id;
	},
	//determine and set all needed properties on every single slide
	setupSlides(){
		let assessments = this.model.get('assessments');
		let assignmentCompleted = false;
		let slides = _.where(this.model.get('slides'), {'bundleId': this.bundleId});

		//look at slides with questions, and look if any are of type "enhanced_elp",
		//if so, check for MediaRecorder support in browser, and remove slide if
		//support is not available, to prevent student from being stuck on a question
		//they lack the tech to answer
		slides = _.filter(slides, function(slide){
			if(slide.questionId){
				let question = _.find(this.model.get('questions'), {id: slide.questionId});
				if(question.type === 'enhanced_elp'){
					if('MediaRecorder' in window){
						// MediaRecorder is supported!
					}else{
						return false;
					}
				}
			}
			return true;
		}, this);

		//set (and sort) all of the slides
		this.slides = _.sortBy(slides, 'sortOrder');

		//TEMP: The following block will remove slides for ELL kids only,
		//and thus they will not encounter any "write_about"-type questions.
		//Non-ELL kids will still see "write_about"-type questions in "practice" subview.
		//NOTE: This block could be deleted if lesson content removed all
		//write about Qs from lesson slides
		let questionsById = _.indexBy(this.model.get('questions'), 'id');
		this.slides = _.reject(this.slides, function(slide){
			if(slide.questionId && questionsById[slide.questionId].type === 'write_about'){
				return true;
			}
		});

		//check if assignment was completed, if so, set property for preventing question submissions
		let assignedBundle = _.findWhere(this.model.get('assignedBundles'), {id: this.assignedBundleId});
		if(assignedBundle && _.contains(assignedBundle.events, 'assignment_complete')){
			assignmentCompleted = true;
		}

		for(let slide of this.slides){
			//properties for every slide
			slide.assignedBundleId = this.assignedBundleId;
			slide.assignmentCompleted = assignmentCompleted;
			slide.notStudent = this.notStudent;
			//properties specific to slides that have a question or an assessment
			if(slide.questionId){
				this.setupSlideQuestion(slide);
			}else if(slide.assessmentId){
				this.setupSlideAssessment(slide);
			}
		}
	},
	setupSlideQuestion(slide){
		let assessments = this.model.get('assessments');
		//determine and set assessment that goes with this slide, based on question ID
		let matchedAssessment = _.find(assessments, function(assessment){
			if(assessment.type !== 'post_test'){
				return assessment.questionIds.indexOf(slide.questionId) > -1;
			}
			return false;
		});
		if(matchedAssessment){
			slide.matchedAssessment = matchedAssessment;
		}

		let slideQuestion = _.findWhere(this.model.get('questions'), {id: slide.questionId});
		slide.questionType = slideQuestion.type;

		//handle slide's attached question correctly depending on type
		switch(slideQuestion.type){
			case 'enhanced_elp':
				this.setupEnhancedElp(slide, slideQuestion);
				break;
			default:
				this.setupDefaultType(slide, slideQuestion);
				break;
		}
	},
	setupSlideAssessment(slide){
		let assessments = this.model.get('assessments');
		//determine and set assessment that goes with this slide, based on assessment ID
		let matchedAssessment = _.find(assessments, function(assessment){
			if(assessment.type === 'p2p_test'){
				return assessment.id === slide.assessmentId;
			}
			return false;
		});
		if(matchedAssessment){
			slide.matchedAssessment = matchedAssessment;
		}
		slide.isAssessment = true;

		//handle slide's attached assessment correctly depending on assessment type
		switch(slide.matchedAssessment.type){
			case 'p2p_test':
				this.prepareP2pAssessment(slide);
				break;
			default:
				break;
		}
	},
	setupDefaultType(slide, question){
		//prepare needed properties for this slide's question
		let preparedQuestion = this.prepareQuestion(question);
		preparedQuestion.matchedAssessmentId = slide.matchedAssessment.id;
		preparedQuestion.bundleId = slide.bundleId;
		preparedQuestion.assignedBundleId = slide.assignedBundleId;

		//set some additional properties on the slide itself
		slide.questions = [{
			question: [preparedQuestion.question], //always array, template iterates over questions array
			studentResponse: preparedQuestion.studentResponse,
			notStudent: preparedQuestion.notStudent,
			isAssessment: false,
			onDisplay: true,
			matchedAssessmentId: slide.matchedAssessment.id,
			bundleId: this.model.get('bundleId'),
			assignedBundleId: slide.assignedBundleId
		}];
		slide.studentResponse = preparedQuestion.studentResponse;
		slide.isAssessment = false;
		slide.currentQuestionId = preparedQuestion.question.id;
	},
	setupEnhancedElp(slide, question){
		let userElp = 1; //default elp level, will be used if staff user is viewing
		let students = this.model.get('students');

		//if student user is viewing, use THAT student's ELP level
		if(_.first(students) && !this.teacherReview){
			userElp = _.first(students).elpLevel;
		}
		if(this.teacherReview){
			userElp = _.findWhere(this.model.get('students'), {id: this.studentId}).elpLevel;
		}

		//set question content based on ELP level
		question.elpContent = _.find(question.content, (content) => {
			const highestQuestionContent = Number(_.max(_.pluck(question.content, 'levelHigh')));
			const lowestQuestionContent = Number(_.min(_.pluck(question.content, 'levelLow')));
			//either select the content that is in the current student's range, or,
			//select the highest available content when the student level is high outside question's range
			if(
				(content.levelLow <= userElp && userElp < content.levelHigh) ||
				(userElp <= content.levelLow && content.levelLow <= lowestQuestionContent) ||
				(userElp >= content.levelHigh && content.levelHigh >= highestQuestionContent)
			){
				return content;
			}
		});

		//if it's a "practice" question, label it as such
		question.practice = null;
		if(question.elpContent.type.indexOf('pr') > -1){
			if(question.elpContent.type === 'pr_conv_turns'){
				question.practice = 'speaking';
			}else{
				question.practice = 'writing';
			}
		}

		//if there's a response to this question from the current user, set it
		let questionResponses = _.where(this.model.get('assessmentResponses'), {
			questionId: question.id
		});
		let response = _.findWhere(questionResponses, {studentId: this.userId});
		if(response){
			response.adjective = this.comfortNumberToAdjective(response.comfort);
			if(response.adjective){
				response.adjective = `<span class="adjective-span">${response.adjective}</span>
					<span class="emoji-span"><img src="/img/confidenceEmoji_${response.comfort}.svg"/></span>`
			}
			//determine response display based on specific content type
			switch(question.elpContent.type){
				case 'stem_point':
					response.output = _.findWhere(question.elpContent.options, {key: response.response.enhanced}).content;
					break;
				case 'stem_drag':
					response.output = _.compact(_.values(response.response.enhanced)).join(', ');
					break;
				case 'stem_fill':
					response.output = _.compact(_.values(response.response.enhanced)).join(', ');
					break;
				case 'stem':
					response.output = _.compact(_.values(response.response.enhanced)).join(', ');
					break;
				case 'pr_write':
					response.output = response.response;
					break;
				default:
					break;
			}
		}

		question.response = response;

		question.notStudent = this.notStudent;
		question.textToSpeech = this.$el.find(`<div>${question.elpContent.content}</div>`).text();

		slide.questions = [{
			question: [question],
			onDisplay: true,
			studentResponse: response,
			isAssessment: false,
			notStudent: question.notStudent,
			matchedAssessmentId: slide.matchedAssessment.id,
			bundleId: this.model.get('bundleId'),
			assignedBundleId: slide.assignedBundleId
		}];
		slide.currentQuestionId = question.id;
		slide.studentResponse = response;
	},
	prepareP2pAssessment(slide){
		let currentQuestion;
		let availableQuestions = _.findWhere(this.model.get('assessments'), {id: slide.assessmentId});
		let allQuestions = [];
		let isResponse = false;
		let responseCount = 0;
		let randomP2pQuestionId = 0;
		//loop through all question IDs, check if there are multiple responses
		for(let questionId of slide.matchedAssessment.questionIds){
			let question = _.findWhere(this.model.get('questions'), {id: questionId});
			let preparedQuestion = this.prepareQuestion(question);
			preparedQuestion.question = [preparedQuestion.question]; //always array, template iterates over questions array
			preparedQuestion.matchedAssessmentId = slide.matchedAssessment.id;
			preparedQuestion.assignedBundleId = this.assignedBundleId;
			if(preparedQuestion.studentResponse){
				responseCount += 1;
				isResponse = true;
				preparedQuestion.onDisplay = true;
			}
			allQuestions.push(preparedQuestion);
		}
		//if there are multiple responses (i.e. a teacher is reviewing an assignment)
		if(responseCount > 1){
			//set all but the first question's display property to false
			let questionDisplayed = false;
			for(let question of allQuestions){
				if(question.onDisplay && !questionDisplayed){
					questionDisplayed = true;
				}else if(question.onDisplay && questionDisplayed){
					question.onDisplay = false;
				}
			}
		}
		//if a response hasn't been recorded, display a random question
		if(!isResponse){
			randomP2pQuestionId = this.randomQuestionId();
			let randomQuestion = _.find(allQuestions, function(q){
				return _.contains(_.pluck(q.question, 'id'), randomP2pQuestionId);
			});
			randomQuestion.onDisplay = true;
		}
		slide.questions = allQuestions;
		slide.studentResponse = isResponse;
		slide.currentQuestionId = randomP2pQuestionId;
	},
	prepareQuestion: function(question){
		//if a response to this question is present, include it
		let response = false;
		if(question){
			//finds the response for a given question, from a given student
			let questionResponses = _.where(this.model.get('assessmentResponses'), {
				questionId: question.id
			});
			response = _.findWhere(questionResponses, {studentId: this.userId});
			//set a separate property for the text content of question, for text-to-speech
			// TODO text to speech?
			//question.textToSpeech = this.$el.find(`<div>${question.content}</div>`).text();
		}
		if(response){
			response.adjective = this.comfortNumberToAdjective(Number(response.comfort));
			if(response.adjective){
				response.adjective = `<span class="adjective-span">${response.adjective}</span>
					<span class="emoji-span"><img src="/img/confidenceEmoji_${response.comfort}.svg"/></span>`
			}
		}
		return {
			question: question,
			notStudent: this.notStudent,
			studentResponse: response,
			isAssessment: true,
			onDisplay: false
		};
	},
	/**
	 * Overridable method. Get current Assignment.
	 * @return (void)
	 */
	getAssignmentProperty(){
		return this.model.get('assignment');
	},
	setProgress(slideIndex){
		this.progress = Math.round(((slideIndex + 1) / this.slides.length) * 100);
	},
	clickedPlayerCover(){
		//"Expectations" and "Dictionary" panels respond to this event
		nm.vent.trigger('assignment_player:clicked_cover');
	},
	clickedDefinitionHolder(event){
		//only fire event if the clicked element is nm-definition-holder (aka "outside" the panel)
		if(event.target.id === 'nm-definition-holder'){
			//opened Vocab Definition responds to this event
			nm.vent.trigger('assignment_player:clicked_definition_holder');
		}
	},
	clickedReviewHolder(event){
		//only fire event if the clicked element is nm-review-holder (aka "outside" the panel)
		if(event.target.id === 'nm-review-holder'){
			//opened Vocab Definition responds to this event
			nm.vent.trigger('assignment_player:clicked_review_holder');
		}
	},
	/**
	 * saveAssignmentSequenceStatus
	 * When a student reaches slide 3 of a primer, save an event status to indicate this.
	 * These saved event statuses are what instructs the "scheduler" to schedule the next primer
	 * in the Sequence. Logs to GA that an attempt to save was... attempted.
	 * @return (void)
	 */
	saveAssignmentSequenceStatus(){
		const key = 'assignment_sequence';
		const assignedBundle = this.getStudentBundle(this.model.toJSON().assignedBundles);
		const userId = this.userId;
		const assignmentId = this.model.get('result').id;
		const bundleId = assignedBundle.id;
		const foundAssignmentSequenceEvent = _.contains(assignedBundle.events, 'assignment_sequence');
		//if event status wasn't already saved before, and if we've got a bundleId, save event status
		if(!foundAssignmentSequenceEvent && bundleId){
			this.newEventStatus(key, bundleId);
		}
		//log to GA that an attempt to save an event status took place
		// ga('send', {
		// 	hitType: 'event',
		// 	eventCategory: 'Assignment',
		// 	eventAction: 'Sequence Threshold Save Attempted',
		// 	eventLabel: `User ID ${userId} attempted to access slide 3 from Assignment ${assignmentId} / Bundle ${bundleId}`
		// });
	},
	/**
	 * assignmentSequenceStatusSuccess
	 * Called when the above event status saves successfully.
	 * Also manually updates the current this.model to include newly saved event status,
	 * and logs to GA.
	 * @return (void)
	 */
	assignmentSequenceStatusSuccess(statusData){
		const userId = this.userId;
		const bundleId = statusData.get('result').assignedBundleId;
		const eventStatus = statusData.get('result').eventKey;
		const eventStatusId = statusData.get('result').id;
		const assignmentId = this.model.get('result').id;
		let assignedBundles = this.model.toJSON().assignedBundles;
		//iterate through assignedBundles and update the correct one with recently saved event status
		for(let b of assignedBundles){
			if(Number(b.studentId) === Number(this.userId)){
				b.events.push(eventStatus);
			}
		}
		//update the model itself
		this.model.set({
			assignedBundles: assignedBundles
		}, {silent: true});
		//log to GA that the event status was saved
		// ga('send', {
		// 	hitType: 'event',
		// 	eventCategory: 'Assignment',
		// 	eventAction: 'Sequence Threshold Saved',
		// 	eventLabel: `User ID ${userId} accessed slide 3 from Assignment ${assignmentId} / Bundle ${bundleId}, Event Status ${eventStatusId}`
		// });
	},
	//returns query string params that don't change from slide to slide
	getConstantParams(params){
		const queryString = _.compact(_.map(params,function(v,k){
			//if we're on the "back" parameter, don't encode again (it's already encoded by now)
			if(k === 'back' || k === 'bundle'){
				return k + '=' + v;
			}
		})).join('&');
		return queryString;
	}
});
