import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import BackboneViewWrapper from '../backbone/helpers/BackboneViewHelper';
import AssignmentPlayerView from '../backbone/views/layouts/AssignmentPlayerView';
import AssignedAssignment from '../backbone/models/AssignedAssignment';

const AssignmentPlayerPage = () => {
    const { id } = useParams();
    const [model, setModel] = useState();

    useEffect(() => {
        setModel(new AssignedAssignment({ id: id }));
    }, [id])

    return (
        <div id="assignment-player-holder">
            { model && <BackboneViewWrapper 
            View={AssignmentPlayerView} 
            model={model} 
            /> }
        </div>
    );
}

export default AssignmentPlayerPage;
