import _ from 'underscore';
import AppBaseView from '../base/AppBaseView';
import stemFillView from '../../templates/questions/enhancedElp/stemFillView.handlebars';

export default AppBaseView.extend({
	events: {
	},
	className: 'stem-fill-view-holder',
	initialize(options){
		_.bindAll(this, 'errorHandler', 'initFillInputs');
		this.contentParts = options.contentParts;
		this.response = options.response;
	},
	render(){
		this.$el.html(stemFillView({
			contentParts: this.prepareContentParts(),
			response: this.response
		}));
		this.initFillInputs();
		return this;
	},
	initFillInputs(){
		//flash inputs to let user know where to interact
		this.$el.find('.stem-fill-part-input').addClass('flash');
	},
	prepareContentParts(){
		//look through "slots" and populate with saved responses when available
		if(this.response){
			let slotResponses = this.response.response.enhanced;
			for(let part of this.contentParts){
				if(slotResponses[part.key]){
					part.value = slotResponses[part.key];
				}
			}
		}
		return this.contentParts;
	}
});
