import React from 'react';
import BackboneViewWrapper from '../backbone/helpers/BackboneViewHelper';
import ClassroomsView from '../backbone/views/ClassroomsView';
import Classroom from '../backbone/models/Classroom';

const LearningCenterPage = ({
    onNavigate,
}) => <BackboneViewWrapper View={ClassroomsView} model={new Classroom()} onNavigate={onNavigate} />

export default LearningCenterPage;
