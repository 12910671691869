/**
 * ClassroomsView
 * Effectively the "home page" for a teacher,
 * shows top level groups (Classrooms) and their subgroups
 */
import $ from 'jquery';
import _ from 'underscore';
import moment from 'moment';
import AppBaseView from './base/AppBaseView';
import nm from '../nm';
import classroomsView from '../templates/classroomsView.handlebars';

export default AppBaseView.extend({
	events: {
		'click .kebab-button': 'toggleKebab',
		'click .toggle-subgroups': 'toggleSubgroups',
		'click .create-group': 'createGroup',
		'click .refresh-button': 'triggerRefresh',
		'click .toggle-hidden-groups': 'toggleHiddenGroups',
		'click .pause-all-sequences': 'pauseAllSequences',
		'click .play-all-sequences': 'playAllSequences',
		'click .routing-navigate': 'routingNavigate'
	},
	className: 'classrooms-holder',
	pageTitle: 'Classrooms | Positive Learning',
	showHidden: false, //whether hidden classrooms/groups should be visible
	initialize(options){
		_.bindAll(this, 'errorHandler', 'setSynced');
		this.model.fetch({
			success: this.setSynced,
			error: this.errorHandler,
			data: {
				limit: 2000
			}
		});
		this.classroomSequences = {};
		this.classroomSequences.synced = true;
		this.listenTo(this.model, 'sync', this.render);
		this.listenTo(nm.vent, 'classrooms:refresh', this.refreshView);
		this.navigateFn = options.onNavigate;
	},
	render(){
		if(this.model.synced && this.classroomSequences.synced){
			//always show header
			this.showHeader();
			this.$el.html(classroomsView({
				classrooms: this.prepareClassrooms(),
				user: nm.user.toJSON(),
				showHidden: this.showHidden,
				allRestartDate: this.prepareAllRestartDate()
			}));
			this.setupKebabMenu();
			nm.vent.trigger('view:render');
		}
		return this;
	},
	prepareClassrooms(){
		let groups = _.uniq(this.model.get('results').concat(this.model.get('groups')), 'id'); //join and ensure unique
		let subGroupsByParent = _.groupBy(_.sortBy(this.model.get('groups'), 'title'), 'parentId'); //sort the groups too
		let groupsById = _.indexBy(groups, 'id');
		let classroomSequences;
		//if not a student user, index classroom sequences by classroom ID
		if(nm.user.get('type') !== 'student'){
			classroomSequences = _.indexBy(this.classroomSequences.get('results'), 'classroomId');
		}
		for(let x in subGroupsByParent){
			if(groupsById[x]){
				groupsById[x].subgroups = subGroupsByParent[x];
				//compare hidden count to total, if equal then hide 'show groups' button
				if(this.showHidden){
					groupsById[x].containsHidden = false; //reset
				}else if(_.where(subGroupsByParent[x], {hidden: 1}).length === subGroupsByParent[x].length){
					groupsById[x].containsHidden = true;
				}
			}
		}
		//set additional properties for labels on groups
		for(let group of this.model.get('results')){
			//if group has a sequence
			if(classroomSequences && classroomSequences[group.id]){
				let today = moment();
				let startDate = moment.unix(classroomSequences[group.id].startDate.timestamp);
				let restartDate = moment.unix(classroomSequences[group.id].restartTimestamp);
				if(startDate && startDate.isAfter(today)){
					group.scheduledStart = startDate.utc().format('MMM Do, Y');
				}else if(restartDate && restartDate.isAfter(today)){
					group.scheduledRestart = restartDate.utc().format('MMM Do, Y');
				}
			}else if(nm.user.get('type') !== 'student'){
				//only add this property for non-student users
				group.noSequence = true;
			}
		}
		//return sorted root classrooms
		return _.sortBy(this.model.get('results'), 'title');
	},
	prepareAllRestartDate(){
		if(nm.user.get('type') !== 'student'){
			let classroomSequences = this.classroomSequences.get('results');
			let uniqueRestarts = _.uniq(_.pluck(classroomSequences, 'restartTimestamp'));
			//if there's only one truthy restarttime, then every classroom is paused and scheduled for same time
			if(_.first(uniqueRestarts) && uniqueRestarts.length === 1){
				return moment.unix(_.first(uniqueRestarts)).utc().format('MMM Do, Y');
			}
			return false;
		}
		return false;
	},
	toggleSubgroups(event){
		event.preventDefault();
		let $ele = $(event.currentTarget);

		//manage the state of the button being interacted with
		if($ele.parents('.flexed-item-row ').first().hasClass('show-tray')){
			$ele.removeClass('pseudo-class-active');
			$ele.find('span').text('Show Groups');
			$ele.find('i').text('keyboard_arrow_down');
		}else{
			$ele.addClass('pseudo-class-active');
			$ele.find('span').text('Hide Groups');
			$ele.find('i').text('keyboard_arrow_up');
		}

		//actually open / close the tray
		this.toggleRowTray($ele, {
			leaveOthersOpen: true
		});
	},
	refreshView(){
		//set synced properties to false
		this.model.synced = false;
		this.classroomSequences.synced = false;
		//re-fetch assignments
		this.model.fetch({
			success: this.setSynced,
			error: this.errorHandler,
			data: {
				limit: 2000
			}
		});
		this.classroomSequences.fetch({
			success: this.setSynced,
			error: this.errorHandler
		});
	},
	triggerRefresh(event){
		event.preventDefault();
		let $refreshButton = $(event.currentTarget);
		if(!$refreshButton.parents('.refresh').hasClass('refreshing')){
			$refreshButton.parents('.refresh').addClass('refreshing');
		}
	},
	toggleHiddenGroups(event){
		event.preventDefault();
		this.showHidden = !this.showHidden;
		this.render();
	},
});
