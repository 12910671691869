import React from 'react';
import './css/style.scss';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";

import DistrictRouter from './components/DistrictRouter';
import MissingDistrictPage from './pages/MissingDistrictPage';

const App = () => {
  return (
        <Router>
          <Switch>
            <Route path="/:id">
              <DistrictRouter />
            </Route>
            <Route path="/">
              <MissingDistrictPage />
            </Route>
          </Switch>
        </Router>
  )
}

export default App;
