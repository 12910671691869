import AppBaseView from '../base/AppBaseView';
import reviewPrimaryTextView from '../../templates/assignmentPlayer/reviewPrimaryTextView.handlebars';

export default AppBaseView.extend({
	events: {
	},
	className: 'review-primary-text-holder',
	initialize: function(){
	},
	render: function(){
		this.$el.html(reviewPrimaryTextView(this.collection.toJSON()));
		return this;
	}
});
