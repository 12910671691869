import $ from 'jquery';
import _ from 'underscore';
import AppBaseView from '../base/AppBaseView';
import Modal from '../../models/Modal';
import BulkAssessmentResponses from '../../models/BulkAssessmentResponses';
import nm from '../../nm';
import qinboxView from '../../templates/assignmentPlayer/qinboxView.handlebars';

export default AppBaseView.extend({
	events: {
		'click .qinbox-submit': 'confirmSubmit',
		'click .question': 'viewQuestion',
		'click .qinbox-next-question': 'viewQuestion',
		'click .toggle-qinbox-list': 'toggleQinboxList',
		'click .disabled-image-link': 'preventSubmit',
		'keyup .question-answer-input input[type="text"]': 'questionInteraction',
		'keyup .question-answer-input textarea': 'questionInteraction',
		'change .question-answer-input input[type=radio]': 'questionInteraction',
		'click .nm-adaptive-review': 'review',
		'click .nm-adaptive-practice': 'practice',
		'click .vocab-word': 'showDefinition',
		'click .qinbox-continue': 'goToOutro',
		'click .qinbox-exit': 'exitPostTest',
		'input #comfort-range': 'updateComfortRange'
	},
	className: 'qinbox-holder',
	responses: [],
	assessment: null,
	initialize(options){
		_.bindAll(this, 'errorHandler');
		this.assessment = options.assessment;
		this.questions = options.questions;
		this.key = options.key;
		this.responses = []; // reset
		this.prepareAssessments();
		this.listenTo(nm.vent, 'pretest:submit', this.submitAssessment);
		this.listenTo(nm.vent, 'status:success', this.postTestStatusSaved);
		this.listenTo(nm.vent, 'assignment:prepared', this.render);
		this.listenTo(nm.vent, 'view:render', this.initRangeslider);
	},
	render(){
		this.hideHeader();
		if(this.assessment.synced){
			this.$el.html(qinboxView({
				assessments: [this.assessment],
				hasResponses: this.responses.length,
				assignedAssignment: this.model.get('result'),
				key: this.key
			}));
			this.updateMathJax();
			nm.vent.trigger('view:render', {playerIsActive: true});
			var that = this;
			this.$el.find('.img-column').find('img').on('load', function(){
				that.fadeInImg($(this));
			});
		}
		return this;
	},
	//prepare assessment appropriately depending on what's being presented
	prepareAssessments(){
		switch(this.key){
			case 'pre-test':
				this.preparePreTest();
				break;
			case 'pre-test-results':
				this.preparePreTestResults()
				break;
			case 'practice':
				this.preparePractice();
				break;
			case 'post-test':
				this.preparePostTest();
				break;
		}
	},
	preparePreTest(){
		//there should only be one assignedBundle per assignment for a student
		let assignedBundle = this.model.get('assignedBundles')[0];
		this.assessment.populatedQuestions = this.prepareQuestions();
		this.assessment.assignedBundleId = assignedBundle.id;
		//assume test can be submitted
		this.assessment.submittable = true;
		this.responses = _.where(this.model.get('assessmentResponses'), {
			assessmentId: this.assessment.id
		});
		//any responses at all, not allowed to submit
		//since assessments are submitted in bulk, any responses means the student has taken the test
		if(this.model.get('assessmentResponses').length){
			this.assessment.submittable = false;
		}
		this.assessment.synced = true;
		nm.vent.trigger('assignment:prepared');
	},
	preparePreTestResults(){
		let response;
		//there should only be one assignedBundle per assignment for a student
		let assignedBundle = this.model.get('assignedBundles')[0];
		this.assessment.numberCorrect = 0;
		this.assessment.populatedQuestions = this.prepareQuestions();
		this.assessment.assignedBundleId = assignedBundle.id;
		//assume test can be submitted
		this.assessment.submittable = true;

		for(let question of this.questions){
			response = _.findWhere(this.model.get('assessmentResponses'), {
				questionId: question.id,
				assessmentId: this.assessment.id
			});
			if(response){
				this.responses.push(response);
				question.includeAnswerKey = true;
				question.includeStudentResponse = true;
				question.studentResponse = response;
				if(question.studentResponse.score){
					this.assessment.numberCorrect++;
				}
			}
		}
		//since assessments are submitted in bulk, any responses means the student has taken the test
		if(this.responses.length){
			this.assessment.submittable = false;
		}
		//determine "%" correct, used to output appropriate emoji
		let percentCorrect = this.assessment.numberCorrect / this.assessment.populatedQuestions.length;
		this.assessment.emojiLevel = this.getEmojiLevel(percentCorrect);
		//show the "review" button right away when displaying pre test results
		this.assessment.showReviewButton = true;
		this.assessment.synced = true;
		nm.vent.trigger('assignment:prepared');
	},
	preparePractice(){
		let response;
		let assignedBundle = this.model.get('assignedBundles')[0];
		let questions = _.indexBy(this.model.get('questions'), 'id');
		let confidence = _.findWhere(this.model.get('assessments'), {type: 'p2p_test'});
		//TODO: temp until full bundle system, spoof assessment
		this.assessment = {
			id: confidence.id,
			questionIds: [],
			type: 'p2p_test'
		};
		this.assessment.numberCorrect = 0;
		//assume test can be submitted
		this.assessment.submittable = true;
		//there should only be one assignedBundle per assignment for a student
		this.assessment.assignedBundleId = assignedBundle.id;
		this.assessment.populatedQuestions = [];
		//determine which confidence question to display to tier1 student
		for(let c of confidence.questionIds){
			//write_about question is for tier1+2 students
			if(questions[c] && questions[c].type === 'write_about'){
				questions[c].instructions = nm.questionInstructions[questions[c].type];
				questions[c].wordBank = questions[c].content.bank;
				// assume these questions have comfort -1. User manipulation of slider will update this value
				questions[c].comfort = -1;
				this.assessment.populatedQuestions.push(questions[c]);
				//find response if it exists for this assessment
				response = _.findWhere(this.model.get('assessmentResponses'), {
					questionId: c,
					assessmentId: confidence.id
				});
				if(response){
					this.responses.push(response);
					questions[c].includeAnswerKey = true;
					questions[c].includeStudentResponse = true;
					questions[c].studentResponse = response;
					if(questions[c].studentResponse.score){
						this.assessment.numberCorrect++;
					}
				}
			}
		}
		//set a property to track questions throughout this view
		this.questions = this.assessment.populatedQuestions;
		//since assessments are submitted in bulk, any responses means the student has taken the test
		if(this.responses.length){
			this.assessment.submittable = false;
		}
		//determine "%" correct, used to output appropriate emoji
		let percentCorrect = this.assessment.numberCorrect / this.assessment.populatedQuestions.length;
		this.assessment.emojiLevel = this.getEmojiLevel(percentCorrect);
		this.assessment.synced = true;
		nm.vent.trigger('assignment:prepared');
	},
	preparePostTest(){
		let response
		//there should only be one assignedBundle per assignment for a student
		let assignedBundle = this.model.get('assignedBundles')[0];
		this.assessment.populatedQuestions = this.prepareQuestions();
		this.assessment.assignedBundleId = assignedBundle.id;
		//assume test can be submitted
		this.assessment.submittable = true;
		this.assessment.numberCorrect = 0;
		this.responses = _.where(this.model.get('assessmentResponses'), {
			assessmentId: this.assessment.id
		});
		//any responses at all, not allowed to submit
		//since assessments are submitted in bulk, any responses means the student has taken the test
		if(this.model.get('assessmentResponses').length){
			this.assessment.submittable = false;
		}
		for(let question of this.questions){
			response = _.findWhere(this.model.get('assessmentResponses'), {
				questionId: question.id,
				assessmentId: this.assessment.id
			});
			if(response){
				this.responses.push(response);
				question.includeAnswerKey = true;
				question.includeStudentResponse = true;
				question.studentResponse = response;
				if(question.studentResponse.score){
					this.assessment.numberCorrect++;
				}
			}
		}
		//determine "%" correct, used to output appropriate emoji
		let percentCorrect = this.assessment.numberCorrect / this.assessment.populatedQuestions.length;
		this.assessment.emojiLevel = this.getEmojiLevel(percentCorrect);
		this.assessment.synced = true;
		nm.vent.trigger('assignment:prepared');
	},
	prepareQuestions(){
		for(let [i, q] of this.questions.entries()){ // eslint-disable-line keyword-spacing
			q.instructions = nm.questionInstructions[q.type];
			q.keyVocab = _.filter(this.model.get('words'), function(w){
				return _.contains(q.wordIds, w.id);
			});
		}
		return this.questions;
	},
	viewQuestion(event){
		event.preventDefault();
		let $eventSource = $(event.currentTarget);
		let targetQuestionId = $(event.currentTarget).data('question-id');

		// if event received from "Next" button, properly set the "target question"
		if($eventSource && $eventSource.hasClass('button')){
			targetQuestionId = $('.question-active').next('.question').data('question-id');
		}

		// set the "states" of the various questions
		let targetQuestion = _.findWhere(this.questions, {id: Number(targetQuestionId)});
		let otherQuestions = _.filter(this.questions, function(q){
			if(q.id !== Number(targetQuestionId)){
				return q;
			}
		});

		// assume we enable the "next" button
		$('.qinbox-next').attr('disabled', false);

		// first questions shows on render always, so, it's always "visited", and assume it's "current"
		_.first(this.questions).visited = true;
		_.first(this.questions).current = true;

		for(let q of this.questions){
			// all other questions are now NOT current
			q.current = false;
			if(q.id === targetQuestion.id){
				// viewed question is now current and visited, of course
				q.current = true;
				q.visited = true;
			}
			// if last question is "current", disable "next" button, otherwise, it's enabled
			if(Number($('.qinbox-list .question:last-child').data('question-id')) === targetQuestion.id){
				$('.qinbox-next').attr('disabled', true);
			}
		}

		// actually update the interface to reflect the questions' "states"
		this.manageQuestionStates();

		// if mobile, close questions list automatically
		this.toggleQinboxList(null, true);
	},
	manageQuestionStates(){
		// hide all key vocab
		$(`.question-key-vocab`).removeClass('show-key-vocab');
		// hide all comfort sliders
		let $comfortSliders = $('.qinbox-comfort').addClass('hide-qinbox-comfort');

		for(let q of this.questions){
			let $listQ = $(`.question[data-question-id="${q.id}"]`);
			let $contentQ = $(`input[value="${q.id}"]`).parents('li.question-not-answered');
			let $qVocab = $(`.question-key-vocab[data-question-vocab-id=${q.id}]`);
			let $comfortSlider = $(`.qinbox-comfort[data-question-vocab-id=${q.id}]`)
			$listQ.removeClass('question-active');
			$contentQ.removeClass('question-content-active').addClass('question-content-inactive');
			$qVocab.addClass('hide-key-vocab');
			if(q.visited && !q.studentResponse){
				$listQ.addClass('question-visited');
				$listQ.find('.question-status i:last-child').text('').removeClass('color-plblue');
			}
			if(q.visited && q.studentResponse){
				$listQ.find('.question-status i:last-child').text('').removeClass('color-plblue');
			}
			if(q.answered && !q.studentResponse){
				$listQ.find('.question-status i').text('question_answer').addClass('color-plblue');
			}
			if(q.current){
				$listQ.addClass('question-active');
				$listQ.find('.question-status i:last-child').text('keyboard_arrow_right');
				$contentQ.addClass('question-content-active').removeClass('question-content-inactive');
				$qVocab.removeClass('hide-key-vocab');
				$comfortSlider.removeClass('hide-qinbox-comfort');
			}else if(q.studentResponse){
				if(q.studentResponse.score){
					$listQ.find('.question-status i:first-child').text('check_circle').addClass('color-green');
				}else{
					$listQ.find('.question-status i:first-child').text('error').addClass('color-red');
				}
			}
			if(q.answered && q.current){
				$listQ.find('.question-status i:last-child').text('keyboard_arrow_right').removeClass('color-plblue');
			}
		}
	},
	getEmojiLevel(percentCorrect){
		let emojiLevel = 5;
		if(percentCorrect < 0.8){
			emojiLevel = 4;
		}
		if(percentCorrect < 0.6){
			emojiLevel = 3;
		}
		if(percentCorrect < 0.4){
			emojiLevel = 2;
		}
		if(percentCorrect < 0.2){
			emojiLevel = 1;
		}
		return emojiLevel;
	},
	questionInteraction: _.throttle(function(event){
		// upon receipt of throttled event, check for presence of answers on all questions
		for(let q of this.questions){
			switch(q.type){
				case 'fill_in':
					let $inputValue = $('.questions-list').find(`li[data-question-id="${q.id}"]`)
						.find('.question-answer-input input').val();
					if($inputValue){
						q.answered = true;
					}else{
						q.answered = false;
					}
					break;
				case 'multiple':
					let $multipleRadioInputs = $('.questions-list').find(`li[data-question-id="${q.id}"]`)
						.find('.question-answer-input .radio-input input[type="radio"]');
					let multipleRadiosChecked = false;
					$multipleRadioInputs.each(function(){
						if($(this).is(':checked')){
							multipleRadiosChecked = true;
						}
					});
					if(multipleRadiosChecked){
						q.answered = true;
					}else{
						q.answered = false;
					}
					break;
				case 'true_false':
					let $trueFalseRadioInputs = $('.questions-list').find(`li[data-question-id="${q.id}"]`)
						.find('.question-answer-input .radio-input input[type="radio"]');
					let trueFalseRadiosChecked = false;
					$trueFalseRadioInputs.each(function(){
						if($(this).is(':checked')){
							trueFalseRadiosChecked = true;
						}
					});
					if(trueFalseRadiosChecked){
						q.answered = true;
					}else{
						q.answered = false;
					}
					break;
				case 'open_ended':
					let $textareaValue = $('.questions-list').find(`li[data-question-id="${q.id}"]`)
						.find('.question-answer-input textarea').val();
					if($textareaValue){
						q.answered = true;
					}else{
						q.answered = false;
					}
					break;
				case 'write_about':
					let $writeAboutValue = $('.questions-list').find(`li[data-question-id="${q.id}"]`)
						.find('.question-answer-input textarea').val();
					if($writeAboutValue){
						q.answered = true;
					}else{
						q.answered = false;
					}
					this.manageWordBank($writeAboutValue, q.content.bank);
					break;
			}
		}
	}, 500),
	confirmSubmit(event){
		event.preventDefault();
		nm.vent.trigger('pretest:confirmSubmit', new Modal({
			message: `You're about to submit your answers. Are you sure?`,
			action: 'pretest:submit'
		}));
	},
	submitAssessment(){
		let $ele = $('.qinbox-submit');
		$ele.attr('disabled', true).find('i').text('hourglass_empty').addClass('flash');
		this.targetForm = $('.take-assessment-form');
		let assessment = this.targetForm.serializeJSON();
		let bulkAssessmentResponses = new BulkAssessmentResponses();
		//if there is only one question, make sure to convert to array
		if(!Array.isArray(assessment.questionId)){
			assessment.questionId = [assessment.questionId];
		}
		let questions = _.map(assessment.questionId, (qid) => ({id: Number(qid)}));
		let populatedQuestions = _.indexBy(this.questions, 'id');
		let responses = [];
		//build array of responses, one for each question, even if a question is unanswered
		for(let question of questions){
			let response = '';
			let comfort = 0;
			for(let k in assessment){
				if(assessment.hasOwnProperty(k) && k.indexOf(question.id) > -1){
					response = assessment[k]
				}
			}
			let userSetComfort = populatedQuestions[question.id].comfort;
			if(userSetComfort !== 0){
				comfort = userSetComfort;
			}
			responses.push({
				questionId: question.id,
				response: response,
				comfort: comfort
			});
		}
		bulkAssessmentResponses.set({
			assessmentId: Number(assessment.assessmentId),
			assignedBundleId: Number(assessment.assignedBundleId),
			responses: responses
		});

		bulkAssessmentResponses.save({}, {
			success: (model, response, options) => {
				switch(this.key){
					case 'pre-test':
						//add responses to current model
						this.model.fetch({
							success: () => {
								//put confirmation here
								nm.vent.trigger('assignment_player:load', 'pre-test-results');
							},
							error: this.errorHandler
						});
						break;
					case 'practice':
						//put confirmation here
						nm.vent.trigger('assignment_player:load', 'outro');
						break;
					case 'post-test':
						// ga('send', {
						// 	hitType: 'event',
						// 	eventCategory: 'Post Test',
						// 	eventAction: 'Post Test Submitted',
						// 	eventLabel: 'User ID ' + nm.user.get('id') + ' submitted Post Test Assessment ID: ' + model.get('assessmentId')
						// });
						let key = 'posttest_complete';
						let bundle = this.getStudentBundle(this.model.toJSON().assignedBundles);
						//save 'posttest_complete' status event. Listener navigates away from post test when event saves.
						this.newEventStatus(key, bundle.id);
						break;
				}
			},
			error: this.errorHandler
		});
	},
	review(event){
		event.preventDefault();
		nm.vent.trigger('assignment_player:load', 'grid');
	},
	practice(event){
		event.preventDefault();
		nm.vent.trigger('assignment_player:load', 'practice');
	},
	toggleQinboxList(event, forceClose){
		if(event){
			event.preventDefault();
		}
		let $toggleQinboxList = $('.toggle-qinbox-list');
		let $toggleIcon = $toggleQinboxList.find('i');
		let $qinboxList = $('.qinbox-list');
		if($qinboxList.hasClass('opened-qinbox-list') || forceClose){
			$qinboxList.removeClass('opened-qinbox-list');
			$toggleIcon.text('keyboard_arrow_right');
		}else{
			$qinboxList.addClass('opened-qinbox-list');
			$toggleIcon.text('keyboard_arrow_left');
		}
	},
	manageWordBank(userText, wordBank){
		userText = userText.toLowerCase(); // ignore capitalization when checking
		for(let w of wordBank){
			if(userText.indexOf(w) > -1){
				$('.write-about-bank').find(`span[data-bank-word="${w}"]`).addClass('bank-used')
					.find('i').addClass('bounceIn').text('done');
			}else{
				$('.write-about-bank').find(`span[data-bank-word="${w}"]`).removeClass('bank-used')
					.find('i').removeClass('bounceIn').text('remove');
			}
		}
	},
	showDefinition(event){
		event.preventDefault();
		let vocabId = $(event.currentTarget).data('vocab-id');
		nm.vent.trigger('words:define', vocabId);
	},
	goToOutro(event){
		event.preventDefault();
		nm.vent.trigger('assignment_player:load', 'outro');
	},
	exitPostTest(event){
		event.preventDefault();
		// TODO 
		//let params = this.getSearchParameters(location.hash);
		// if(params.classroom){
		// 	nm.router.navigate(`student/group/${params.classroom}`, {trigger: true});
		// }else{
		// 	nm.router.navigate('learning-center', {trigger: true});
		// }
	},
	//once posttest_completed event is saved, navigate back to learning center
	postTestStatusSaved(status){
		let eventKey = status.get('eventKey');
		if(eventKey === 'posttest_complete'){
			// let params = this.getSearchParameters(location.hash);
			// if(params.classroomId){
			// 	nm.router.navigate(`student/group/${params.classroomId}`, {trigger: true});
			// }else{
			// 	nm.router.navigate('learning-center', {trigger: true});
			// }
		}
	},
	updateComfortRange(event){
		event.stopPropagation();
		var $ele = $(event.currentTarget);
		var currentId = $ele.data('id');
		var comfortLevel = $ele.val();
		var comfortLevelOutput = $('#comfort-range-output-' + currentId);
		if(comfortLevel){
			var adjective = this.comfortNumberToAdjective(Number(comfortLevel));
			var emojiSpan = '<span class="emoji-span"><img src="/img/confidenceEmoji_' + comfortLevel + '.svg"/></span>';
			comfortLevelOutput.html(emojiSpan + adjective);
		}
		let targetQuestion = _.findWhere(this.questions, {id: currentId});
		targetQuestion.comfort = Number(comfortLevel);
	},
	initRangeslider(){
		$('input[type="range"]').rangeslider({
			polyfill: false
		});
	}
});
