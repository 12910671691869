import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import AppBaseView from '../base/AppBaseView';
import ConvInputView from '../assignmentPlayer/ConvInputView';
import ConvOutputView from '../assignmentPlayer/ConvOutputView';
import ConvResponseView from '../assignmentPlayer/ConvResponseView';
import ConvStemView from '../assignmentPlayer/ConvStemView';
import SsmlToMedia from '../../models/SsmlToMedia';
import practiceConversationView from '../../templates/questions/enhancedElp/practiceConversationView.handlebars';
import defaultSsml from '../../templates/questions/enhancedElp/defaultSsml.handlebars';
import nm from '../../nm';

export default AppBaseView.extend({
	className: 'practice-conversation-view-holder',
	numberConvLines: null, //updated shortly with number of lines of text the current question has
	currentConvLineIndex: 0, //always start with the first line in the conversation
	currentConvLine: null, //the next line of conversation to display
	lastLine: 'Thanks! Keep going!', //displayed at end of conversation
	errorText: 'Oops, I couldn\'t hear you...', //used if there's a problem obtaining transcription
	consecutiveLinesLength: 0, //number of consecutive lines the app should speak at a given point in conversation
	consecutiveLinesIndex: 0, //index used to iterate consecutive lines
	userAudios: [], //store each audio file as user takes turns in conversation
	inputDisabled: true, //init conversation input / mic as disabled, enable when it's user's turn
	displayingErrorText: false, //flag to switch when displaying an error
	initialize(options){
		_.bindAll(this, 'errorHandler');
		this.question = options.question;
		this.response = options.response;
		this.words = options.words;
		this.elpLevel = options.elpLevel;
		this.numberConvLines = this.question.elpContent.content.length;
		this.stemRendering = this.getStemRendering();
		this.listenTo(nm.vent, 'conversation:userSpoke', this.converseWithApp);
		this.listenTo(nm.vent, 'audio:ended', this.audioEnded);
		this.listenTo(nm.vent, 'speechToText:error', this.speechToTextError);
		this.listenTo(nm.vent, 'convOutput:rendered', this.checkConversationOverflow);
		this.listenTo(nm.vent, 'audio:save', this.saveUserAudio);
	},
	render(){
		this.$el.html(practiceConversationView());
		this.convViews = {
			convInputView: new ConvInputView({
				question: this.question,
				disabled: this.inputDisabled
			}),
			convStemView: new ConvStemView({
				question: this.question,
				stemRendering: this.stemRendering
			})
		};
		this.convSpeechBubbles = [] //updated as conversation lines become available
		//if response, just render the output of the completed conversation...
		if(this.response){
			this.renderCompletedConversation();
		//...else render the interface and let's have a conversation
		}else{
			this.assignSubViews({
				'#nm-conv-input': this.convViews.convInputView,
				'#nm-conv-stem': this.convViews.convStemView
			});
			_.defer(() => {
				this.converseWithUser();
			});
		}
		nm.vent.trigger('view:render', {playerIsActive: true});
		return this;
	},
	//simply renders the saved, complete conversation on screen, no interactive capability
	renderCompletedConversation(){
		//conversation length assumed to be the number of question "lines"
		let conversationLength = this.question.elpContent.content.length;
		let questionConvLines = _.map(this.question.elpContent.content, 'text');
		let userConvLines = this.response.response.enhanced.responseTranscripts;
		let userConvAudios = this.response.response.enhanced.responseAudios;
		if(!_.isArray(userConvLines)){
			userConvLines = [userConvLines];
		}
		let completeConversation = [];
		//build an array representing the whole conversation
		for(var i = 0; i < conversationLength; i++){
			if(_.isArray(questionConvLines[i])){
				let consecutiveLines = questionConvLines[i];
				for(var a = 0; a < consecutiveLines.length; a++){
					completeConversation.push({
						content: consecutiveLines[a],
						source: 'question'
					});
				}
			}else if(questionConvLines[i] === 'display_definition'){
				let word = _.findWhere(this.words, {id: _.first(this.question.wordIds)});
				completeConversation.push({
					content: word.alternate || word.definition,
					source: 'question'
				});
			}else{
				completeConversation.push({
					content: questionConvLines[i],
					source: 'question'
				});
			}
			completeConversation.push({
				content: userConvLines[i],
				audio: userConvAudios[i],
				source: 'user'
			});
		}
		completeConversation.push({
			content: this.lastLine,
			source: 'question'
		});
		this.convViews.convResponseView = new ConvResponseView({
			conversation: completeConversation
		});
		this.assignSubViews({
			'#nm-conv-output': this.convViews.convResponseView
		});
		//sets simpler styles, allows for vanilla scrolling content if overflowing
		this.$el.addClass('displaying-complete-conversation');
	},
	//determine next question content line and call for speech / text to render
	converseWithUser(){
		this.currentConvLine = this.prepareQuestionLine();
		if(this.currentConvLine){
			this.getSsmlMedia(this.currentConvLine);
		}
	},
	//politely retry last question content line in event of an error when listening to user
	retryConverseWithUser(){
		let errorConvLine = {
			ssmlText: defaultSsml({
				content: this.errorText
			}),
			text: this.errorText
		}
		this.getSsmlMedia(errorConvLine);
	},
	//given response of student speech translation, render that transcript in conversation
	converseWithApp(model){
		let userConvLine = {
			ssmlText: defaultSsml({
				content: model.get('result').transcript
			})
		}
		let userAudioTranscription = new Backbone.Model({
			result: {
				audio: `data:${model.get('contentType')};base64,${model.get('audio')}`,
				transcript: model.get('result').transcript,
				vtt: model.get('result').vtt
			},
			ssml: defaultSsml({
				content: model.get('result').transcript
			})
		});
		this.createSpeechBubble(userAudioTranscription, userConvLine, true);
	},
	//prepares next line of conversation from current question
	prepareQuestionLine(){
		let convLine = this.question.elpContent.content[this.currentConvLineIndex];
		if(convLine){
			//text is either string or array. If array, speak consecutive lines, else, just speak next line
			if(_.isArray(convLine.text)){
				this.consecutiveLinesLength = convLine.text.length;
				convLine.ssmlText = defaultSsml({
					content: convLine.text[this.consecutiveLinesIndex]
				});
			//if not array, check if "display_definition" key is being used, which calls for word's definition
			}else if(convLine.text === 'display_definition'){
				//get the word's definition. Assumes only one word is associated with question
				let word = _.findWhere(this.words, {id: _.first(this.question.wordIds)});
				convLine.ssmlText = defaultSsml({
					content: word.alternate || word.definition
				});
			//default action if a regular string
			}else{
				convLine.ssmlText = defaultSsml({
					content: convLine.text
				});
			}
		}else{
			//if no more question content lines are found, render the last line to end the conversation
			convLine = {
				ssmlText: defaultSsml({
					content: this.lastLine
				}),
				text: this.lastLine,
				endOfConversation: true
			}
		}
		return convLine;
	},
	//main method for taking SSML text and retrieving audio and transcript for playing / displaying
	getSsmlMedia(convLine, isUser = false){
		let ssml = new SsmlToMedia({
			ssml: convLine.ssmlText
		});

		//manage ui
		$('.conversation-speech').last()
			.next('.conversation-speech-loading').removeClass('hide-conversation-speech-loading');

		ssml.save({}, {
			success: (model, response, options) => {
				this.createSpeechBubble(model, convLine, isUser);
			},
			error: (model, response, options) => {
				this.errorHandler(model, response, options);
			}
		});
	},
	createSpeechBubble(model = null, convLine = null, isUser = false){
		//if end of conversation, let's also save the question
		if(convLine.endOfConversation){
			nm.vent.trigger('slide_question:submit', {
				ele: $('.conversation-speech-input').first(), //tricks SlideView's submitQuestion(), which expects an $ele
				currentQuestionId: this.question.id,
				userAudios: this.userAudios
			});
		}
		//render subview of conversation bubble by appending to mount point
		//every speech bubble created gets pushed to array, so we can clean up all of them later
		let conversationOutputView = new ConvOutputView({
			model: model,
			convLine: convLine,
			question: this.question,
			isUser: isUser,
			currentConvLineIndex: this.currentConvLineIndex,
			consecutiveLinesIndex: this.consecutiveLinesIndex,
			endOfConversation: convLine.endOfConversation,
			elpLevel: this.elpLevel
		});
		this.convSpeechBubbles.push(conversationOutputView);
		//Continually APPENDS a new subview to the mount point! This ain't "assignSubViews()"...
		this.appendSubViews({
			'#nm-conv-output': conversationOutputView
		});
		//if we're currently speaking consecutive lines
		if(this.consecutiveLinesLength){
			//increment to next line
			this.consecutiveLinesIndex++;
			//if we've reached the end of consecutive lines, show stem and reset
			if(this.consecutiveLinesIndex >= this.consecutiveLinesLength){
				this.consecutiveLinesLength = 0;
				this.consecutiveLinesIndex = 0;
				//tell ConvStemView to update the stem that's rendered
				nm.vent.trigger('conversation:updateStem', {
					currentConvLineIndex: this.currentConvLineIndex,
					isUser: isUser
				});
			}
		}else{
			//tell ConvStemView to update the stem that's rendered
			nm.vent.trigger('conversation:updateStem', {
				currentConvLineIndex: this.currentConvLineIndex,
				isUser: isUser
			});
		}
		//if user just spoke, increment conversation index for next line in conversation
		if(isUser){
			this.currentConvLineIndex++;
		}
		//manage ui
		$('.conversation-speech-loading').addClass('hide-conversation-speech-loading');
	},
	//add received audio file to array, used to send audio data when saving completed conversation
	saveUserAudio(audioFile){
		this.userAudios.push(audioFile);
	},
	//if error getting student's speech transcribed, retry the question contet line that was just spoken
	speechToTextError(){
		this.retryConverseWithUser();
		this.displayingErrorText = true;
	},
	//triggered when audio for a speech bubble has ended
	audioEnded(isUser){
		//if the audio was from the user speaking, or,
		//if we're speaking consecutive lines, proceed to next line of conversation, or
		//if we just showed an error, continue conversation
		if(isUser || this.consecutiveLinesLength || this.displayingErrorText){
			this.converseWithUser();
			this.displayingErrorText = false;
		}
	},
	getStemRendering(){
		if(this.elpLevel < 5){
			return true;
		}
		return false;
	},
	//gross and lame but I saw no other way. When speech bubbles start to overflow out of container,
	//update styling to allow for scrolling, but also keep everything scrolled to the bottom as
	//new speech bubbles come in
	//TODO: Some nonsense that triggers this upon window resizing
	checkConversationOverflow(){
		let $conversationEle = $('.practice-conversation-view-holder');
		let conversationHeight = $conversationEle.outerHeight();
		let containerHeight = $('#nm-practice-conversation-view').outerHeight();
		let headerHeight = $('.practice-question-heading').outerHeight() + 8; //gaaaah pad it a little so it's legible
		containerHeight = containerHeight - headerHeight;

		//if conversation grows beyond container, make it suddenly scrollable, and scroll it to the bottom
		if(conversationHeight >= containerHeight){
			let convInputHeight = $('#nm-conv-input').outerHeight();
			let convStemHeight = $('.conv-stem').outerHeight();
			let newBubblesHeight = containerHeight - convInputHeight - convStemHeight;
			//set speech bubbles container to appropriate height
			$('.practice-conversation-viewer').height(newBubblesHeight);
			//take practice-conversation-viewer ...set specific height, set scroll
			$('.practice-conversation-viewer').addClass('conversation-scrollable');
		}
		//auto-scroll to bottom. Won't matter if above check didn't set height / add the needed class
		$('.conversation-scrollable').scrollTop(2000);
	}
});
