import Backbone from 'backbone';
import UserActivity from './UserActivity';
import nm from '../nm';

export default Backbone.Model.extend({
	// Default attributes
	defaults: {},
	initialize: () =>{
		// TODO logout
		//this.listenTo(nm.vent, 'user:logout', this.logout);
	},
	populate: ({cognitoUser}) => {
		let properties = {
			admin: false,
			promptNewPassword: 0,
			editor: false
		};

		if (cognitoUser) {
			const attributes = cognitoUser.attributes;
			for(var x in attributes){
				if(attributes[x].Name === 'custom:positive_learning_id'){
					properties.id = Number(attributes[x].Value);
				}
				if(attributes[x].Name === 'custom:user_type'){
					properties.type = attributes[x].Value;
				}
				if(attributes[x].Name === 'custom:organization'){
					properties.context = attributes[x].Value;
				}
				if(attributes[x].Name === 'family_name'){
					properties.lastname = attributes[x].Value;
				}
				if(attributes[x].Name === 'name'){
					properties.firstname = attributes[x].Value;
				}
			}
			properties.display = properties.firstname + ' ' + properties.lastname;

			this.clear();
			this.set(properties);
			this.synced = true;

			//record user session start to event status
			let userActivity = new UserActivity();
			userActivity.save({
				eventKey: `${this.get('type')}_login`,
				userId: this.get('id')
			}, {
				error: this.errorHandler
			});

			this.trigger('sync');

		}

	},
	logout: function(){
		if(nm.aws.cognitoUser){
			nm.aws.cognitoUser.signOut();
		}
		this.clear();
		//manually un-set the sync variable created during login above
		this.synced = false;
		this.trigger('sync');
	}
});
