/**
 * AssignmentControlsView
 * Assignment Player controls
 */
import $ from 'jquery';
import _ from 'underscore';
import AppBaseView from '../base/AppBaseView';
import nm from '../../nm';
import assignmentControlsView from '../../templates/assignmentPlayer/assignmentControlsView.handlebars';

export default AppBaseView.extend({
	events: {
		'click .navigate-slide-next': 'triggerNextSlide',
		'click .navigate-slide-back': 'triggerPreviousSlide',
		'click .navigate-replay': 'replaySlide',
		'click .navigate-play': 'playPauseSlide',
		'click .speed-slower': 'triggerSlowDown',
		'click .speed-faster': 'triggerSpeedUp',
		'click .submit-take-question-form': 'triggerSubmitQuestion',
		'click .question-skip': 'triggerskipQuestion'
	},
	className: 'assignment-controls-holder',
	initialize: function(options){
		this.isReview = options.isReview;
		this.hasAudio = options.hasAudio;
		this.previousClicked = options.previousClicked;
		this.currentSlide = options.currentSlide;
		this.assessments = options.assessments;
		// When we first get to slide, lock continue button for 0.5 seconds to avoid button mashing
		this.continueLocked = true;
		var that = this;
		setTimeout(function(){
			that.continueLocked = false;
		}, 500);
		this.listenTo(nm.vent, 'continue:enabled', this.enableContinue);
		this.listenTo(nm.vent, 'change:audioPause', this.audioPause);
		this.listenTo(nm.vent, 'player:paused', this.changePausedButton);
		this.listenTo(nm.vent, 'speed:disable', this.disableSpeedButtons);
		this.listenTo(nm.vent, 'controls:disable', this.disableAllControls);
		this.listenTo(nm.vent, 'speed:enable', this.enableSpeedButtons);
		//for auto speed selection from SlideView
		this.listenTo(nm.vent, 'speed:slow', this.triggerSlowDown);
		this.listenTo(nm.vent, 'speed:fast', this.triggerSpeedUp);
	},
	render: function(){
		//use setElement to prevent extra un-needed wrapping div
		if(nm.user.get('type') === 'staff'){
			this.isStudent = false;
		}else{
			this.isStudent = true;
		}
		this.$el.html(assignmentControlsView({
			hasAudio: this.hasAudio,
			previousClicked: this.previousClicked,
			isStudent: this.isStudent,
			slide: this.currentSlide.toJSON()
		}));
		nm.vent.trigger('view:render', {playerIsActive: true});
		return this;
	},
	triggerNextSlide: function(event){
		event.preventDefault();
		var ele = $(event.currentTarget);
		if(!ele.hasClass('disabled') && !this.continueLocked){
			nm.vent.trigger('assignment_player:next_slide');
			// TODO text to speech?
			//this.stopTextToSpeechAudio();
		}else{
			ele.removeClass('shake');
			ele.addClass('shake');
		}
	},
	triggerPreviousSlide: function(event){
		event.preventDefault();
		var ele = $(event.currentTarget);
		// TODO not sure what this is doing
		// var isReview = false;
		// if(Backbone.history.getFragment().indexOf('assignment-review') > -1){
		// 	isReview = true;
		// }
		if(!ele.hasClass('disabled') && !this.continueLocked){
			nm.vent.trigger('assignment_player:previous_slide', this.isReview);
			// TODO text to speech?
			//this.stopTextToSpeechAudio();
		}else{
			ele.removeClass('shake');
			ele.addClass('shake');
		}
	},
	replaySlide: function(event){
		event.preventDefault();
		this.managePlayPauseButton(true);
		this.stopTextToSpeechAudio();
		nm.vent.trigger('player:replay');
		nm.vent.trigger('text-track:reset');
	},
	playPauseSlide: function(event){
		event.preventDefault();
		var ele = $(event.currentTarget);
		if(this.hasAudio){
			this.managePlayPauseButton();
			// TODO text to speech?
			//this.stopTextToSpeechAudio();
			nm.vent.trigger('player:play');
		}
	},
	enableContinue: function(managePlayPauseButton){
		managePlayPauseButton = managePlayPauseButton || false;
		$('.navigate-slide-next').removeClass('disabled');
		if(managePlayPauseButton){
			this.managePlayPauseButton();
		}
	},
	triggerSlowDown: function(event){
		if(event){
			event.preventDefault();
		}
		if(!$('.speed-slower').hasClass('disabled')){
			var source = '';
			var newSpeed = '';
			if($('.navigate-play').hasClass('active')){
				source = 'normal';
				newSpeed = 'slow';
				$('.speed-slower').addClass('active');
				$('.navigate-play').removeClass('active');
				$('.speed-slower').addClass('disabled');
				$('.speed-slower').prop('disabled', true);
			}else if($('.speed-faster').hasClass('active')){
				source = 'fast';
				newSpeed = 'normal';
				$('.navigate-play').addClass('active');
				$('.speed-faster').removeClass('active');
				$('.speed-faster').removeClass('disabled');
				$('.speed-faster').prop('disabled', false);
			}
			// If currently stopped, change play button to pause
			$('.navigate-play').removeClass('audio-stopped');
			$('.navigate-play').find('i').text('pause');
			nm.vent.trigger('player:slower', source, newSpeed);
		}
	},
	triggerSpeedUp: function(event){
		if(event){
			event.preventDefault();
		}
		if(!$('.speed-faster').hasClass('disabled')){
			var source = '';
			var newSpeed = '';
			if($('.speed-slower').hasClass('active')){
				source = 'slow';
				newSpeed = 'normal';
				$('.navigate-play').addClass('active');
				$('.speed-slower').removeClass('active');
				$('.speed-slower').removeClass('disabled');
				$('.speed-slower').prop('disabled', false);
			}else if($('.navigate-play').hasClass('active')){
				source = 'normal';
				newSpeed = 'fast';
				$('.speed-faster').addClass('active');
				$('.navigate-play').removeClass('active');
				$('.speed-faster').addClass('disabled');
				$('.speed-faster').prop('disabled', true);
			}
			// If currently stopped, change play button to pause
			$('.navigate-play').removeClass('audio-stopped');
			$('.navigate-play').find('i').text('pause');
			nm.vent.trigger('player:faster', source, newSpeed);
		}
	},
	managePlayPauseButton: function(setToPlaying){
		var ele = $('.navigate-play');
		setToPlaying = setToPlaying || false;
		if(setToPlaying || ele.hasClass('audio-stopped')){
			ele.removeClass('audio-stopped').find('i').text('pause');
			$('.speed-faster').prop('disabled', false);
			$('.speed-slower').prop('disabled', false);
		}else{
			ele.addClass('audio-stopped').find('i').text('play_arrow');
		}
	},
	audioPause: function(){
		if(this.hasAudio && $('.navigate-play').find('i').text() === 'pause'){
			this.managePlayPauseButton();
			nm.vent.trigger('player:play');
		}
	},
	changePausedButton: function(){
		var ele = $('.navigate-play');
		ele.addClass('audio-stopped').find('i').text('play_arrow');
	},
	disableSpeedButtons: function(){
		var eleFast = $('.speed-faster');
		var eleSlow = $('.speed-slower');
		eleFast.prop('disabled', true);
		eleSlow.prop('disabled', true);
	},
	disableAllControls: function(){
		var playPause = $('.navigate-play');
		var replay = $('.navigate-replay');
		var eleFast = $('.speed-faster');
		var eleSlow = $('.speed-slower');
		eleFast.prop('disabled', true);
		eleSlow.prop('disabled', true);
		replay.prop('disabled', true);
		playPause.addClass('audio-stopped').find('i').text('play_arrow');
		playPause.prop('disabled', true);
	},
	enableSpeedButtons: function(){
		var eleFast = $('.speed-faster');
		var eleSlow = $('.speed-slower');
		if(eleFast.is(':disabled') && !eleFast.hasClass('active')){
			eleFast.prop('disabled', false);
		}
		if(eleSlow.is(':disabled') && !eleSlow.hasClass('active')){
			eleSlow.prop('disabled', false);
		}
	},
	triggerSubmitQuestion: function(event){
		event.preventDefault();
		var ele = $(event.currentTarget);
		if(!ele.hasClass('disabled')){
			var currentQuestionId = Number(ele.attr('data-id'));
			ele.addClass('pulse infinite');
			ele.find('i').show();
			ele.parents('.form-row').find('.error-response').removeClass('bounceIn').hide();
			nm.vent.trigger('slide_question:submit', {
				ele: ele,
				currentQuestionId: currentQuestionId
			});
		}
	},
	triggerskipQuestion: function(event){
		event.preventDefault();
		var ele = $(event.currentTarget);
		if(!ele.hasClass('disabled')){
			var currentQuestionId = Number(ele.attr('data-id'));
			nm.vent.trigger('slide_question:skip', {
				ele: ele,
				currentQuestionId: currentQuestionId
			});

			// Grab next question
			var availableQuestions = _.where(this.assessments, {id: this.currentSlide.get('assessmentId')})[0].questionIds;

			// don't allow use of question ID if it's a write_about type
			let allowedQuestions = _.filter(this.currentSlide.get('questions'), function(q){
				return _.first(q.question).type !== 'write_about'; // WTF
			});
			let allowedQuestionIds = _.pluck(_.map(allowedQuestions, function(q){
				return _.first(q.question);
			}), 'id');
			availableQuestions = _.intersection(availableQuestions, allowedQuestionIds);

			var nextId = 0;
			if(availableQuestions.indexOf(currentQuestionId) !== availableQuestions.length - 1){
				nextId = availableQuestions[availableQuestions.indexOf(currentQuestionId) + 1];
			}else{
				nextId = availableQuestions[0];
			}
			ele.data('data-id', nextId).attr('data-id', nextId);
			ele.parent('.submit-question-controls').find('.submit-take-question-form').data('data-id', nextId).attr('data-id', nextId);
		}
	},
	afterRender: function(){
		// if assignment completed, enable continue button right away
		if(this.currentSlide.assignmentCompleted){
			nm.vent.trigger('continue:enabled');
		}
	}
});
