var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"enhanced-elp-input enhanced-write-speak-responses\">\n	<p>Say it first:</p>\n	<p class=\"enhanced-write-speak-response\">\n		<strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"spokenResponse") || (depth0 != null ? lookupProperty(depth0,"spokenResponse") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"spokenResponse","hash":{},"data":data,"loc":{"start":{"line":5,"column":10},"end":{"line":5,"column":28}}}) : helper)))
    + "</strong>\n		<button class=\"play-spoken-response light-blue-button icon-only\"><i class=\"material-icons\">play_arrow</i><i class=\"material-icons\">hourglass_empty</i></button>\n	</p>\n	<p>Now write your answer:</p>\n	<p class=\"enhanced-write-speak-response\"><strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"writtenResponse") || (depth0 != null ? lookupProperty(depth0,"writtenResponse") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"writtenResponse","hash":{},"data":data,"loc":{"start":{"line":9,"column":50},"end":{"line":9,"column":69}}}) : helper)))
    + "</strong></p>\n	<audio id=\"response-audio\" src=\"\" preload=\"metadata\" autoplay=\"false\" crossorigin=\"anonymous\">\n		Your browser does not support the audio format.\n	</audio>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "<div class=\"enhanced-writing\">\n	<input type=\"text\" placeholder=\"\" class=\"input-full\" name=\"writingPractice\" />\n	<input type=\"hidden\" class=\"spoken-writing-practice\" name=\"spokenWritingPractice\" value=\"\" />\n	<input type=\"hidden\" class=\"writing-practice-audio\" name=\"writingPracticeAudio\" value=\"\" />\n</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"writtenResponse") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":21,"column":7}}})) != null ? stack1 : "");
},"useData":true});