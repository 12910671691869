import $ from 'jquery';
import _ from 'underscore';
import interact from 'interactjs';
import AppBaseView from '../base/AppBaseView';
import nm from '../../nm';
import stemDragView from '../../templates/questions/enhancedElp/stemDragView.handlebars';

export default AppBaseView.extend({
	events: {
	},
	className: 'stem-drag-view-holder',
	flashedSlots: false, //whether we've already showed users the dropzones with a "flash" animation
	initialize(options){
		_.bindAll(this, 'errorHandler');
		this.contentParts = options.contentParts;
		this.response = options.response;
		this.listenTo(nm.vent, 'stem_fill:emptied_slot', this.emptySlot);
		this.listenTo(nm.vent, 'slide_question:submit', this.disableDragAndDrop);
	},
	render(){
		this.$el.html(stemDragView({
			contentParts: this.prepareContentParts()
		}));
		//only init drag/drop interface if no response is present
		_.defer(() => {
			if(!this.response){
				this.initSlotDropZones();
			}else{
				this.destroySlotDropZones();
			}
		});
		return this;
	},
	initSlotDropZones(){
		let that = this;

		//flash slots to let user know they're "dropzones", but only on initial render
		if(!this.flashedSlots){
			$('.stem-drag-part-slot').addClass('flash');
			this.flashedSlots = true;
		}

		//clear any interact-ables that might be present from previous render
		this.destroySlotDropZones();

		//set dropzone interactivity on "slots" in question
		interact('.stem-drag-part-slot').dropzone({
			//only accept elements from the word bank
			accept: '.enhanced-stem-drag-bank-word',
			//require a 10% element overlap for a drop to be possible
			overlap: 0.1,
			ondragenter(event){
				//provide style cue to let user know a drop is possible
				let draggingWord = event.relatedTarget;
				let slot = event.target;
				$(slot).addClass('active-drop-target');
			},
			ondragleave(event){
				//remove style cue to show a drop is no longer possible
				let draggingWord = event.relatedTarget;
				let slot = event.target;
				$(slot).removeClass('active-drop-target');
				$(draggingWord).removeClass('no-shadow');
			},
			ondrop(event){
				//successful drop into slot, event triggers bank to remove the word just used
				let draggingWord = event.relatedTarget;
				let slot = event.target;
				that.fillSlot(event);
				nm.vent.trigger('stem_fill:fill_slot', event);
			},
			checker(dragEvent, event, dropped, dropzone, dropElement, draggable, draggableElement){
				//this checker provides opportunity to customize when drops into slots are allowed
				let $draggingWord = $(draggableElement);
				let $allSlots = $('.stem-drag-part-slot');
				let $slottedWords = _.map($allSlots, function(slot){
					return $(slot).find('i').next('span').text();
				});
				let $targetedSlot = $(dropElement);

				//if a word from one slot is dragged to another slot that already has a word,
				//don't fill target drop, just empty the "exited" slot
				if(
					dropped &&
					$targetedSlot.hasClass('slot-filled') &&
					$(dragEvent.target).hasClass('slotted') &&
					dragEvent.type === 'dragend'
				){
					that.render();
					return false;
				}

				//if slot is already full, don't drop, just re-render bank (returns dragged, un-dropped word to bank)
				if(dropped && $targetedSlot.hasClass('slot-filled') && dragEvent.type === 'dragend'){
					nm.vent.trigger('stem_fill:render_bank');
					return false;
				}

				//if user drags an "already-slotted" word to ANOTHER slot, empty "exiting" slot, and let it drop
				if(dropped && _.contains($slottedWords, $draggingWord.find('span').text()) && dragEvent.type === 'dragend'){
					let existingSlotWord = $(dropElement).find('i').next('span').text();
					//specifically empty the "exiting slot", checker()'s return below will fill the "entering" slot
					that.emptySlot(dragEvent);
				}

				return dropped;
			}
		});
	},
	destroySlotDropZones(){
		interact('.stem-drag-part-slot').unset();
	},
	disableDragAndDrop(){
		interact('.stem-drag-part-slot').dropzone({
			enabled: false
		});
	},
	//update this.contentParts to fill a slot with a word
	fillSlot(interactEvent){
		let $targetedSlot = $(interactEvent.target);
		let $draggedWord = $(interactEvent.relatedTarget);
		for(let part of this.contentParts){
			if($targetedSlot.data('key') === part.key){
				part.value = $draggedWord.find('span').text();
			}
		}
		this.render();
	},
	//update this.contentParts to empty a slot that previously had a word in it
	emptySlot(interactEvent){
		let $targetedSlot = $(interactEvent.target);
		let $draggedWord = $(interactEvent.relatedTarget);
		for(let part of this.contentParts){
			if($targetedSlot.data('key') === part.key){
				part.value = '';
			}
		}
		this.render();
	},
	prepareContentParts(){
		//look through "slots" and populate with saved responses when available
		if(this.response){
			let slotResponses = this.response.response.enhanced;
			for(let part of this.contentParts){
				if(slotResponses[part.key]){
					part.value = slotResponses[part.key];
				}
			}
		}
		return this.contentParts;
	}
});
