/**
 * ReviewControlsView
 * Review Player controls
 */
import $ from 'jquery';
import AppBaseView from '../base/AppBaseView';
import nm from '../../nm';
import reviewControlsView from '../../templates/assignmentPlayer/reviewControlsView.handlebars';

export default AppBaseView.extend({
	events: {
		'click .review-next': 'triggerNextSlide',
		'click .review-replay': 'replaySlide',
		'click .review-play': 'playPauseSlide',
		'click .review-speed-slower': 'triggerSlowDown',
		'click .review-speed-faster': 'triggerSpeedUp'
	},
	className: 'review-controls-holder',
	initialize: function(options){
		this.hasAudio = options.hasAudio;
		this.hasNextSlide = options.hasNextSlide;
		this.listenTo(nm.vent, 'review:continueEnabled', this.enableContinue);
		this.listenTo(nm.vent, 'reviewSpeed:disable', this.disableSpeedButtons);
		this.listenTo(nm.vent, 'reviewSpeed:enable', this.enableSpeedButtons);
		this.listenTo(nm.vent, 'review-controls:disable', this.disableAllControls);
	},
	render: function(){
		if(nm.user.get('type') === 'staff'){
			this.isStudent = false;
		}else{
			this.isStudent = true;
		}
		//use setElement to prevent extra un-needed wrapping div
		this.setElement(reviewControlsView({
			hasAudio: this.hasAudio,
			hasNextSlide: this.hasNextSlide,
			isStudent: this.isStudent
		}));
		return this;
	},
	triggerNextSlide: function(event){
		event.preventDefault();
		var ele = $(event.currentTarget);
		if(!ele.hasClass('disabled')){
			nm.vent.trigger('review:next_slide');
		}else{
			ele.removeClass('shake');
			ele.addClass('shake');
		}
	},
	replaySlide: function(event){
		event.preventDefault();
		this.managePlayPauseButton(true);
		nm.vent.trigger('review:replay');
		nm.vent.trigger('review-text-track:reset');
	},
	playPauseSlide: function(event){
		event.preventDefault();
		var ele = $(event.currentTarget);
		if(this.hasAudio){
			nm.vent.trigger('review:play');
			this.managePlayPauseButton();
		}
	},
	enableContinue: function(managePlayPauseButton){
		managePlayPauseButton = managePlayPauseButton || false;
		$('.review-next').removeClass('disabled');
		if(managePlayPauseButton){
			this.managePlayPauseButton();
		}
	},
	triggerSlowDown: function(event){
		event.preventDefault();
		if(!$('.review-speed-slower').hasClass('disabled')){
			var source = '';
			var newSpeed = '';
			if($('.review-play').hasClass('active')){
				source = 'normal';
				newSpeed = 'slow';
				$('.review-speed-slower').addClass('active');
				$('.review-play').removeClass('active');
				$('.review-speed-slower').addClass('disabled');
				$('.review-speed-slower').prop('disabled', true);
			}else if($('.review-speed-faster').hasClass('active')){
				source = 'fast';
				newSpeed = 'normal';
				$('.review-play').addClass('active');
				$('.review-speed-faster').removeClass('active');
				$('.review-speed-faster').removeClass('disabled');
				$('.review-speed-faster').prop('disabled', false);
			}
			// If currently stopped, change play button to pause
			$('.review-play').removeClass('audio-stopped');
			$('.review-play').find('i').text('pause');
			nm.vent.trigger('review:slower', source, newSpeed);
		}
	},
	triggerSpeedUp: function(event){
		event.preventDefault();
		if(!$('.review-speed-faster').hasClass('disabled')){
			var source = '';
			var newSpeed = '';
			if($('.review-speed-slower').hasClass('active')){
				source = 'slow';
				newSpeed = 'normal';
				$('.review-play').addClass('active');
				$('.review-speed-slower').removeClass('active');
				$('.review-speed-slower').removeClass('disabled');
				$('.review-speed-slower').prop('disabled', false);
			}else if($('.review-play').hasClass('active')){
				source = 'normal';
				newSpeed = 'fast';
				$('.review-speed-faster').addClass('active');
				$('.review-play').removeClass('active');
				$('.review-speed-faster').addClass('disabled');
				$('.review-speed-faster').prop('disabled', true);
			}
			// If currently stopped, change play button to pause
			$('.review-play').removeClass('audio-stopped');
			$('.review-play').find('i').text('pause');
			nm.vent.trigger('review:faster', source, newSpeed);
		}
	},
	managePlayPauseButton: function(setToPlaying){
		var ele = $('.review-play');
		setToPlaying = setToPlaying || false;
		if(setToPlaying || ele.hasClass('audio-stopped')){
			ele.removeClass('audio-stopped').find('i').text('pause');
			this.enableSpeedButtons();
		}else{
			ele.addClass('audio-stopped').find('i').text('play_arrow');
		}
	},
	disableSpeedButtons: function(){
		var eleFast = $('.review-speed-faster');
		var eleSlow = $('.review-speed-slower');
		eleFast.prop('disabled', true);
		eleSlow.prop('disabled', true);
	},
	disableAllControls: function(){
		var playPause = $('.review-play');
		var replay = $('.review-replay');
		var eleFast = $('.review-speed-faster');
		var eleSlow = $('.review-speed-slower');
		eleFast.prop('disabled', true);
		eleSlow.prop('disabled', true);
		replay.prop('disabled', true);
		playPause.addClass('audio-stopped').find('i').text('play_arrow');
		playPause.prop('disabled', true);
	},
	enableSpeedButtons: function(){
		var eleFast = $('.review-speed-faster');
		var eleSlow = $('.review-speed-slower');
		if(eleFast.is(':disabled') && !eleFast.hasClass('active')){
			eleFast.prop('disabled', false);
		}
		if(eleSlow.is(':disabled') && !eleSlow.hasClass('active')){
			eleSlow.prop('disabled', false);
		}
	}
});
